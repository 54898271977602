import { Link } from '@mui/material';
import { HyperLinkStyle } from './PatientCodeLinkCell.styled';

type PatientCodeLinkCellProps = {
  patientId: string;
  patientCode: string;
};

const PatientCodeLinkCell = ({ patientId, patientCode }: PatientCodeLinkCellProps) => {
  return (
    <Link href={`/patients/${patientId}`} target="_blank" sx={HyperLinkStyle}>
      {patientCode}
    </Link>
  );
};

export { PatientCodeLinkCell };
