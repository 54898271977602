import PhoneAndroid from '@mui/icons-material/PhoneAndroid';
import { Button } from '@mui/material';

// TODO: add all the logic for Voice to call in the component level not in the page level
export const CallPatientButton = () => {
  const isDisabled = false;
  const onClick = () => {
    console.log('add logic this here');
  };
  return (
    <Button
      variant="contained"
      fullWidth={true}
      color="primary"
      onClick={onClick}
      disabled={isDisabled}
      data-dd-privacy="allow"
      startIcon={<PhoneAndroid />}
    >
      Call Patient
    </Button>
  );
};
