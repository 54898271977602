import { usePostIhiResolutionReason } from '@/hooks/patients/usePostIhiResolutionReason';
import type { IHIReviewLogDto } from '@montugroup/pms-api-contracts';
import { Box, TextField, Typography } from '@mui/material';
import type { ChangeEvent } from 'react';
import { useState } from 'react';

const MAX_CHARACTERS = 500;

type IhiReasonDialogProps = {
  reviewLog: IHIReviewLogDto;
  handleModalClose: () => void;
};

export const IhiReasonDialog = (props: IhiReasonDialogProps) => {
  const { reviewLog, handleModalClose } = props;
  const { postResolutionReason } = usePostIhiResolutionReason();
  const [reasonTextValue, setReasonTextValue] = useState<string>('');
  const maxCharactersExceeded = reasonTextValue.length >= MAX_CHARACTERS;

  const handleAddReason = (reviewId: number) => {
    postResolutionReason({ reason: reasonTextValue, reviewId });

    handleModalClose();
  };

  const handleChangeReasonText = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setReasonTextValue(event.target.value);
  };

  return (
    <Box
      component="form"
      data-testid="resolution-reason-form"
      id="resolution-reason-form"
      width={'590px'}
      marginRight={3}
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!maxCharactersExceeded) {
          handleAddReason(reviewLog.id);
        }
      }}
    >
      <Typography marginBottom={4}>
        Please make sure to add the reason in the input field on the modal before clicking the save button.
      </Typography>
      <TextField
        multiline
        fullWidth
        minRows={10}
        sx={{ textarea: { resize: 'both' } }}
        error={maxCharactersExceeded}
        helperText={`${reasonTextValue.length} / ${MAX_CHARACTERS} characters${maxCharactersExceeded ? '. You cannot save because there are too many characters.' : ''}`}
        onChange={handleChangeReasonText}
        placeholder={'Add reason...'}
        value={reasonTextValue}
      />
    </Box>
  );
};
