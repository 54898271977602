import axios from 'axios';

import settings from '@/data/constants';
import type { Doctor } from '@/types';

export type GetDoctorsResponse = Doctor[];

export const getDoctors = async () => {
  const getDoctorsUrl = `${settings.url}/doctor?showInactive=true`;

  const getDoctorsResponse = await axios.get<GetDoctorsResponse>(getDoctorsUrl);

  return getDoctorsResponse.data;
};
