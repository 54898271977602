import type { PrescriptionData } from '@/types';

export const getRemainingPrescribedProductsAfterCancel = ({
  prescriptionData,
  prescriptionID
}: {
  prescriptionData: PrescriptionData[];
  prescriptionID: string;
}) => {
  for (const prescription of prescriptionData) {
    const productToBeCancelled = prescription.PrescribedProducts.find(
      (product) => product.prescription_product_code === prescriptionID
    );
    if (productToBeCancelled) {
      return prescription.PrescribedProducts.filter(
        (product) => product.active && product.prescription_product_code !== prescriptionID
      );
    }
  }
  return [];
};

export const getNotificationOfTreatmentModalConfirmText = ({
  isForCancellation,
  pdfError
}: {
  isForCancellation: boolean;
  pdfError: Error | null;
}) => {
  if (pdfError === null || !pdfError.message) {
    return 'Sign and Submit';
  }
  if (isForCancellation) {
    return 'Approve Cancellation';
  }
  return 'Approve Prescription';
};
