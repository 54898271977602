import { Box, Link, styled } from '@mui/material';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useClient } from 'urql';

import { getConsultationType } from '@/assets/js/util';
import ConsultationDirectCancelPopupButton from '@/components/Consultations/ConsultationDirectCancelPopupButton';
import { getPaymentTypeText } from '@/components/PatientPageV2/ConsultationTab';
import CalendarCancelPopupButton from '@/components/calcom/CalendarCancelPopupButton';
import CalendarPopupButton from '@/components/calcom/CalendarPopupButton';
import { FF_ENABLE_ALTERNATE_PENDING_CANCELLATION } from '@/constants/featureFlags';
import settings from '@/data/constants';
import { useFeatureFlags } from '@/hooks';
import { GET_PAYMENT_STATUS_QUERY } from '@/hooks/graphql/queries/usePaymentStatusQuery';
import type { ConsultationModel, DoctorModel } from '@/types';
import { buildCancellationLink, buildRescheduleLink } from '@/utils/calendar';
import { luxonFormatWithOrdinalSuffix } from '@/utils/luxon';
import { toast } from '@montugroup/design-system';
import { DateTime } from 'luxon';

type UseConsultationTabOverviewTableProps = {
  patientEmail: string;
  consultations: ConsultationModel[];
  setShowConsultation: (showConsultation: ConsultationModel) => void;
  setConsultationSubStatus: (consultationSubStatus: ConsultationSubStatus | null) => void;
  handleRefetchPatientData: () => void;
};

export type ConsultationWithPaymentStatus = {
  id: string;
  appointment_time: ReactNode;
  type: ReactNode;
  practitioner: string;
  payment_status: string;
  consultation_status: string;
  payment_type: string | false;
  view_link: ReactNode;
  reschedule_link: ReactNode;
  cancel_link: ReactNode;
};

type PaymentResponseStatus = {
  preAuthTransactionExists: boolean;
  debitTransactionExists: boolean;
  paymentStatus: string;
};

type ConsultationSubStatus = {
  subStatusId: number;
  name: string;
};

type ConsultationDoctorModel = DoctorModel & {
  email: string;
  calcom_initial_consult_event: string;
  calcom_follow_up_consult_event: string;
};

const StyledLink = styled(Link)({
  cursor: 'pointer'
});

const StyledAppointmentDate = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '200px'
});

export const useConsultationTabOverviewTable = ({
  patientEmail,
  consultations,
  setShowConsultation,
  setConsultationSubStatus,
  handleRefetchPatientData
}: UseConsultationTabOverviewTableProps) => {
  const { flags } = useFeatureFlags();
  const ffAlterativePendingFlow = flags[FF_ENABLE_ALTERNATE_PENDING_CANCELLATION];

  const [consultationsOverviewTableData, setConsultationsOverviewTableData] = useState<ConsultationWithPaymentStatus[]>(
    []
  );

  const client = useClient();

  const formatConsultationAppointmentTime = (consultation: ConsultationModel) => {
    const { start_time, end_time, ConsultationType } = consultation;
    const startTime = start_time ? DateTime.fromISO(start_time) : null;
    const endTime = end_time ? DateTime.fromISO(end_time) : null;

    const formattedConsultDate = startTime
      ? `${startTime.toFormat('MMMM')} ${luxonFormatWithOrdinalSuffix(startTime.day)} ${startTime.toFormat('yyyy')}`
      : '';

    const formattedStartTime = startTime?.toFormat('h:mm a').toLowerCase() || '';
    const formattedEndTime = endTime?.toFormat('h:mm a').toLowerCase() || '';
    const durationTime = ConsultationType?.duration_mins || 0;

    return (
      <StyledAppointmentDate>
        <span>{formattedConsultDate}</span>
        <span>{`${formattedStartTime} - ${formattedEndTime} (${durationTime} mins)`}</span>
      </StyledAppointmentDate>
    );
  };

  const formatPractitionerName = (consultation: ConsultationModel) => {
    return consultation.Doctor
      ? `${consultation.Doctor.DoctorUser?.first_name} ${consultation.Doctor.DoctorUser?.last_name}`
      : `${consultation.Nurse.NurseUser?.first_name} ${consultation.Nurse.NurseUser?.last_name}`;
  };

  const formatConsultationType = (consultation: ConsultationModel) => {
    return <Box minWidth={'80px'}>{getConsultationType(consultation)}</Box>;
  };

  const formatPaymentStatus = (consultation: ConsultationModel) => {
    let content;
    if (consultation.paymentStatus?.preAuthTransactionExists) {
      content = 'Credit Card';
    } else {
      content =
        consultation.paymentStatus?.paymentStatus === 'PAID'
          ? consultation.paid_date
            ? DateTime.fromISO(consultation.paid_date).toFormat('dd-MMM-yyyy')
            : ''
          : consultation.payment_status;
    }

    return content;
  };

  const formatConsultationStatus = (consultation: ConsultationModel) => {
    const status = consultation.ConsultationStatus?.name;
    const subStatus = consultation.ConsultationSubStatus?.name;
    return [status, subStatus ? `- ${subStatus}` : ''].filter(Boolean).join(' ');
  };

  const formatPaymentType = (consultation: ConsultationModel) => {
    return !consultation?.Doctor && getPaymentTypeText(consultation);
  };

  const createViewLink = useCallback(
    (consultation: ConsultationModel) => {
      return (
        <Box width="60px">
          <StyledLink
            onClick={() => {
              setShowConsultation(consultation);

              if (consultation.ConsultationStatus?.name === 'Ineligible') {
                setConsultationSubStatus(
                  consultation.ConsultationSubStatus
                    ? {
                        subStatusId: consultation?.ConsultationSubStatus?.id,
                        name: consultation?.ConsultationSubStatus?.name
                      }
                    : null
                );
              }
            }}
            data-testid="view-consultation-details"
          >
            View
          </StyledLink>
        </Box>
      );
    },
    [setConsultationSubStatus, setShowConsultation]
  );

  useEffect(() => {
    const createRescheduleLink = (consultation: ConsultationModel) => {
      if (!consultation.calcom_consultation_uuid) {
        return null;
      }

      const isScheduledOrPending =
        consultation.status_id === settings.consultationStatus.scheduled ||
        consultation.status_id === settings.consultationStatus.pending;
      const rescheduleLink = buildRescheduleLink(consultation.calcom_consultation_uuid);
      const showRescheduleButton = isScheduledOrPending && rescheduleLink;

      return (
        showRescheduleButton && (
          <CalendarPopupButton
            variant="text"
            link={rescheduleLink}
            onRescheduleBookingSuccessful={handleRefetchPatientData}
          >
            Reschedule
          </CalendarPopupButton>
        )
      );
    };

    const createCancelLink = (consultation: ConsultationModel, alternativePending: boolean) => {
      if (!consultation.calcom_consultation_uuid) {
        return null;
      }

      const isScheduled = consultation.status_id === settings.consultationStatus.scheduled;
      const isPending = consultation.status_id === settings.consultationStatus.pending;
      const isScheduledOrPending = isScheduled || isPending;
      const cancelBookingUrl = buildCancellationLink(
        consultation.calcom_consultation_uuid,
        consultation.Doctor as ConsultationDoctorModel
      );
      const showCancelButton = isScheduledOrPending && cancelBookingUrl;

      return (
        showCancelButton &&
        ((isScheduled && (
          <CalendarCancelPopupButton cancelBookingUrl={cancelBookingUrl} onClose={handleRefetchPatientData} />
        )) ||
          (isPending && alternativePending && (
            <ConsultationDirectCancelPopupButton consultationId={consultation.id} onClose={handleRefetchPatientData} />
          )))
      );
    };

    const appendConsultationsWithPaymentStatus = async () => {
      try {
        const paymentStatusPromises = consultations.map(async (consultation: ConsultationModel) => {
          const paymentStatusResponse = await client
            .query(GET_PAYMENT_STATUS_QUERY, {
              getPaymentStatusInput: {
                email: patientEmail,
                merchantTransactionId: consultation.calcom_consultation_uuid as string
              }
            })
            .toPromise();

          const consultWithAddedPaymentStatus = {
            ...consultation,
            paymentStatus: paymentStatusResponse?.data?.getPaymentStatus as PaymentResponseStatus
          };

          return {
            id: consultation.consultation_code,
            appointment_time: formatConsultationAppointmentTime(consultation),
            type: formatConsultationType(consultation),
            practitioner: formatPractitionerName(consultation),
            payment_status: formatPaymentStatus(consultWithAddedPaymentStatus),
            consultation_status: formatConsultationStatus(consultation),
            payment_type: formatPaymentType(consultWithAddedPaymentStatus),
            view_link: createViewLink(consultWithAddedPaymentStatus),
            reschedule_link: createRescheduleLink(consultation),
            cancel_link: createCancelLink(consultation, ffAlterativePendingFlow)
          };
        });

        const consultationsWithPaymentStatus = await Promise.all(paymentStatusPromises);

        setConsultationsOverviewTableData(consultationsWithPaymentStatus);
      } catch {
        toast.error('Error while fetching payment status data for consultations');
      }
    };

    appendConsultationsWithPaymentStatus();
  }, [client, consultations, createViewLink, ffAlterativePendingFlow, handleRefetchPatientData, patientEmail]);

  return {
    consultationsOverviewTableData
  };
};
