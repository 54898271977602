import type { TabProps as MuiTabProps } from '@mui/material';

import { StyledTab } from './Tabs.styled';

export type TabProps = MuiTabProps;

export const Tab = (props: TabProps) => {
  return <StyledTab {...props} />;
};

export default Tab;
