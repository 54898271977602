import { useCallback, useState } from 'react';

//as we are passing onConfirm callback dynamically, this hook is meant to ensure that opening of the modal and setting of the callback happen at the same time
//so future code does not accidentally open the modal with the 'wrong' callback or vice versa
export const useNotificationOfTreatmentModal = () => {
  const [notificationOfTreatmentModalIsOpen, setNotificationOfTreatmentModalIsOpen] = useState<boolean>(false);
  const [notificationOfTreatmentModalOnConfirmCallback, setNotificationOfTreatmentOnConfirmCallback] = useState<
    () => void
  >(() => {});
  const [isForCancellation, setIsForCancellation] = useState<boolean>(false);

  const openNotificationOfTreatmentModal = useCallback(
    ({ onConfirm, isForCancellation }: { onConfirm: () => void; isForCancellation: boolean }) => {
      setNotificationOfTreatmentOnConfirmCallback(() => onConfirm);
      setIsForCancellation(isForCancellation);
      setNotificationOfTreatmentModalIsOpen(true);
    },
    []
  );

  const closeNotificationOfTreatmentModal = useCallback(() => setNotificationOfTreatmentModalIsOpen(false), []);

  return {
    notificationOfTreatmentModalIsOpen,
    openNotificationOfTreatmentModal,
    closeNotificationOfTreatmentModal,
    notificationOfTreatmentModalOnConfirmCallback,
    isForCancellation
  };
};
