import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import React, { useEffect, useState } from 'react';

import { DiscontinuedPanel } from '@/components/Rescripting/components/DiscontinuedPanel/DiscontinuedPanel';
import { OutOfStockPanel } from '@/components/Rescripting/components/OutOfStockPanel/OutOfStockPanel';
import {
  FF_ENABLE_DOCTOR_OOS_RESCRIPT,
  FF_ENABLE_DOCTORS_G_SCRIPT,
  FF_PATV5_SCRIPT_REVIEW_PAGE_DISABLE_DISCONTINUED_TAB
} from '@/constants/featureFlags';
import { getUser } from '@/data/service/authService';
import { PageHeader } from '@/shared-ui/PageElements/PageHeader';
import { PageMainContent } from '@/shared-ui/PageElements/PageMainContent';
import { PageTitle } from '@/shared-ui/PageElements/PageTitle';
import { UserRole } from '@/types';
import { useFeatureFlags } from '../hooks';

export default function DoctorScriptReview() {
  const [value, setValue] = useState('1');

  // Using feature flag hook, send the names of the flags you are using within the page.
  const { flags } = useFeatureFlags();

  // Extract the feature flag values
  const ffDoctorRescript = flags[FF_ENABLE_DOCTORS_G_SCRIPT];
  const ffOssRescript = flags[FF_ENABLE_DOCTOR_OOS_RESCRIPT];
  const disableDiscontinuedTab = flags[FF_PATV5_SCRIPT_REVIEW_PAGE_DISABLE_DISCONTINUED_TAB];

  const user = getUser();
  const isADoctor = user.role_id === UserRole.Doctor;

  // Following required to set default Tab
  useEffect(() => {
    if (!ffDoctorRescript || (isADoctor && !disableDiscontinuedTab)) {
      setValue('2');
    }
  }, [ffDoctorRescript, isADoctor, disableDiscontinuedTab]);

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return !disableDiscontinuedTab ? (
    <>
      <PageHeader>
        <PageTitle variant="h1" color="textPrimary">
          Doctors Review Dashboard
        </PageTitle>
      </PageHeader>
      <PageMainContent>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="Script Types">
                {!isADoctor && ffDoctorRescript && <Tab label="DISCONTINUED" value="1" />}
                {ffOssRescript && <Tab label="OOS" value="2" />}
              </TabList>
            </Box>
            {!isADoctor && ffDoctorRescript && (
              <TabPanel value="1" sx={{ paddingX: 0 }}>
                <DiscontinuedPanel />
              </TabPanel>
            )}
            {ffOssRescript && (
              <TabPanel value="2" sx={{ paddingX: 0 }}>
                <OutOfStockPanel />
              </TabPanel>
            )}
          </TabContext>
        </Box>
      </PageMainContent>
    </>
  ) : (
    <>
      <PageHeader>
        <PageTitle variant="h1" color="textPrimary">
          Doctors Review Dashboard
        </PageTitle>
      </PageHeader>
      <PageMainContent>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="Script Types">
                {ffDoctorRescript && <Tab label="DISCONTINUED" value="1" />}
                {ffOssRescript && <Tab label="OOS" value="2" />}
              </TabList>
            </Box>
            {ffDoctorRescript && (
              <TabPanel value="1" sx={{ paddingX: 0 }}>
                <DiscontinuedPanel />
              </TabPanel>
            )}
            {ffOssRescript && (
              <TabPanel value="2" sx={{ paddingX: 0 }}>
                <OutOfStockPanel />
              </TabPanel>
            )}
          </TabContext>
        </Box>
      </PageMainContent>
    </>
  );
}
