import { Box, Button } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { FF_USE_SHOPIFY_PRODUCT_IMAGES } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import LimitedAvailabilityChip from '@/shared-ui/LimitedAvailabilityChip/LimitedAvailabilityChip';
import getProductImageUrl from '@/utils/getProductImageUrl';

import { DateTime } from 'luxon';
import Comingsoon from '../assets/images/coming_soon.png';
import NewlowPrice from '../assets/images/new_low_price.png';
import NewProduct from '../assets/images/new_product.png';
import outOfStock from '../assets/images/outofstock.png';
import { ConcessionProductCatalogueChip } from '../components/concession';
import { makeGET } from '../data/service/dataService';

const ProductOffering = (props) => {
  const navigate = useNavigate();
  const { id: productIdParam } = useParams();

  const { flags } = useFeatureFlags();
  const useShopifyProductImages = flags[FF_USE_SHOPIFY_PRODUCT_IMAGES];

  const [data, setData] = React.useState({
    product: null,
    product_id: productIdParam
  });
  const isConcessionProduct = data.product?.is_concession;

  const fetchData = async (positionId) => {
    const resp = await makeGET(`product/offering/${productIdParam}`, 'getProductOffering-productOffering');
    if (resp) {
      setData({
        product: resp.product,
        product_id: productIdParam
      });
      if (positionId) {
        const rootElement = window.document.getElementById('root');
        if (rootElement) {
          rootElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }
      }
    }
  };

  React.useEffect(() => {
    if (productIdParam) {
      fetchData('product-detail-head');
    }
  }, [productIdParam]);

  const renderLimitedAvailabilityChip = () => {
    return (
      ((data.product.is_excluded_from_new_prescriptions ||
        data.product.is_supply_chain_risk ||
        data.product.is_generative_scripting) && (
        <div className="position-limited-tag">
          <LimitedAvailabilityChip label="Limited Availability" />
        </div>
      )) ||
      null
    );
  };

  return (
    <Box id="product-detail-head" className="product-offering-wrapper" width={'100%'}>
      <Box width={'100%'}>
        {data.product && (
          <Box width={'100%'}>
            <Box width={'95%'} marginTop="28px">
              <Button
                variant="contained"
                className="floatRight"
                color="primary"
                style={{ display: props.data ? 'none' : 'block' }}
                onClick={() => navigate(-1)}
              >
                {' '}
                Go Back{' '}
              </Button>
            </Box>
            <Box width="100%" textAlign={'center'} alignItems={'center'}>
              <Box width="100%" display="flex">
                <Box width="35%">
                  <Box width={'100%'} display="flex" flexWrap="wrap" className="container">
                    {data.product && (
                      <Box width={'70%'} position={'relative'}>
                        <img
                          src={getProductImageUrl(
                            useShopifyProductImages,
                            data.product.image_url,
                            data.product.ProductImages
                          )}
                          alt="PMS_NO_IMAGE"
                          className="container-img"
                        />
                        {renderLimitedAvailabilityChip()}
                        <div className="position-product">
                          {data.product && data.product.new_at_lowprice && (
                            <img src={NewlowPrice} className="img-fluid new-product-low-price" />
                          )}
                          {data.product && data.product.new_product && (
                            <img src={NewProduct} className="img-fluid new-product" />
                          )}
                          {data.product && data.product.coming_soon && (
                            <img src={Comingsoon} className="img-fluid coming-soon" />
                          )}
                          {data.product && (data.product.is_out_of_stock || data.product.reasoning_toggle) && (
                            <img src={outOfStock} className="img-fluid out-of-stock" />
                          )}

                          {isConcessionProduct && <ConcessionProductCatalogueChip />}
                        </div>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box width="60%" textAlign={'left'} marginTop="90px">
                  <Box width={'100%'}>
                    <p className="product-head-supplier-name">
                      {data.product.Suppliers[0] ? data.product.Suppliers[0].supplier_name : '-'}
                    </p>
                    <p className="pat-product-name">{data.product.short_name || data.product.name}</p>
                    <p className="pat-product-ratio" style={{ color: '#505050', fontSize: '20px' }}>
                      THC {data.product.thc_strength_for_display} : CBD {data.product.cbd_strength_for_display}
                    </p>
                    <p className="pat-product-ratio" style={{ color: '#505050', fontSize: '20px' }}>
                      ${data.product.price}
                    </p>
                    <Box className="product-display-flex" alignItems={'center'}>
                      <div className="product-highlights product-display-flex">
                        <p>{data.product.ProductFormulation.name}</p>
                        <img
                          src={data.product.ProductFormulation.image_url}
                          className="img-fluid"
                          alt="PMS-IMG"
                          style={{ width: '16px', objectFit: 'contain' }}
                        />
                      </div>

                      {data.product.size && (
                        <Box alignItems={'center'} className="product-highlights">
                          {data.product.size}
                        </Box>
                      )}
                      {data.product.spectrum_id && (
                        <Box alignItems={'center'} className="product-highlights" style={{ width: '140px' }}>
                          {data.product.spectrum_id === 1 ? 'Broad-Spectrum' : 'Full-Spectrum'}
                        </Box>
                      )}
                    </Box>
                    <p className="pro-description" style={{ marginTop: '10px', fontWeight: '500' }}>
                      {data.product.description_for_display}
                    </p>
                    {
                      // eslint-disable-next-line no-useless-concat
                      data.product.is_out_of_stock && data.product.stock_return_date && (
                        <p
                          className="pro-descriptionm out-of-stock-text"
                          style={{ color: 'red', marginTop: '10px' }}
                          data-testid="out-of-stock-return-date"
                        >
                          {'Out of Stock - Expected Return Date: ' +
                            ' ' +
                            DateTime.fromISO(data.product.stock_return_date).toFormat('dd-MMM-yyyy')}{' '}
                        </p>
                      )
                    }
                    {
                      // eslint-disable-next-line no-useless-concat
                      data.product.reasoning_toggle && (
                        <p className="pro-description out-of-stock-text" style={{ color: 'red', marginTop: '10px' }}>
                          {'Out of Stock: ' + ' ' + data.product.reasoning}{' '}
                        </p>
                      )
                    }
                    {(data.product.is_excluded_from_new_prescriptions ||
                      data.product.is_generative_scripting ||
                      data.product.is_supply_chain_risk) && (
                      <p className="pro-description out-of-stock-text" style={{ color: 'red', marginTop: '10px' }}>
                        {'Due to supply issues this SKU is limited to patients who have an existing script'}{' '}
                      </p>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <hr className="w-100 m-0 p-0 mx-auto" />
        <Box width={'100%'}>
          <Box className="box_spacing">
            <h3 className="mt-5 heading product-head-supplier-name ">DETAILS</h3>
            <Box className="des-detail-view">
              {data.product && (
                <Box className="row mx-auto product-detail-wrapper">
                  <table className="table details-table">
                    <tbody style={{ verticalAlign: 'top' }}>
                      <tr>
                        <td className="pat-detail-table">Brand:</td>
                        <td className="pat-font-sec pat-value-table">
                          {data.product.Suppliers[0] ? data.product.Suppliers[0].supplier_name : '-'}
                        </td>
                        <td className="pat-detail-table">CBD-THC Ratio:</td>
                        <td className="pat-font-sec pat-value-table">
                          {data.product.cbd_thc_ratio ? data.product.cbd_thc_ratio : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td className="pat-detail-table">Strain:</td>
                        <td className="pat-font-sec pat-value-table">
                          {data.product.ProductStrain ? data.product.ProductStrain.name : '-'}
                        </td>
                        <td className="pat-detail-table">Schedule:</td>
                        <td className="pat-font-sec pat-value-table">
                          {data.product.ProductSchedule ? data.product.ProductSchedule.name : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td className="pat-detail-table">Category:</td>
                        <td className="pat-font-sec pat-value-table">
                          {data.product.ProductCategory ? data.product.ProductCategory.name.split(':')[0] : '-'}
                        </td>
                        <td className="pat-detail-table">Condition used:</td>
                        <td rowSpan="2" className="pat-font-sec pat-value-table">
                          {data.product.conditions ? data.product.conditions.replaceAll('\n', ', ') : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td className="pat-detail-table">Terpene profile:</td>
                        <td className="pat-font-sec pat-value-table">
                          {data.product.contents ? data.product.contents.replaceAll('\n', ', ') : '-'}
                        </td>

                        <Box component={'td'} display={'none'} className="w-10 pat-detail-table">
                          Price:
                        </Box>
                        <Box display={'none'} className="pat-font-sec pat-value-table">
                          <span className="price">
                            $
                            {data.product.size
                              ? `${(
                                  Number(data.product.price) / Number(data.product.size.replace(/[^0-9]/gi, ''))
                                ).toFixed(2)}`
                              : data.product.price}
                          </span>
                          {data.product.size && `/${data.product.size.replace(/[^a-z]/gi, '')}`}
                        </Box>
                      </tr>
                    </tbody>
                  </table>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <hr className="mt-5 w-100 mx-auto" />
        <Box width={'100%'}>
          <Box>
            <Box width={'100%'} className="box_spacing">
              <h3 className="mt-5 heading product-head-supplier-name">HOW TO USE</h3>
              <Box className="des-detail-view product-display-flex" marginLeft={'100px'}>
                <Box className=" use-table">
                  <ul
                    className="mt-3 mx-auto product-use-list-wrapper py-2 pat-font-sec pat-value-table"
                    style={{ listStyle: 'none' }}
                  >
                    {data.product?.how_to_use &&
                      data.product.how_to_use.split('\n').map((x, idx) => (
                        <li key={idx} style={{ margin: '10px 0' }}>
                          {x}
                        </li>
                      ))}
                  </ul>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ProductOffering;
