const parseUniqueRowKey = (uniqueKey: string): { scriptId: number; productId: number } => {
  const [scriptId, productId] = uniqueKey.split('_');

  return {
    scriptId: parseInt(scriptId),
    productId: parseInt(productId)
  };
};

const buildUniqueRowKey = (scriptId: number, productId: number) => `${scriptId}_${productId}`;

export { buildUniqueRowKey, parseUniqueRowKey };
