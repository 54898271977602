import { styled } from '@mui/material/styles';
import { useState } from 'react';

import BasicModal from '@/components/common/BasicModal';

import { Link } from '@mui/material';
import BookSpecificDoctorList from './BookSpecificDoctorList';

export interface Doctor {
  DoctorUser: {
    first_name: string;
    last_name: string;
    email: string;
  };
  calcom_initial_consult_event: string;
  calcom_follow_up_consult_event: string;
  id: number;
  doctor_code: string;
}

export type DoctorWithSlugs = {
  name: string;
  calcom_username: string;
  calcom_initial_consult_event: string;
  calcom_follow_up_consult_event: string;
  id: number;
  doctor_code: string;
};

export interface PrefillCalCom {
  name: string;
  email: string;
  phoneNumber: string;
}

type BookSpecificDoctorButtonProps = {
  children: React.ReactNode;
  disabled?: boolean;
  doctors: Doctor[];
  assignedDoctor: Doctor;
  formData: PrefillCalCom;
  disableBookingTypeButton: boolean;
};

const BookSpecificDoctorButton = (props: BookSpecificDoctorButtonProps) => {
  const { children, disabled, doctors, assignedDoctor, formData, disableBookingTypeButton, ...restProps } = props;
  const StyledBasicModal = styled(BasicModal)(({ theme: muiTheme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '65%',
    height: '90%',
    [muiTheme.breakpoints.up('xl')]: {
      width: '60%'
    }
  }));

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => {
    if (!disabled) {
      setModalOpen(true);
    }
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Link underline="none" onClick={handleOpen} color={disabled ? 'action.disabled' : 'secondary'} {...restProps}>
        {children}
      </Link>
      <StyledBasicModal open={modalOpen} handleClose={handleClose} data-testid="book-specific-doctor-modal">
        <BookSpecificDoctorList
          doctors={doctors}
          assignedDoctor={assignedDoctor}
          formData={formData}
          disableBookingTypeButton={disableBookingTypeButton}
          handleClose={handleClose}
        />
      </StyledBasicModal>
    </>
  );
};

export default BookSpecificDoctorButton;
