import { requestHeaders } from '@/api';
import { API_URL } from '@/data/service/dataService';
import { toast } from '@montugroup/design-system';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import type {
  AvailabilitySlot,
  CalcomUser,
  DateOverrideRange,
  ScheduleResponse,
  TimeRange
} from '../../shared/types/calcom-schedule-types';

type UseUpdateScheduleProps = {
  user: CalcomUser;
  handleUpdateSuccess: () => void;
};

type ScheduleFormValues = {
  schedule: AvailabilitySlot[][] | undefined;
};

type ScheduleSubmit = {
  dateOverrides?: DateOverrideRange[];
  schedule: AvailabilitySlot[][] | undefined;
  timeZone?: string;
};

type ScheduleSubmitPayload = {
  scheduleId: number | undefined;
  availability: AvailabilitySlot[][] | undefined;
  dateOverrides: TimeRange[] | undefined;
  timeZone?: string;
};

export const useUpdateSchedule = (props: UseUpdateScheduleProps) => {
  const { user, handleUpdateSuccess } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [scheduleResponse, setScheduleResponse] = useState<ScheduleResponse[]>([]);
  const [selectedSchedule, setSelectedSchedule] = useState<ScheduleResponse>();

  const handleScheduleSelect = (schedule: ScheduleResponse) => {
    setSelectedSchedule(schedule);
  };

  const form = useForm<ScheduleFormValues>({
    values:
      selectedSchedule &&
      ({
        ...selectedSchedule,
        schedule: selectedSchedule?.availability || []
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any)
  });

  const handleGetSchedules = async (calcomUserId: number) => {
    setIsLoading(true);
    await fetch(`${API_URL}/calcom/calcom-schedule/${calcomUserId}`, {
      method: 'GET',
      headers: requestHeaders()
    })
      .then((res) => res.json() as Promise<ScheduleResponse[] | ScheduleResponse>)
      .then((response) => {
        if (Array.isArray(response)) {
          if (response.length === 1) {
            setSelectedSchedule(response[0]);
          }
          if (response.length > 1) {
            const orderedList = [...response].sort(
              (a: ScheduleResponse, b: ScheduleResponse) => a.scheduleId - b.scheduleId
            );
            setScheduleResponse(orderedList);
          }
        } else {
          setSelectedSchedule(response);
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toast.error('An error occured fetching existing schedule');
      });
  };

  const handleScheduleSubmit = async ({ ...values }: ScheduleSubmit) => {
    setIsLoading(true);

    const allDateOverridesCombined = values?.dateOverrides?.flatMap((override) => override.ranges);

    const updatedPayload: ScheduleSubmitPayload = {
      scheduleId: selectedSchedule?.scheduleId,
      availability: values.schedule,
      dateOverrides: allDateOverridesCombined,
      timeZone: values?.timeZone
    };
    await fetch(`${API_URL}/calcom/calcom-schedule`, {
      method: 'POST',
      headers: requestHeaders(),
      body: JSON.stringify(updatedPayload)
    })
      .then((res) => res.json() as Promise<ScheduleResponse>)
      .then((response) => {
        toast.success(`This ${user.userType}'s schedule has been updated`);
        setSelectedSchedule(response);
        if (handleUpdateSuccess) {
          handleUpdateSuccess();
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toast.error('An error occured updating user existing schedule');
      });
  };

  useEffect(() => {
    handleGetSchedules(user.calcom_id);
  }, [user.calcom_id]);

  return {
    form,
    scheduleResponse,
    selectedSchedule,
    handleScheduleSubmit,
    handleScheduleSelect,
    isLoading
  };
};
