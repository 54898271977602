import DisplayField from '@/components/fields/DisplayField';
import { useTableUtils } from '@/components/tables/hooks/useTableUtils';
import { FF_VIEW_CONSULTATION_NOTES_HISTORY } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import { SectionHeader } from '@/shared-ui/PageElements/SectionHeader';
import { LUXON_FORMAT_DAY_MONTH_YEAR, LUXON_FORMAT_HOUR_MINUTE_AM_PM } from '@/utils/luxon';
import { Table } from '@montugroup/design-system';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, Button, Grid, Link, Modal, Paper, Stack, Typography, styled } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useGetConsultationNotesHistory } from '../../../../../../hooks/consult/useGetConsultationNotesHistory';

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'scroll'
});

const StyledModalContents = styled(Paper)({
  padding: 24,
  paddingTop: 36,
  width: 792,
  outline: 'none',
  color: blueGrey[900]
});
const StyledNoteWrapper = styled(Box)({
  border: '1px solid #ddd',
  borderRadius: 6,
  maxHeight: 300,
  overflowY: 'auto',
  whiteSpace: 'break-spaces',
  textWrap: 'wrap',
  wordBreak: 'break-word'
});

export enum ConsultationNoteTableColumnHeaders {
  CreatedDate = 'Created',
  CreatedBy = 'Created By',
  Notes = 'Notes',
  View = 'View'
}

type FormattedNote = {
  id: number;
  notes: string;
  createdDate: string;
  createdBy: string;
};

export type SingleConsultationNoteHistoryProps = {
  consultationId: number;
};

const SingleConsultationNoteHistory = ({ consultationId }: SingleConsultationNoteHistoryProps) => {
  const { createColumnDefinition } = useTableUtils();
  const { flags } = useFeatureFlags();
  const ffViewConsultationNotes = flags[FF_VIEW_CONSULTATION_NOTES_HISTORY];

  const { data, isLoading } = useGetConsultationNotesHistory(consultationId, ffViewConsultationNotes);
  const [selectedNote, setSelectedNote] = useState<FormattedNote | null>(null);
  if (!ffViewConsultationNotes || isLoading) {
    return null;
  }

  const formattedNotes =
    data?.body?.data.map((note) => {
      const formattedNote: FormattedNote = {
        id: note.id,
        notes: note.notes,
        createdDate: DateTime.fromISO(new Date(note.createdAt).toISOString()).toFormat(
          `${LUXON_FORMAT_DAY_MONTH_YEAR} ${LUXON_FORMAT_HOUR_MINUTE_AM_PM}`
        ),
        createdBy: note.createdBy ? `${note.createdBy.firstName} ${note.createdBy.lastName}` : ''
      };

      return {
        ...formattedNote,
        view: (
          <Link
            data-testid={`${note.id}-viewNoteHistoryButton`}
            onClick={() => setSelectedNote(formattedNote)}
            sx={{
              margin: '0 auto',
              '&:hover': {
                cursor: 'pointer'
              }
            }}
            pl={1}
          >
            <VisibilityOutlinedIcon color="secondary" />
          </Link>
        )
      };
    }) ?? [];

  const formattedColumns = [
    createColumnDefinition('createdDate', ConsultationNoteTableColumnHeaders.CreatedDate, 100, {
      enableSorting: false
    }),
    createColumnDefinition('createdBy', ConsultationNoteTableColumnHeaders.CreatedBy, 150, {
      enableSorting: false
    }),
    createColumnDefinition('notes', ConsultationNoteTableColumnHeaders.Notes, undefined, {
      cell: (cell: { getValue: () => FormattedNote['notes'] | null }) => {
        return (
          <Box
            sx={{
              maxHeight: '4em',
              overflow: 'hidden',
              /**
               * are there better ways to display reduced text in table?
               * currently the row auto heights to display all text
               */
              textOverflow: 'ellipsis',
              whiteSpace: 'pre',
              maxWidth: 400
            }}
          >
            {cell.getValue() ? cell.getValue() : '-'}
          </Box>
        );
      },
      enableSorting: false
    }),
    createColumnDefinition('view', ConsultationNoteTableColumnHeaders.View, 50, {
      enableSorting: false
    })
  ];

  return (
    <>
      <StyledModal open={!!selectedNote} closeAfterTransition onClose={() => setSelectedNote(null)}>
        <StyledModalContents data-testid="selectedNoteModal">
          <Stack spacing={4}>
            <SectionHeader headerText="Consultation Note" />
            <Grid container>
              <Grid item md={6} xs={12}>
                <DisplayField
                  id="consultationNoteCreatedBy"
                  label="Created By"
                  fieldText={selectedNote?.createdBy ?? '-'}
                  labelPadding="0px"
                  fieldPadding="0px"
                  customGrid={[4, 8]}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <DisplayField
                  id="consultationNoteCreatedDate"
                  label="Created Date"
                  fieldText={selectedNote?.createdDate ?? '-'}
                  labelPadding="0px"
                  fieldPadding="0px"
                  customGrid={[4, 8]}
                />
              </Grid>
            </Grid>
            <StyledNoteWrapper p={4}>
              <Typography>{selectedNote?.notes}</Typography>
            </StyledNoteWrapper>
            <Box display="flex" justifyContent="end">
              <Button
                data-testid="closeNoteHistoryModal"
                onClick={() => setSelectedNote(null)}
                variant="outlined"
                color="secondary"
              >
                <Typography>Close</Typography>
              </Button>
            </Box>
          </Stack>
        </StyledModalContents>
      </StyledModal>
      <Stack spacing={2} data-testid="notesHistoryTable">
        <Table
          data={formattedNotes}
          columns={formattedColumns}
          pageSize={5}
          pageSizeOptions={[5, 10, 25]}
          hasRowBackgroundColor={false}
          showPagination
        />
      </Stack>
    </>
  );
};
export default SingleConsultationNoteHistory;
