import downloadDocumentClient from '@/api/document/downloadDocumentClient';
import { toast } from '@montugroup/design-system';
import type { GetDocumentResponse } from '@montugroup/pms-api-contracts';
import { NOTIFICATION_OF_TREATMENT_DOCUMENT_TYPE_ID } from './DocumentsTab.config';

/**
 * Filters an array of documents based on the document_type_id.
 * If documentTypeId is not provided, returns everything except items with document_type_id === NOTIFICATION_OF_TREATMENT_DOCUMENT_TYPE_ID.
 *
 * @param data - The array of documents to filter.
 * @param [documentTypeId] - The document_type_id to filter by.
 * @returns - The filtered array of documents.
 */
export const filterByDocumentTypeId = (
  data: Partial<GetDocumentResponse>[],
  documentTypeId?: number
): Partial<GetDocumentResponse>[] => {
  if (!documentTypeId) {
    return data.filter((item) => item.document_type_id !== NOTIFICATION_OF_TREATMENT_DOCUMENT_TYPE_ID);
  }
  return data.filter((item) => item.document_type_id === documentTypeId);
};

export type downloadFileParamsType = { documentId: number; documentPath: string };
export const downloadFile = async ({ documentId, documentPath }: downloadFileParamsType) => {
  const documentQueryResponse = await downloadDocumentClient.downloadDocument.query({ params: { documentId } });
  if (documentQueryResponse.status !== 200) {
    toast.error('There was an issue downloading the document.');
    return;
  }
  const blob = new Blob([documentQueryResponse.body], { type: 'application/octet-stream' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = documentPath;
  document.body.appendChild(a);
  a.click();
  a.remove();
};
