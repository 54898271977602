import { SectionHeader } from '@/shared-ui/PageElements/SectionHeader';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DateTime } from 'luxon';

export const headings = {
  list: ['daily_activities', 'physical_condition', 'emotional_condition', 'relationships'],
  daily_activities: 'daily_activities_list',
  daily_activities_list: ['commuting_ease', 'daily_jobs_ease', 'mobility_ease', 'self_care_ease'],
  daily_activities_heading: {
    daily_jobs_ease: 'Daily jobs ease',
    commuting_ease: 'Community ease',
    mobility_ease: 'Mobility ease',
    self_care_ease: 'Self-care ease'
  },
  emotional_condition: 'emotional_condition_list',
  emotional_condition_list: [
    'agitation',
    'calm_and_tranquil',
    'control',
    'coping',
    'despair',
    'energy',
    'sad',
    'worry'
  ],
  emotional_condition_heading: {
    agitation: 'Agitation',
    calm_and_tranquil: 'Calm and Tranquil',
    control: 'Control',
    coping: 'Coping',
    despair: 'Despair',
    energy: 'Energy',
    sad: 'Sad',
    worry: 'Worry'
  },
  physical_condition: 'physical_condition_list',
  physical_condition_list: [
    'communication_quality',
    'hearing_quality',
    'pain_frequency',
    'pain_interferance',
    'pain_level',
    'sight_quality'
  ],
  physical_condition_heading: {
    communication_quality: 'Communication quality ',
    hearing_quality: 'Hearing quality ',
    pain_frequency: 'Pain frequency ',
    pain_interferance: 'Pain interference ',
    pain_level: 'Pain level',
    sight_quality: 'Sight quality '
  },
  relationships: 'relationships_list',
  relationships_list: ['Initimate_relationships', 'community_relationships', 'family_relationships'],
  relationships_heading: {
    Initimate_relationships: 'Intimate relationships',
    family_relationships: 'Family relationships',
    community_relationships: 'Community relationships'
  }
};

const QOL = (props) => {
  const qolData = props.data;

  return (
    <Box height="70vh" overflow="scroll">
      <SectionHeader headerText="Quality Of Life Assessment" />
      <TableContainer component={Paper}>
        <Table aria-label="QOL table" size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {qolData.created_date.map((entry) => (
                <TableCell key={entry} align="center">
                  {DateTime.fromISO(entry).toFormat('dd-MMM-yyyy')}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" colSpan={4}>
                <Box fontWeight={600}>Day-to-Day Activities</Box>
              </TableCell>
            </TableRow>
            {headings.daily_activities_list.map((itm) => (
              <TableRow key={itm}>
                <TableCell component="th" scope="row">
                  <Box ml={1}>{headings.daily_activities_heading[itm]}</Box>
                </TableCell>
                {qolData[itm].map((val) => (
                  <TableCell key={val} align="center">
                    {val}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            <TableRow>
              <TableCell component="th" scope="row" colSpan={4}>
                <Box fontWeight={600}>Relationships</Box>
              </TableCell>
            </TableRow>
            {headings.relationships_list.map((itm) => (
              <TableRow key={itm}>
                <TableCell component="th" scope="row">
                  <Box ml={1}>{headings.relationships_heading[itm]}</Box>
                </TableCell>
                {qolData[itm].map((val) => (
                  <TableCell key={val} align="center">
                    {val}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            <TableRow>
              <TableCell component="th" scope="row" colSpan={4}>
                <Box fontWeight={600}>Emotional Condition</Box>
              </TableCell>
            </TableRow>
            {headings.emotional_condition_list.map((itm) => (
              <TableRow key={itm}>
                <TableCell component="th" scope="row">
                  <Box ml={1}>{headings.emotional_condition_heading[itm]}</Box>
                </TableCell>
                {qolData[itm].map((val) => (
                  <TableCell key={val} align="center">
                    {val}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            <TableRow>
              <TableCell component="th" scope="row" colSpan={4}>
                <Box fontWeight={600}>Physical Condition</Box>
              </TableCell>
            </TableRow>
            {headings.physical_condition_list.map((itm) => (
              <TableRow key={itm}>
                <TableCell component="th" scope="row">
                  <Box ml={1}>{headings.physical_condition_heading[itm]}</Box>
                </TableCell>
                {qolData[itm].map((val) => (
                  <TableCell key={val} align="center">
                    {val}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default QOL;
