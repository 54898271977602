import type { PrescriptionsWithOOSMedicationsParams } from '@/api/prescriptions/getPrescriptionsWithOutOfStockMedications';
import { getPrescriptionWithOOSMedications } from '@/api/prescriptions/getPrescriptionsWithOutOfStockMedications';
import { QueryKey } from '@/data/query';
import { useQuery } from '@tanstack/react-query';

const useGetPrescriptionsWithOOSMedications = (params: PrescriptionsWithOOSMedicationsParams) => {
  const queryKey = [QueryKey.GetOOSPrescriptions, params];
  const { isLoading, data, error, refetch } = useQuery({
    queryKey,
    queryFn: () => getPrescriptionWithOOSMedications(params)
  });

  return {
    loading: isLoading,
    data,
    error,
    doGet: refetch
  };
};

export { useGetPrescriptionsWithOOSMedications };
