import { previewNotificationFormContract } from '@montugroup/pms-api-contracts';
import { tsRestFetchApi } from '@ts-rest/core';
import { initQueryClient } from '@ts-rest/react-query';

import { normalizeHeaders, requestHeaders } from '@/api';
import settings from '@/data/constants';

// NOTE: Contract has to be 'unwrapped' because c.otherResponse is not compatible with initQueryClient
// https://montuhq.slack.com/archives/C07CM3DR532/p1726098146515299
const unwrappedPreviewNotificationFormContract = {
  ...previewNotificationFormContract,
  previewNotificationForm: {
    ...previewNotificationFormContract.previewNotificationForm,
    responses: {
      ...previewNotificationFormContract.previewNotificationForm.responses,
      200: previewNotificationFormContract.previewNotificationForm.responses[200].body
    }
  }
};

const previewNotificationFormClient = initQueryClient(unwrappedPreviewNotificationFormContract, {
  baseUrl: settings.baseXApiUrl,
  baseHeaders: {},
  api: (args) => {
    args.headers = normalizeHeaders({ ...requestHeaders(), ...args.headers });
    return tsRestFetchApi(args);
  }
});

export default previewNotificationFormClient;
