import { Box } from '@mui/material';
import {
  StyledAlert,
  StyledAlertTitle,
  StyledBox,
  StyledButtonBox,
  StyledCancelButton,
  StyledConfirmButton,
  StyledModal
} from './AlertModal.styles';
import type { AlertModalProps } from './AlertModal.types';
import { AlertSeverity } from './AlertModal.types';

/**
 * @deprecated please use ConfirmDialog component instead
 * https://storybook.alternaleaf.io/?path=/story/design-system-organisms-confirmdialog--default
 */
const AlertModal = ({
  isOpen,
  onClose,
  onConfirm,
  severity = AlertSeverity.Info,
  showIcon = false,
  title,
  titleStyles,
  body,
  bodyStyles,
  cancelText,
  confirmText,
  children,
  sx
}: AlertModalProps) => (
  <StyledModal open={isOpen} onClose={onClose}>
    <StyledBox sx={sx}>
      {title && title?.length > 0 && (
        <StyledAlert variant="outlined" severity={severity} {...(showIcon ? {} : { icon: false })} sx={titleStyles}>
          <StyledAlertTitle>{title}</StyledAlertTitle>
        </StyledAlert>
      )}
      {body && body?.length > 0 && (
        <Box marginBottom="1.5rem" sx={bodyStyles}>
          {body}
        </Box>
      )}
      {children}
      <StyledButtonBox>
        <StyledCancelButton variant="text" onClick={onClose} color="secondary">
          {cancelText}
        </StyledCancelButton>
        <StyledConfirmButton variant="contained" onClick={onConfirm} color="secondary">
          {confirmText}
        </StyledConfirmButton>
      </StyledButtonBox>
    </StyledBox>
  </StyledModal>
);

export default AlertModal;
