import settings from '@/data/constants';
import { noteContract } from '@montugroup/pms-api-contracts';
import { tsRestFetchApi } from '@ts-rest/core';
import { initQueryClient } from '@ts-rest/react-query';
import { normalizeHeaders, requestHeaders } from '../api.utils';

export const notesClient = () => {
  return initQueryClient(noteContract, {
    baseUrl: settings.baseXApiUrl,
    baseHeaders: {},
    api: (args) => {
      args.headers = normalizeHeaders({ ...requestHeaders(), ...args.headers });
      return tsRestFetchApi(args);
    }
  });
};
