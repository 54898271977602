import type { MedicationPadItemCancelData, MedicationPadItemError } from '../../PrescriptionPad';

import { FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import type { AlertProps } from '@mui/material';
import { Alert } from '@mui/material';
import { PRODUCT_ALERT_MESSAGE_TEMPLATE_REISSUE } from '../../ProductDetailsCard/ProductDetailsCard.constants';
import { PrescriptionItemAlertType } from '../PrescriptionItemEditor.types';

export type PrescriptionItemAlertProps = {
  description?: React.ReactNode;
  cancelAlertData?: MedicationPadItemCancelData | null;
  errorMessage?: MedicationPadItemError | null;
  reissueStatus?: {
    isReissued?: boolean;
    isOutOfStock?: boolean;
    productName?: string;
    supplierName?: string;
  };
};

const ALERT_TYPE_TO_SEVERITY: Record<PrescriptionItemAlertType, AlertProps['severity']> = {
  [PrescriptionItemAlertType.CANCEL]: 'warning',
  [PrescriptionItemAlertType.ERROR]: 'error',
  [PrescriptionItemAlertType.IS_OUT_OF_STOCK]: 'warning',
  [PrescriptionItemAlertType.REISSUE]: 'info'
} as const;

type AlertConfig = {
  isShown: boolean;
  props: {
    ariaLabel: string;
    description: React.ReactNode;
  };
};

export const PrescriptionItemAlert = ({
  description,
  cancelAlertData,
  errorMessage,
  reissueStatus
}: PrescriptionItemAlertProps) => {
  const { flags } = useFeatureFlags();
  const ffV4PrescriptionAssistantCancelPrescribedMedication =
    flags[FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION];

  const alertConfig: Partial<Record<PrescriptionItemAlertType, AlertConfig>> = {
    [PrescriptionItemAlertType.CANCEL]: {
      isShown: !errorMessage && !!cancelAlertData && ffV4PrescriptionAssistantCancelPrescribedMedication,
      props: {
        ariaLabel: 'Medication will be cancelled',
        description: cancelAlertData ? (
          <>
            <strong>
              {[cancelAlertData.supplierName, cancelAlertData.medicationName].filter(Boolean).join(' | ')} will be
              cancelled
            </strong>{' '}
            and updated to another medication based on the previously selected prescriptions
          </>
        ) : null
      }
    },
    [PrescriptionItemAlertType.ERROR]: {
      isShown: !!errorMessage,
      props: {
        ariaLabel: 'Error message',
        description: errorMessage ? (
          <>
            <strong>{errorMessage.title} </strong> {errorMessage.description}
          </>
        ) : null
      }
    },
    [PrescriptionItemAlertType.REISSUE]: {
      isShown: Boolean(!errorMessage && reissueStatus?.isReissued),
      props: {
        ariaLabel: reissueStatus?.isOutOfStock ? 'Out of stock medication' : 'Re-issued medication',
        description: reissueStatus ? (
          reissueStatus.isOutOfStock ? (
            <>
              We apologise, but <strong>{reissueStatus.productName}</strong> is currently out of stock. Based on your
              order preferences, we recommend the following alternative product that closely matches your needs.
            </>
          ) : (
            <>
              <strong>
                {reissueStatus.supplierName
                  ? `${reissueStatus.supplierName} | ${reissueStatus.productName}`
                  : reissueStatus.productName || 'The medication'}
              </strong>{' '}
              {PRODUCT_ALERT_MESSAGE_TEMPLATE_REISSUE}.
            </>
          )
        ) : null
      }
    }
  };

  const activeAlert = Object.entries(alertConfig).find(([_, { isShown }]) => isShown);

  if (!activeAlert) {
    return null;
  }

  const [alertType, config] = activeAlert;
  const { ariaLabel, description: alertDescription } = config.props;

  return (
    <Alert
      aria-label={ariaLabel}
      severity={
        alertType === PrescriptionItemAlertType.REISSUE && reissueStatus?.isOutOfStock
          ? ALERT_TYPE_TO_SEVERITY[PrescriptionItemAlertType.IS_OUT_OF_STOCK]
          : ALERT_TYPE_TO_SEVERITY[alertType as PrescriptionItemAlertType]
      }
    >
      {alertDescription ?? description}
    </Alert>
  );
};
