import axios from 'axios';

import type { PaginatedRequest } from '@/types/api.types';
import type { Prescription } from '@/types/prescriptions.types';
import settings from '../../data/constants';

interface PrescriptionsWithOOSMedicationsParams extends Omit<PaginatedRequest, 'offset'> {
  status: string;
}

export type GetPrescriptionsResponse = {
  prescriptions: Prescription[];
  count: number;
};

const getPrescriptionWithOOSMedications = async ({ status, limit }: PrescriptionsWithOOSMedicationsParams) => {
  const response = await axios.get<GetPrescriptionsResponse>(
    `${settings.url}/prescription/getPrescriptionsListWithOOSProducts`,
    {
      method: 'GET',
      params: {
        limit: limit,
        status: status
      }
    }
  );

  return response?.data;
};

export { getPrescriptionWithOOSMedications };
export type { PrescriptionsWithOOSMedicationsParams };
