import { notesClient } from '@/api/notes/noteClient';

export const useStrikePatientNote = () => {
  const { mutateAsync, ...rest } = notesClient().strikeNote.useMutation();

  const strikePatientNote = async (noteId: number, strikeReason: string): Promise<string> => {
    try {
      const response = await mutateAsync({
        params: {
          noteId
        },
        body: {
          strikeReason
        }
      });

      return response.body;
    } catch (_error) {
      throw new Error('Failed to strike note. Please try again later.');
    }
  };

  return { strikePatientNote, ...rest };
};
