import type { RescriptingReason } from '@/components/Rescripting/Rescripting.types';
import type { AutocompleteMultiSelectOption } from '@montugroup/design-system';
import type { DiscontinuedTableRowData } from '../../DiscontinuedPanel/DiscontinuedPanel.types';
import type { OutOfStockTableRowData } from '../../OutOfStockPanel/OutOfStockPanel.types';

const createAutoCompleteMultiSelectOptions = (options: string[]) =>
  options.map((option) => ({
    id: option,
    title: option
  }));

const formatPatientCodeOptions = (
  tableRowData: OutOfStockTableRowData[] | DiscontinuedTableRowData[]
): AutocompleteMultiSelectOption[] => {
  const patientCodeNoDuplicates = [...new Set(tableRowData.map(({ patientCode }) => patientCode || ''))];

  return createAutoCompleteMultiSelectOptions(patientCodeNoDuplicates);
};

const formatMedicationNameOptions = (
  tableRowData: OutOfStockTableRowData[] | DiscontinuedTableRowData[],
  rescriptingReason: RescriptingReason
): AutocompleteMultiSelectOption[] => {
  const medicationNameNoDuplicates = [
    ...new Set(
      tableRowData.map((s) => {
        if (rescriptingReason === 'oos') {
          return (s as OutOfStockTableRowData).outOfStockMedicationName || '';
        }

        if (rescriptingReason === 'discontinued') {
          return (s as DiscontinuedTableRowData).discontinuedMedicationName || '';
        }
        return '';
      })
    )
  ];

  return createAutoCompleteMultiSelectOptions(medicationNameNoDuplicates);
};

const formatDoctorNameOptions = (
  tableRowData: OutOfStockTableRowData[] | DiscontinuedTableRowData[]
): AutocompleteMultiSelectOption[] => {
  const doctorNameNoDuplicates = [...new Set(tableRowData.map((s) => s.doctorName || ''))];

  return createAutoCompleteMultiSelectOptions(doctorNameNoDuplicates);
};

export { formatDoctorNameOptions, formatMedicationNameOptions, formatPatientCodeOptions };
