import { requestHeaders } from '@/api';
import isValidPhoneNumber from '@/utils/isValidPhoneNumber';
import { toast } from '@montugroup/design-system';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import useStyles from '../assets/js/patientsV2';
import '../assets/scss/onboardDoctor.scss';
import CheckBoxField from '../components/fields/CheckBoxField';
import FileUpload from '../components/fields/FileUpload';
import InputField from '../components/fields/InputField';
import SearchableSelect from '../components/fields/SearchableSelect';
import { API_URL, makeGET } from '../data/service/dataService';

const OnBoardDoctor = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [file, setFile] = React.useState<File | null>(null);

  const [getData, setGetData] = useState({
    states: []
  });
  const [pharmacyList, setPharmacyList] = useState([]);

  const [data, setData] = useState({
    state: '',
    pharmacy: '',
    ePrescription: false,
    fileError: '',
    originalFileName: ''
  });
  let value;
  const SearchableSelectFields = ['state', 'pharmacy'];
  const checkboxFields = ['ePrescription'];
  const handledropdownChange = (prop: string) => (event: { target: { checked: boolean; value: any }; value: any }) => {
    if (checkboxFields.indexOf(prop) > -1) {
      value = event.target.checked;
    } else if (SearchableSelectFields.indexOf(prop) > -1) {
      value = event ? event.value : '';
    } else {
      value = event.target.value;
    }

    if (prop === 'state') {
      formik.errors.state = '';
      formik.values.state = value;
    } else if (prop === 'pharmacy') {
      formik.errors.pharmacy = '';
      formik.values.pharmacy = value;
    } else if (prop === 'ePrescription') {
      formik.errors.ePrescription = '';
      formik.values.ePrescription = value;
      formik.values.hpiinumber = '';
    }
    setData({
      ...data,
      [prop]: value
    });
  };

  const validationSchema = z
    .object({
      firstName: z.string().trim().min(1, 'First name is required'),
      lastName: z.string().trim().min(1, 'Last name is required'),
      ahpranumber: z.string().trim().min(1, 'AHPRA number is required'),
      email: z.string().email(),
      phone: z
        .string()
        .trim()
        .refine((val) => {
          return isValidPhoneNumber(val);
        }, 'Invalid phone number'),
      hpiinumber: z.coerce.string().trim(), // required when ePrescription true, check refine function
      prescribernumber: z.coerce
        .string()
        .min(5, 'This value should be of minimum 5 to maximum 8 digits')
        .max(8, 'This value should be of minimum 5 to maximum 8 digits'),
      state: z.coerce.string().min(1, 'State is required'),
      pharmacy: z.coerce.string().min(1, 'Pharmacy is required'),
      originalFileName: z.coerce.string().min(1, 'Doctor Signature is required!'),
      coviuUrl: z
        .string()
        .url()
        .startsWith('https://', { message: 'Must provide secure URL' })
        .regex(/\/t\/[^/]+\/join$/, {
          message: 'URL must contain "/t/" and end with "/join"'
        }),
      erxEntityId: z
        .string()
        .min(5, 'This value should 5 characters in length')
        .max(5, 'This value should 5 characters in length'),
      qualifications: z.coerce.string().min(1, 'Qualifications is required'),
      ePrescription: z.boolean()
    })
    .refine(
      (data) => {
        // Enforce hpiinumber if ePrescription is true
        return !data.ePrescription || (data.ePrescription && data.hpiinumber);
      },
      { message: 'Enter your HPII number', path: ['hpiinumber'] }
    );

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      ahpranumber: '',
      hpiinumber: '',
      prescribernumber: '',
      erxEntityId: '',
      qualifications: '',
      email: '',
      phone: '',
      state: '',
      pharmacy: '',
      ePrescription: false,
      originalFileName: '',
      coviuUrl: ''
    },
    validate: (values) => {
      try {
        validationSchema.parse(values);
      } catch (error) {
        if (error instanceof z.ZodError) {
          return error.flatten().fieldErrors;
        }
      }
      return {};
    },
    onSubmit: async (rawValues) => {
      const values = {
        ...rawValues,
        firstName: rawValues.firstName.trim(),
        lastName: rawValues.lastName.trim(),
        phone: rawValues.phone.trim()
      };
      // keeping typescript happy
      if (!file) {
        toast.error('Doctor Signature is required');
        return;
      }
      const filExtention = file.name.split('.')[file.name.split('.').length - 1].toLowerCase();
      const filename = `${values.firstName}-${values.firstName}-signature.${filExtention}`;
      const signatureFile = new File([file], filename);

      const sendBody = {
        file: signatureFile,
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        phone: values.phone,
        ahpranumber: values.ahpranumber,
        hpiinumber: values.hpiinumber,
        prescribernumber: values.prescribernumber,
        state_id: values.state,
        pharmacy_id: values.pharmacy,
        erx_entity_id: values.erxEntityId,
        qualifications: values.qualifications,
        ePrescription: values.ePrescription,
        doctor_signature: filename,
        file_name: filename,
        coviu_url: values.coviuUrl
      };

      await fetch(`${API_URL}/doctor/create`, {
        method: 'POST',
        headers: requestHeaders(),
        body: JSON.stringify(sendBody),
        redirect: 'follow'
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status === 200) {
            toast.success('New Doctor created successfully');

            if (response.onboardToCalcomResult) {
              const success = Object.values(response.onboardToCalcomResult).every(Boolean);
              if (!success) {
                toast.error('Doctor could not be created in Calcom');
              }
            }

            navigate('/doctors');
          } else {
            toast.error(response.message);
          }
        })
        .catch(() => {
          toast.error('Failed to create New Doctor');
        });
    }
  });

  const fileHandling = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    let filExtention = '';
    if (file) {
      formik.errors.originalFileName = '';
      formik.values.originalFileName = file ? file.name : '';
      filExtention = file.name.split('.')[file.name.split('.').length - 1].toLowerCase();
    }

    if (file && file.size > 525000) {
      setData({
        ...data,
        fileError: 'STATE File Exceeds Limit'
      });
      toast.info('STATE File Exceeds Limit');
    } else if (file && !['jpg', 'jpeg', 'png'].includes(filExtention)) {
      setData({
        ...data,
        fileError: 'Please upload only png and jpeg format'
      });
      toast.info('Please upload only jpeg,png,jpg format!');
    } else {
      setFile(file ? file : null);
      setData({
        ...data,
        fileError: '',
        originalFileName: file ? file.name : ''
      });
    }
  };

  const fetchData = async () => {
    const statesResponse = await makeGET('location/states');
    if (statesResponse) {
      setGetData({
        states: statesResponse
      });
    }

    const pharmacyResponse = await makeGET('circuit/pharmacies?type=dropdown', 'getPharmacies-PatientDetails');
    if (pharmacyResponse) {
      setPharmacyList(pharmacyResponse);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box px={4}>
      <Box mt={5} mb={1} textAlign="left">
        <Typography variant="h5" color="textPrimary">
          Onboard New Doctor
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box maxWidth="1024px" className="less-padding">
          <Box mt={5} component="p" className={classes.heading}>
            Doctor
          </Box>
          <Grid container mt={5} className="onBoardDoctor">
            <InputField
              id="firstName"
              type="text"
              label="First Name"
              labelPadding="7px 5px"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
            <InputField
              id="lastName"
              label="Last Name"
              type="text"
              labelPadding="7px 5px"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
            <InputField
              id="ahpranumber"
              label="AHPRA Number"
              type="text"
              labelPadding="7px 5px"
              value={formik.values.ahpranumber}
              onChange={formik.handleChange}
              error={formik.touched.ahpranumber && Boolean(formik.errors.ahpranumber)}
              helperText={formik.touched.ahpranumber && formik.errors.ahpranumber}
            />
            <InputField
              id="email"
              label="Email"
              type="text"
              labelPadding="7px 5px"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <InputField
              id="phone"
              label="Phone"
              type="text"
              labelPadding="7px 5px"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />

            {/* State */}
            <Box component={Grid} width="100%" display="flex" m={1} p={0}>
              <Grid xs={4} item={true}>
                <Box component="div" textAlign={'left'} p={'7px 5px'} fontWeight={600} style={{ display: 'flex' }}>
                  <label>{'State'}</label>
                </Box>
              </Grid>
              <Grid xs={8} item={true}>
                <Box p={0} width="100%" display="flex">
                  <SearchableSelect
                    id="state"
                    label="State"
                    options={getData.states}
                    margin={0}
                    padding={0}
                    customGrid={[null, 12]}
                    value={data.state}
                    onChange={handledropdownChange('state')}
                    touched={formik.touched.state}
                    error={formik.touched.state && formik.errors.state !== '' && formik.errors.state !== undefined}
                    helperText={formik.touched.state && formik.errors.state}
                    varient="outlined"
                    lessPadding
                  />
                </Box>
              </Grid>
            </Box>
            <InputField
              id="erxEntityId"
              label="eRx Entity id"
              type="text"
              labelPadding="7px 5px"
              value={formik.values.erxEntityId}
              onChange={formik.handleChange}
              error={formik.touched.erxEntityId && Boolean(formik.errors.erxEntityId)}
              helperText={formik.touched.erxEntityId && formik.errors.erxEntityId}
            />
            <InputField
              id="qualifications"
              label="Qualifications"
              type="text"
              labelPadding="7px 5px"
              value={formik.values.qualifications}
              onChange={formik.handleChange}
              error={formik.touched.qualifications && Boolean(formik.errors.qualifications)}
              helperText={formik.touched.qualifications && formik.errors.qualifications}
            />
            <InputField
              id="prescribernumber"
              label="Prescriber Number"
              type="number"
              labelPadding="7px 5px"
              value={formik.values.prescribernumber}
              onChange={formik.handleChange}
              error={formik.touched.prescribernumber && Boolean(formik.errors.prescribernumber)}
              helperText={formik.touched.prescribernumber && formik.errors.prescribernumber}
            />

            {/* Pharmacy */}
            <Box component={Grid} width="100%" display="flex" m={1} p={0}>
              <Grid xs={4} item={true}>
                <Box component="div" textAlign={'left'} p={'7px 5px'} fontWeight={600} style={{ display: 'flex' }}>
                  <label>{'Pharmacy Selection'}</label>
                </Box>
              </Grid>
              <Grid xs={8} item={true}>
                <Box p={0} width="100%" display="flex">
                  <SearchableSelect
                    id="pharmacy"
                    label="Pharmacy Selection"
                    options={pharmacyList}
                    margin={0}
                    padding={0}
                    customGrid={[null, 12]}
                    value={data.pharmacy}
                    onChange={handledropdownChange('pharmacy')}
                    touched={formik.touched.pharmacy}
                    error={
                      formik.touched.pharmacy && formik.errors.pharmacy !== '' && formik.errors.pharmacy !== undefined
                    }
                    helperText={formik.touched.pharmacy && formik.errors.pharmacy}
                    varient="outlined"
                    lessPadding
                  />
                </Box>
              </Grid>
            </Box>
            <InputField
              id="coviuUrl"
              label="Coviu meeting link"
              type="text"
              labelPadding="7px 5px"
              value={formik.values.coviuUrl}
              onChange={formik.handleChange}
              error={formik.touched.coviuUrl && Boolean(formik.errors.coviuUrl)}
              helperText={
                formik.touched.coviuUrl && formik.errors.coviuUrl && Array.from(formik.errors.coviuUrl).join(', ')
              }
            />
            <CheckBoxField
              id="ePrescription"
              label="ePrescription?"
              placeholder="-"
              labelPadding="11px 5px 5px 7px"
              icon={
                <Box
                  width="15px"
                  height="15px"
                  m={0.5}
                  border={1}
                  borderRadius="2px"
                  style={{ padding: 5 }}
                  borderColor="#c4c4c4"
                  component="p"
                />
              }
              labelFontWeight={900}
              checked={data.ePrescription}
              InputClasses="custom-adress-field"
              onChange={handledropdownChange('ePrescription')}
            />

            {data.ePrescription ? (
              <InputField
                id="hpiinumber"
                label="HPII Number"
                type="number"
                labelPadding="7px 5px"
                value={formik.values.hpiinumber}
                onChange={formik.handleChange}
                error={formik.touched.hpiinumber && Boolean(formik.errors.hpiinumber)}
                helperText={formik.touched.hpiinumber && formik.errors.hpiinumber}
              />
            ) : (
              ''
            )}

            <Box component={Grid} width="100%" display="flex" m={1} p={0}>
              <Grid xs={4} item={true}>
                <Box component="div" textAlign="left" p="10px 5px 5px 7px" fontWeight={600}>
                  <label htmlFor="originalFileName">Doctor Signature</label>
                </Box>
              </Grid>
              <Grid xs={8} item={true}>
                <FileUpload
                  id="originalFileName"
                  style={{ width: '500px' }}
                  file={file}
                  fileName={data.originalFileName}
                  fileError={data.fileError}
                  onChange={fileHandling}
                  displayText="File limit 500KB"
                  touched={formik.touched.originalFileName}
                  error={
                    formik.touched.originalFileName &&
                    formik.errors.originalFileName !== '' &&
                    formik.errors.originalFileName !== undefined
                  }
                  helperText={formik.touched.originalFileName && formik.errors.originalFileName}
                  small
                />
              </Grid>
            </Box>

            <Box textAlign="center" marginTop="30px" height="30px">
              <Button type="submit" variant="contained" color="secondary">
                Onboard Doctor
              </Button>
            </Box>
          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default OnBoardDoctor;
