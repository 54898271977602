import { Box, styled } from '@mui/material';

import DisplayField from '@/components/fields/DisplayField';

import type { InformationBlockProps } from './ConfirmPrescriptionCard.types';

const StyledBox = styled(Box)({
  '&:not(:first-of-type)': {
    marginTop: '2rem'
  },
  '&:not(:last-child)': {
    marginBottom: '2rem'
  }
});

export const InformationBlock = ({ heading, rowsOfText }: InformationBlockProps) => {
  return (
    <StyledBox padding={7}>
      <Box mt={0.5} mb={4} fontWeight={600}>
        {heading}
      </Box>
      <Box mt={1}>
        {rowsOfText.map((row, idx) => (
          <DisplayField
            key={`${row.id}-${idx}`}
            id={row.id}
            label={row.heading}
            fieldText={row.body}
            margin={0}
            labelPadding="0px"
            fieldPadding="0px"
            labelFontWeight={500}
            customGrid={[3, 9]}
            lineHeight={1.5}
            marginBottom={2}
          />
        ))}
      </Box>
    </StyledBox>
  );
};
