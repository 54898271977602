import { Button, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useMemo, useState } from 'react';

import AppointmentStyles from '@/assets/js/appointments';
import { getAuthData } from '@/data/service/authService';
import type { NurseRoundRobinItem } from '@/hooks/rest/useGetRoundRobinNurses';
import useGetRoundRobinNurses from '@/hooks/rest/useGetRoundRobinNurses';
import useUpdateRoundRobinNurses from '@/hooks/rest/useUpdateRoundRobinNurses';
import useTransferList from '@/hooks/useTransferList';
import type { TransferListItem } from '@montugroup/design-system';
import { toast, TransferList } from '@montugroup/design-system';

const useStyles = makeStyles((t) => AppointmentStyles(t) as any);

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500]
}));

const RoundRobinNurses = () => {
  const classes = useStyles() as any;
  const { isSuperAdmin, isManager } = getAuthData();
  const { doGet } = useGetRoundRobinNurses();
  const { loading: submitting, doPut } = useUpdateRoundRobinNurses();
  const [doctorsInList, setDoctorsInList] = useState<{
    current: NurseRoundRobinItem[];
    removed: NurseRoundRobinItem[];
  }>({ current: [], removed: [] });

  const formatNursesTransferList = (list: NurseRoundRobinItem[]): TransferListItem[] =>
    list?.map((listItem) => ({
      id: listItem.calcom_nurse_id,
      label: `${listItem.NurseUser.first_name} ${listItem.NurseUser.last_name}`
    }));

  const leftSideItems = useMemo(() => {
    return formatNursesTransferList(doctorsInList.removed);
  }, [doctorsInList]);

  const rightSideItems = useMemo(() => {
    return formatNursesTransferList(doctorsInList.current);
  }, [doctorsInList]);

  const transferList = useTransferList(leftSideItems, rightSideItems);

  useEffect(() => {
    const fetchNurses = async () => {
      const response = await doGet();

      if (response.data) {
        setDoctorsInList(response.data);
      }
    };

    fetchNurses();
  }, [doGet]);

  const handleUpdateRoundRobin = async () => {
    if (submitting) {
      return;
    }
    try {
      const nursesForRoundRobin = transferList.rightSideItems.map((checkedDoctor) => checkedDoctor.id);

      const putResponse = await doPut({
        hosts: nursesForRoundRobin
      });

      if (putResponse.data) {
        toast.success('Round Robin updated');
      }
      if (putResponse.error) {
        toast.error(putResponse.error.toString());
      }
    } catch {
      toast.error('Failed to update Round Robin');
    }
  };

  return (
    <Box component="div">
      <Grid className={classes.content}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography variant="h5">Nurse Round Robin for Initial Consultations (Cal.com)</Typography>
            <StyledTypography>
              You can add or remove nurses from the &apos;initial consultation&apos; round robin directly in PMS. Ensure
              users have their schedules set up beforehand for it to take effect.
            </StyledTypography>
          </Box>
          <Button
            disabled={!(isSuperAdmin || isManager)}
            variant="contained"
            color="secondary"
            onClick={handleUpdateRoundRobin}
          >
            Save Round Robin
          </Button>
        </Box>
        <Box component="div">
          <TransferList {...transferList} headingRightSide={'In Round Robin'} headingLeftSide={'Not in Round Robin'} />
        </Box>
      </Grid>
    </Box>
  );
};

export default RoundRobinNurses;
