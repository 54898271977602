import { getAuthData } from '@/data/service/authService';

import settings from '@/data/constants';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const { user } = getAuthData();

type UseDeactivateDoctorParams = {
  doctorId: number;
  userId: number;
};

export type PutDeactivateDoctorParams = UseDeactivateDoctorParams & {
  modifiedBy: number;
};

export const useDeactivateDoctor = () => {
  const { isPending, data, error, mutate, mutateAsync } = useMutation({
    mutationFn: async (params: UseDeactivateDoctorParams) => {
      const body = { ...params, modifiedBy: user?.id } as PutDeactivateDoctorParams;

      const cancelConsultationResponse = await axios.put<PutDeactivateDoctorParams>(
        `${settings.url}/doctor/deactivateDoctor`,
        body
      );

      return cancelConsultationResponse.status === 200;
    }
  });

  return {
    loading: isPending,
    data,
    error,
    mutate,
    mutateAsync
  };
};
