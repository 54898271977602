import { Flag } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import Link from '@mui/material/Link';

import ConsultationDirectCancelPopupButton from '@/components/Consultations/ConsultationDirectCancelPopupButton';
import CalendarCancelPopupButton from '@/components/calcom/CalendarCancelPopupButton';
import CalendarPopupButton from '@/components/calcom/CalendarPopupButton';
import type { DoctorConsultation, NurseConsultation } from '@/types';
import { buildCancellationLink, buildRescheduleLink } from '@/utils/calendar';
import { luxonFormatWithOrdinalSuffix } from '@/utils/luxon';
import { DateTime } from 'luxon';

export const formatStartTime = (consultation: NurseConsultation | DoctorConsultation) => {
  const startTime1 = DateTime.fromISO(consultation.start_time);
  const endTime1 = DateTime.fromISO(consultation.end_time);
  return `${startTime1.toFormat('MMM')} ${luxonFormatWithOrdinalSuffix(startTime1.day)} ${startTime1.toFormat('yyyy, h:mm a').toLowerCase()} - ${endTime1.toFormat('h:mm a').toLowerCase()} (${consultation.duration})`;
};

export const formatPatientDob = (consultation: NurseConsultation | DoctorConsultation) => {
  return consultation.patient_dob ? DateTime.fromISO(consultation.patient_dob).toFormat('dd-MMM-yyyy') : null;
};
export const formatPatientName = (consultation: NurseConsultation | DoctorConsultation) => {
  return (
    <Box display="flex">
      {consultation.patient_name}
      {consultation.has_red_flag && (
        <Tooltip title={consultation.red_flag_comment || ''}>
          <Flag fontSize="small" color="error" />
        </Tooltip>
      )}
    </Box>
  );
};

export const createViewLink = (consultation: NurseConsultation | DoctorConsultation) => {
  return (
    <Link href={`/patients/${consultation.patient_id}?show=1`} target="_blank">
      View
    </Link>
  );
};

export const createRescheduleLink = (
  consultation: NurseConsultation | DoctorConsultation,
  handleRefetch: () => void
) => {
  if (!consultation.calcom_consultation_uuid) {
    return null;
  }

  const isScheduledOrPending = consultation.status === 'Scheduled' || consultation.status === 'Pending';
  const rescheduleLink = buildRescheduleLink(consultation.calcom_consultation_uuid);
  const showRescheduleButton = isScheduledOrPending && rescheduleLink;

  return (
    showRescheduleButton && (
      <CalendarPopupButton variant="text" link={rescheduleLink} onRescheduleBookingSuccessful={handleRefetch}>
        Reschedule
      </CalendarPopupButton>
    )
  );
};

export const createCancelLink = (
  consultation: NurseConsultation | DoctorConsultation,
  alternativePending: boolean,
  handleRefetch: () => void
) => {
  if (!consultation.calcom_consultation_uuid) {
    return null;
  }

  const isPending = consultation.status === 'Pending';
  const isScheduled = consultation.status === 'Scheduled';
  const isScheduledOrPending = isPending || isScheduled;
  const cancelBookingUrl = buildCancellationLink(consultation.calcom_consultation_uuid);
  const showCancelButton = isScheduledOrPending && cancelBookingUrl;

  return (
    showCancelButton &&
    ((isScheduled && <CalendarCancelPopupButton cancelBookingUrl={cancelBookingUrl} onClose={handleRefetch} />) ||
      (isPending && alternativePending && (
        <ConsultationDirectCancelPopupButton consultationId={consultation.id} onClose={handleRefetch} />
      )))
  );
};
