import { Box, Tooltip } from '@mui/material';
import { RequestCountStyled } from './PatientRescriptRequestCountHeaderCell.styled';

type PatientRescriptRequestCountHeaderCellProps = {
  headerLabel: string;
  count: number;
};

const PatientRescriptRequestCountHeaderCell = ({ headerLabel, count }: PatientRescriptRequestCountHeaderCellProps) => {
  return (
    <Tooltip title={headerLabel}>
      <Box display="flex" gap="0.5rem" alignItems="center" fontWeight="bold">
        {headerLabel}
        <RequestCountStyled size="small" label={count} />
      </Box>
    </Tooltip>
  );
};

export { PatientRescriptRequestCountHeaderCell };
