import { Alert, AlertTitle } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { makeCustomErrorText, makeDefaultErrorText } from './NotificationOfTreatmentPDFViewer.data';
import {
  Bold,
  BoxViewer,
  DefaultErrorViewer,
  IframeViewer,
  Paragraph,
  PrewrapBox
} from './NotificationOfTreatmentPDFViewer.styles';

export type NotificationOfTreatmentPDFViewerProps = {
  isPdfLoading: boolean;
  pdfError: Error | null;
  pdfString: string | null;
  isForCancellation: boolean;
};

const CustomErrorMessage = ({ message, isForCancellation }: { message: string; isForCancellation: boolean }) => {
  const text = makeCustomErrorText(isForCancellation);
  return (
    <DefaultErrorViewer>
      <Alert severity="error">
        <AlertTitle>
          <Bold>{text.title}</Bold>
        </AlertTitle>
        <PrewrapBox>{message}</PrewrapBox>
        <Paragraph>
          {text.partOne}
          <Bold>{text.partTwo}</Bold>
          {text.partThree}
        </Paragraph>
        <Paragraph>{text.partFour}</Paragraph>
      </Alert>
    </DefaultErrorViewer>
  );
};

const DefaultErrorMessage = ({ isForCancellation }: { isForCancellation: boolean }) => {
  const text = makeDefaultErrorText(isForCancellation);
  return (
    <DefaultErrorViewer>
      <Alert severity="error">
        <AlertTitle>
          <Bold>{text.title}</Bold>
        </AlertTitle>
        {text.partOne}
        <Bold>{text.partTwo}</Bold>
        {text.partThree}
      </Alert>
    </DefaultErrorViewer>
  );
};
export const NotificationOfTreatmentPDFViewer: React.FC<NotificationOfTreatmentPDFViewerProps> = ({
  isPdfLoading,
  pdfError,
  pdfString,
  isForCancellation
}) => {
  if (isPdfLoading) {
    return (
      <BoxViewer>
        <CircularProgress />
      </BoxViewer>
    );
  }

  if (pdfError) {
    return pdfError.message ? (
      <CustomErrorMessage message={pdfError.message} isForCancellation={isForCancellation} />
    ) : (
      <DefaultErrorMessage isForCancellation={isForCancellation} />
    );
  }

  if (pdfString) {
    return <IframeViewer src={`${pdfString}#toolbar=0`} title="Notification of Treatment Form" />;
  }

  return null;
};
