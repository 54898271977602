import type { PlaceDetails } from '@montugroup/design-system/LocationInput';

type ValidationResult = {
  isValid: boolean;
  errorMessage?: string;
  errorMessageTitle?: string;
};

export default function useAddressValidation() {
  const validateStreetNumber = (value: PlaceDetails): ValidationResult => {
    const hasStreetNumber = value.address_components.some(
      (component) => component.types.includes('street_number') && component.long_name
    );

    if (!hasStreetNumber) {
      return {
        isValid: false,
        errorMessageTitle: 'Invalid address.',
        errorMessage: 'Please type street number.'
      };
    }

    return { isValid: true };
  };

  return {
    validateStreetNumber
  };
}
