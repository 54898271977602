import { CircularProgress, Stack } from '@mui/material';
import type { ComponentType, SuspenseProps } from 'react';
import { Suspense } from 'react';

/**
 * Stack with CircularProgress Component
 */
export const StackWithCircularProgress = (
  <Stack direction="column" alignItems="center" my={5}>
    <CircularProgress />
  </Stack>
);

/**
 * Wraps a component with Suspense and CircularProgress fallback if nothing was provided
 * @param WrappedComponent The component to wrap.
 * @param fallback The component to render while loading.
 * @returns Suspense wrapped component
 *
 * @example
 * const WrappedComponent = withSuspense(lazy(() => import("path/to/some/Component")));
 */
export function withSuspense<P extends object>(
  WrappedComponent: ComponentType<P>,
  fallback: SuspenseProps['fallback'] = null
) {
  function ComponentWithSuspense(props: P) {
    return (
      <Suspense fallback={fallback ?? StackWithCircularProgress}>
        <WrappedComponent {...props} />
      </Suspense>
    );
  }

  return ComponentWithSuspense;
}
