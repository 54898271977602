import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const PREFIX = 'FileUpload';

const classes = {
  fileUpload: `${PREFIX}-fileUpload`,
  errorFileUpload: `${PREFIX}-errorFileUpload`,
  normal: `${PREFIX}-normal`,
  small: `${PREFIX}-small`,
  fileName: `${PREFIX}-fileName`,
  fileNameSmall: `${PREFIX}-fileNameSmall`,
  cursorPointer: `${PREFIX}-cursorPointer`
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.fileUpload}`]: {
    border: '1px solid grey',
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderRadius: 8
  },

  [`& .${classes.errorFileUpload}`]: {
    border: '1px solid red',
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'black',
    borderRadius: 8
  },

  [`& .${classes.normal}`]: {
    height: '150px',
    width: '150px'
  },

  [`& .${classes.small}`]: {
    height: 'fit-content',
    width: '140px'
  },

  [`& .${classes.fileName}`]: {
    color: theme.palette.secondary.main,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },

  [`& .${classes.fileNameSmall}`]: {
    width: '90px'
  },

  [`& .${classes.cursorPointer}`]: {
    cursor: 'pointer'
  }
}));
/**
 * @deprecated due to the non-scalable composition of the component
 */
const FileUpload = (props) => {
  return (
    <StyledBox style={{ paddingLeft: 2 }}>
      <Box
        className={`${!!props.error && props.touched ? classes.errorFileUpload : classes.fileUpload} ${
          props.small ? classes.small : classes.normal
        }`}
        mt={props.small ? 0.5 : 2}
        p={props.small ? 0.5 : 2}
      >
        <Box
          component="label"
          display={props.small ? 'flex' : 'block'}
          htmlFor="file-input"
          className={classes.cursorPointer}
        >
          <i
            className="fa fa-cloud-upload"
            aria-hidden="true"
            style={{ color: props.disabled ? '#efecee' : '#6FB6C8', fontSize: props.small ? '3em' : '8em' }}
          />
          <input
            id="file-input"
            name="sasFile"
            style={{ display: 'none' }}
            type="file"
            onChange={props.onChange}
            disabled={props.disabled}
          />
          {props.file === null && props.fileError === '' ? (
            <Box component="div" p={props.small ? 0.5 : 0}>
              <Box component="p" fontSize={14}>
                <Typography>Choose a file</Typography>
              </Box>
              <Box fontSize={12}>{props.displayText ? props.displayText : 'File limit 25MB'}</Box>
            </Box>
          ) : (
            <Box p={1}>
              {props.fileError !== '' ? (
                <Box component="p" color="#194EFF" fontWeight={600} fontSize={11}>
                  {props.fileError}
                </Box>
              ) : (
                <Box
                  component="p"
                  fontSize={14}
                  className={`${classes.fileName} ${props.small ? classes.fileNameSmall : ''}`}
                >
                  {props.fileName}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {!!props.error && props.touched && <div style={{ color: 'red', fontSize: '0.85rem' }}>{props.helperText}</div>}
    </StyledBox>
  );
};

export default FileUpload;
