import { toast } from '@montugroup/design-system';
import { onlineManager } from '@tanstack/react-query';
import type { NetworkError } from './types';
import { StatusCode } from './types';
import { errorLogger, generateErrorMessage } from './utils';

export const ERROR_PAGE_STATUSES = [
  StatusCode.Unauthorised,
  StatusCode.BadRequest,
  StatusCode.Forbidden,
  StatusCode.NotFound
];

export const useErrorHandler = () => {
  const handleErrorAlert = (error: unknown) => {
    const networkError = error as NetworkError;
    // enable error logging
    errorLogger(networkError);

    toast.error({
      message: generateErrorMessage(networkError.status, !onlineManager.isOnline())
    });
  };

  const handleErrorPage = (error: unknown) => {
    const networkError = error as NetworkError;

    if (ERROR_PAGE_STATUSES.includes(networkError.status)) {
      return true;
    }

    return false;
  };

  return { handleErrorAlert, handleErrorPage };
};
