/* eslint-disable deprecation/deprecation */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { FF_WA_NOTIFICATION_OF_TREATMENT } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { DateTime } from 'luxon';
import React from 'react';

import { SectionBody } from '@/shared-ui/PageElements/SectionBody';
import { SectionHeader } from '@/shared-ui/PageElements/SectionHeader';

import { getToken } from '@/data/service/authService';
import { API_URL, makeGET } from '@/data/service/dataService';
import DateField from '../fields/DateField';
import FileUpload from '../fields/FileUpload';
import InputField from '../fields/InputField';
import SelectField from '../fields/SelectField';
import DocumentTable from './DocumentTable';
import { NOTIFICATION_OF_TREATMENT_DOCUMENT_TYPE_ID } from './DocumentsTab.config';
import { filterByDocumentTypeId } from './DocumentsTab.utils';

import { toast } from '@montugroup/design-system';
import { NotificationOfTreatmentTable } from './components/NotificationOfTreatmentTable/NotificationOfTreatmentTable';

import { usePatientDocuments } from '@/hooks/document/usePatientDocuments';

const Documents = (props) => {
  const initState = {
    DateOfApproval: DateTime.now(),
    ExpirationDate: DateTime.now().plus({ years: 2 }),
    category: '',
    formulation: '',
    type: '',
    OtherType: '',
    fileName: '',
    OtherMedication: '',
    fileError: '',
    originalFileName: '',
    MbNumber: ''
  };
  const [products, setProducts] = React.useState({ category: [], formulations: [] });
  const [documentTypes, setDocTypes] = React.useState([]);
  const [data, setData] = React.useState(initState);
  const [file, setFile] = React.useState(null);
  const [loading, setLoader] = React.useState(false);

  const { data: documentData, refetch: refetchDocumentsData } = usePatientDocuments(props.patientId);

  const { flags } = useFeatureFlags();
  const isWaNotificationOfTreatment = flags[FF_WA_NOTIFICATION_OF_TREATMENT];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const patient_id = props.patientId;

  const loadProducts = React.useCallback(async () => {
    const resp = await makeGET('product/approvalDropdowns', 'getProducts-DocumentsTab');
    if (resp) {
      setProducts({
        category: resp.ingredients,
        formulations: resp.formulations
      });
    }
  }, []);

  const loadDocTypes = React.useCallback(async () => {
    const resp = await makeGET('document/types', 'getDocumentTypes-DocumentsTab');
    if (resp) {
      setDocTypes(resp);
    }
  }, []);

  // This prevents scenarios where the server might return a 204 No Content response
  const hasDocuments = Boolean(documentData && documentData.body && documentData.body.length);

  const notificationOfTreatmentDocuments = hasDocuments
    ? filterByDocumentTypeId(documentData.body, NOTIFICATION_OF_TREATMENT_DOCUMENT_TYPE_ID)
    : [];

  React.useEffect(() => {
    loadProducts();
  }, [loadProducts]);
  React.useEffect(() => {
    loadDocTypes();
  }, [loadDocTypes]);

  let value;

  const handleDataInput = (prop) => (event) => {
    if (prop === 'ExpirationDate') {
      value = event;
    } else {
      value = event.target.value;
    }
    setData({
      ...data,
      [prop]: value
    });
  };

  const handleDataApInput = (prop) => (event) => {
    if (prop === 'DateOfApproval') {
      value = event;
    } else {
      value = event;
    }
    value = event;
    const luxonDate = DateTime.fromISO(value);
    setData({
      ...data,
      DateOfApproval: luxonDate,
      ExpirationDate: luxonDate.plus({ years: 2 })
    });
  };

  const fileHandling = (e) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const file = e.target.files[0];
    const fileName = file.name;
    const fileExtension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
    if (['png', 'jpg', 'jpeg', 'pdf'].indexOf(fileExtension) === -1) {
      setData({
        ...data,
        fileError: 'This File type is not allowed'
      });
      return;
    }
    if (file && file.size > 26214400) {
      setData({
        ...data,
        fileError: 'STATE File Exceeds Limit'
      });
    } else {
      setFile(file ? e.target.files[0] : null);
      setData({
        ...data,
        fileError: '',
        originalFileName: file ? file.name : ''
      });
    }
  };

  const onSumbit = async () => {
    if (props.disabled) {
      return;
    }
    if (!file) {
      toast.warning('Please select a file');
      return;
    }
    if (data.type === 1 && (!data.category || !data.formulation)) {
      toast.warning('Category & Formulation is required for SAS');
      return;
    }
    if (data.type === 1 && !data.MbNumber) {
      toast.warning('MB number is required for SAS');
      return;
    }
    setLoader(true);
    const sendBody = new FormData();
    if (data.fileName) {
      const filExtention = file.name.split('.')[file.name.split('.').length - 1].toLowerCase();
      sendBody.append('file_name', `${data.fileName}.${filExtention}`);
    }
    sendBody.append('file', file);
    sendBody.append('expiry_date', data.ExpirationDate.toUTC().toISO());
    sendBody.append('approval_date', data.DateOfApproval.toUTC().toISO());
    sendBody.append('patient_id', patient_id);
    sendBody.append('document_type_id', data.type);
    sendBody.append('other_type', data.OtherType);
    sendBody.append('other_medication', data.OtherMedication);
    sendBody.append('ingredient_id', data.category);
    sendBody.append('formulation_id', data.formulation);
    sendBody.append('mb_number', data.MbNumber);

    const sendHeaders = new Headers();
    sendHeaders.append('Authorization', `Bearer ${getToken()}`);

    const requestOptions = {
      method: 'POST',
      headers: sendHeaders,
      body: sendBody,
      redirect: 'follow'
    };
    const resp = await fetch(`${API_URL}/document`, requestOptions);
    if (resp.ok) {
      toast.success('Document Uploaded');
      setData(initState);
      setFile(null);
      refetchDocumentsData();
    } else {
      toast.error('Failed to Upload! Please try again later');
    }
    setLoader(false);
  };

  return (
    <Stack className="less-padding" gap={15}>
      <Stack className="section" gap={6}>
        <SectionHeader headerText="Patient Documents" />
        {/* we have to give 1px to show the border of the table component */}
        <SectionBody p="1px" height="40vh" overflow="auto">
          <DocumentTable showUpload={false} patientActive={!props.disabled} patientId={props.patientId} />
        </SectionBody>
      </Stack>
      {isWaNotificationOfTreatment && notificationOfTreatmentDocuments?.length ? (
        <Stack className="section" gap={6}>
          <SectionHeader headerText="WA Notification of Treatment" />
          {/* we have to give 1px to show the border of the table component */}
          <SectionBody p="1px" maxHeight="40vh" overflow="auto" data-testid="notification-of-treatment-table">
            <NotificationOfTreatmentTable patientId={props.patientId} />
          </SectionBody>
        </Stack>
      ) : null}
      <Box className="section">
        <SectionHeader headerText="Upload Documents" />
        <SectionBody>
          <Box display="flex">
            <Grid xs={3} item>
              <FileUpload
                file={file}
                fileName={data.originalFileName}
                fileError={data.fileError}
                onChange={fileHandling}
                disabled={props.disabled}
              />
            </Grid>
            <Grid item xs={9} className="inputfield-container-v2">
              <SelectField
                label="Type"
                id="Patient-Uploads-type"
                options={documentTypes.map((itm) => ({ name: itm.name, value: itm.id }))}
                onChange={handleDataInput('type')}
                value={data.type}
                varient="outlined"
                select
                customGrid={[3, 9]}
                disabled={props.disabled}
                labelPadding="7px 5px"
              />
              {data.type === 7 ? (
                <InputField
                  label="Type Name"
                  id="Patient-document-Type-name"
                  type="text"
                  value={data.OtherType}
                  placeholder="-"
                  labelPadding="7px 5px"
                  disabled={props.disabled}
                  customGrid={[3, 9]}
                  onChange={handleDataInput('OtherType')}
                />
              ) : (
                ''
              )}
              {file ? (
                <InputField
                  label="File Name"
                  id="Patient-document-Type-name"
                  type="text"
                  value={data.fileName}
                  placeholder="-"
                  labelPadding="7px 5px"
                  disabled={props.disabled}
                  customGrid={[3, 9]}
                  helperText="Name for the uploaded file to be stored as in our servers. [The actual file name will be maintained if left empty]"
                  onChange={handleDataInput('fileName')}
                />
              ) : (
                ''
              )}
              {data.type === 1 ? (
                <div>
                  <SelectField
                    label="Approved Category"
                    id="prescribed-product"
                    options={products.category.map((itm) => ({ name: itm.name, value: itm.id }))}
                    onChange={handleDataInput('category')}
                    value={data.product}
                    varient="outlined"
                    disabled={props.disabled}
                    customGrid={[3, 9]}
                    labelPadding="7px 5px"
                  />
                  <SelectField
                    label="Approved Formulation"
                    id="prescribed-product"
                    options={products.formulations.map((itm) => ({ name: itm.name, value: itm.id }))}
                    onChange={handleDataInput('formulation')}
                    value={data.product}
                    varient="outlined"
                    disabled={props.disabled}
                    customGrid={[3, 9]}
                    labelPadding="7px 5px"
                  />
                  {data.product === 'OtherMedication' ? (
                    <InputField
                      label="Other Medication"
                      id="other-medication"
                      type="text"
                      labelPadding="7px 5px"
                      disabled={props.disabled}
                      value={data.OtherMedication}
                      placeholder="-"
                      customGrid={[3, 9]}
                      onChange={handleDataInput('OtherMedication')}
                    />
                  ) : (
                    ''
                  )}
                  <DateField
                    id="sas-approval-date"
                    label="Date of Approval"
                    onChange={handleDataApInput('DateOfApproval')}
                    value={data.DateOfApproval}
                    disabled={props.disabled}
                    labelPadding="7px 5px"
                    customGrid={[3, 9]}
                  />
                  <DateField
                    id="sas-expiration-date"
                    label="Approved Expiration Date"
                    onChange={handleDataInput('ExpirationDate')}
                    value={data.ExpirationDate}
                    disabled={props.disabled}
                    labelPadding="7px 5px"
                    customGrid={[3, 9]}
                    selectedApprovalDate={data.DateOfApproval}
                  />
                  <InputField
                    label="MB Number"
                    id="mb-number"
                    type="text"
                    labelPadding="7px 5px"
                    value={data.MbNumber}
                    disabled={props.disabled}
                    customGrid={[3, 9]}
                    onChange={handleDataInput('MbNumber')}
                  />
                </div>
              ) : (
                ''
              )}
            </Grid>
          </Box>
        </SectionBody>
      </Box>
      <Box mt={2} display="flex" flexDirection="row-reverse">
        <Button variant="contained" disabled={loading || props.disabled} color="secondary" onClick={onSumbit}>
          <Box width="100px">{loading ? <CircularProgress size={16} color="secondary" /> : 'Submit'}</Box>
        </Button>
      </Box>
    </Stack>
  );
};

export default Documents;
