import type { CatalogProduct } from '@/hooks/rest/useGetProductList';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';

type ProductSelectCellProps = {
  value: string | number;
  options: CatalogProduct[];
  onSelect: (selectedProduct: CatalogProduct | undefined) => void;
};

const ProductSelectCell = ({ value, options, onSelect }: ProductSelectCellProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    const altProductId = parseInt(event.target.value);
    const selectedProduct = options.find((product) => product.id === altProductId);
    onSelect(selectedProduct);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Select
          displayEmpty
          value={value.toString()}
          onChange={handleChange}
          onClick={(event) => event.stopPropagation()}
        >
          {options.map((product) => (
            <MenuItem key={product.id} value={product.id}>
              {product.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export { ProductSelectCell };
