import type { UseFormProps } from 'react-hook-form';
import { useController, useForm } from 'react-hook-form';

import { DateTime } from 'luxon';
import type { ConcessionCardFile, ConcessionCardType } from '../../components/concession';
import { CONCESSION_CARD_TYPE } from '../../components/concession';

export type UseConcessionCardFormValues = {
  cardType: ConcessionCardType | '';
  cardNumber: string;
  cardExpiry: DateTime | null;
  cardImage: ConcessionCardFile | null;
};

type UseConcessionCardFormProps = UseFormProps<UseConcessionCardFormValues>;

// TODO: Convert to Formik
/**
 *
 * @param props
 */
export function useConcessionCardForm(props?: UseConcessionCardFormProps) {
  const { control, ...otherForm } = useForm<UseConcessionCardFormValues>({
    ...props,
    defaultValues: {
      cardType: '',
      cardNumber: '',
      cardExpiry: null,
      cardImage: null,
      ...(props || {}).defaultValues
    }
  });

  const controlCardType = useController({
    name: 'cardType',
    control,
    rules: {
      validate: (cardType) => {
        if (!cardType || Object.values(CONCESSION_CARD_TYPE).indexOf(cardType) === -1) {
          return 'Please select a valid card type';
        }
        return true;
      }
    }
  });

  const controlCardNumber = useController({
    name: 'cardNumber',
    control,
    rules: {
      required: 'Please enter a valid card number'
    }
  });

  const controlCardExpiry = useController({
    name: 'cardExpiry',
    control,
    rules: {
      validate: (cardExpiry) => {
        if (!cardExpiry || !cardExpiry.isValid || cardExpiry < DateTime.local().startOf('day')) {
          return 'Please enter a valid expiry date';
        }
        return true;
      }
    }
  });

  const controlCardUpload = useController({
    name: 'cardImage',
    control,
    rules: {
      validate: (value) => {
        if (!value) {
          return 'Please upload proof of card';
        }
        return true;
      }
    }
  });

  const defaultControllers = {
    controlCardType,
    controlCardNumber,
    controlCardExpiry,
    controlCardUpload
  };

  return {
    ...otherForm,
    control,
    defaultControllers
  };
}

export default useConcessionCardForm;
