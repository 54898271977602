import { useCallback, useEffect, useRef } from 'react';

/**
 * Custom hook to create a debounced callback function.
 * @param callback - The function to debounce.
 * @param delay - The delay in milliseconds for the debounce.
 * @param dependencies - An array of dependencies that, when changed, will recreate the debounced function.
 * @returns A debounced version of the provided callback function.
 *
 * @remarks
 * Ensure that the `dependencies` array contains stable references (e.g., memoized functions or state values).
 * Unstable dependencies may cause the debounced function to be recreated on every dependencies changes, leading to
 * unnecessary re-creation and defeating the purpose of the debounce.
 */

const useDebouncedCallback = <T extends (...args: any[]) => void>(
  callback: T,
  delay: number,
  dependencies: any[] = []
): T => {
  const timeoutRef = useRef<number | null>(null);

  const debouncedFunction = useCallback(
    (...args: Parameters<T>) => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = window.setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [delay, ...dependencies]
  );

  useEffect(() => {
    // Clear timeout on unmount to avoid memory leaks
    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return debouncedFunction as T;
};

export default useDebouncedCallback;
