import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, ListItem, styled, Tooltip, Typography } from '@mui/material';

import type {
  DateOverrideRange,
  TimeRange
  // TODO: workingHours may be needed for Phase 2. Keeping this here for now until confirmed it can be removed
  // WorkingHours
} from '@/components/calcom/shared/types/calcom-schedule-types';

import DateOverrideInputDialog from './DateOverrideInputDialog';

/**
 * Date Override List Item component
 *
 * @remarks
 * An item within the DateOverrideList which shows the overrides for a specific date.
 *
 * NOTE: This component was taken and adapted to PMS from a Cal.com component 'DateOverrideListItem'
 * See - https://github.com/calcom/cal.com/blob/main/apps/web/pages/availability/[schedule].tsx
 *
 * @param item - An single item which contain the date and times being overriden
 * @param workingHours - The standard working hours for that user. Used for default values
 * @param excludedDates - Dates which the user is not working on
 * @param handleReplaceFields - Handler for React-Hook-Form method to replace a field in the form array
 * @param handleRemoveFields - Handler for React-Hook-Form method to remove a field from the form array
 *
 * @returns JSXElement
 *
 */

export type DateOverrideListItemProps = {
  item: DateOverrideRange;
  // TODO: workingHours may be needed for Phase 2. Keeping this here for now until confirmed it can be removed
  // workingHours: WorkingHours[];
  excludedDates?: string[];
  handleReplaceFields: (ranges: TimeRange[], item: DateOverrideRange) => void;
  handleRemoveFields: (item: DateOverrideRange) => void;
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
  justifyContent: 'space-between',
  marginBottom: 15,
  border: `1px solid ${theme.palette.common.black}`,
  padding: '15px 20px',
  borderRadius: 5
}));

const StyledEditOverrideButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  marginRight: 10,

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}));

const StyledDeleteOverrideButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}));

const DateOverrideListItem = (props: DateOverrideListItemProps) => {
  const {
    item,
    // TODO: workingHours may be needed for Phase 2. Keeping this here for now until confirmed it can be removed
    // workingHours,
    excludedDates,
    handleReplaceFields,
    handleRemoveFields
  } = props;

  const timeSpan = ({ start, end }: TimeRange) => {
    return (
      new Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric' }).format(
        new Date(start.toISOString().slice(0, -1))
      ) +
      ' - ' +
      new Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric' }).format(
        new Date(end.toISOString().slice(0, -1))
      )
    );
  };

  return (
    <StyledListItem key={item.id}>
      <Box>
        <Typography variant="h6" fontSize={'1rem'} fontWeight={'bold'}>
          {new Intl.DateTimeFormat('en-GB', {
            weekday: 'short',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC'
          }).format(item.ranges[0].start)}
        </Typography>
        <Box>
          {item.ranges[0].start.valueOf() - item.ranges[0].end.valueOf() === 0 ? (
            <Typography>Unavailable all day</Typography>
          ) : (
            item.ranges.map((range, i) => <Typography key={i}>{timeSpan(range)}</Typography>)
          )}
        </Box>
      </Box>
      <Box display={'flex'}>
        <DateOverrideInputDialog
          excludedDates={excludedDates}
          // TODO: workingHours may be needed for Phase 2. Keeping this here for now until confirmed it can be removed
          // workingHours={workingHours}
          value={item.ranges}
          onChange={(ranges) => handleReplaceFields(ranges, item)}
          trigger={
            <Tooltip title="Edit date override">
              <StyledEditOverrideButton variant="outlined">
                <EditIcon />
              </StyledEditOverrideButton>
            </Tooltip>
          }
        />
        <Tooltip title="Delete date override">
          <StyledDeleteOverrideButton variant="outlined" onClick={() => handleRemoveFields(item)}>
            <DeleteForeverIcon />
          </StyledDeleteOverrideButton>
        </Tooltip>
      </Box>
    </StyledListItem>
  );
};

export default DateOverrideListItem;
