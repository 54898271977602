import type { MedicationType } from '../../components/PatientPageV2/components/ProductDetailsCard/ProductDetailsCard.types';

/**
 * Helper function to return an error message on a medication item.
 * @param medicationPadItem - medicationPadItem object to validate.
 * @returns string - error message.
 */
export const getErrorMessageForMedicationItem = (
  medicationPadItem?: MedicationType,
  ffPatPrescriptionDosageMinLengthValidation?: boolean
) => {
  if (!medicationPadItem?.productName) {
    return null;
  }

  if (!ffPatPrescriptionDosageMinLengthValidation) {
    return null;
  }

  /**
   * Validation for dosage field
   */
  const medicationDosage = medicationPadItem?.dosage;
  if (!medicationDosage && !medicationDosage?.length) {
    return {
      title: 'Dosage Required:',
      description: 'Please provide detailed dosage instructions.'
    };
  } else if (medicationDosage?.length < 50) {
    return {
      title: 'Action Required:',
      description: 'Dosage instructions must be at least 50 characters.'
    };
  }
  return null;
};
