import { useDeactivateDoctor } from '@/hooks/rest/useDeactivateDoctor';
import { useGetDoctorPatientAndConsultCount } from '@/hooks/rest/useGetDoctorPatientAndConsultCount';
import { Button, ConfirmDialog, toast } from '@montugroup/design-system';
import { Link, Typography } from '@mui/material';
import { useModal } from 'mui-modal-provider';

export type DeactivateDoctorPopupButtonProps = {
  doctorId: number;
  doctorUserId: number;
  doctorCode: string;
  isButton?: boolean;
};

const DeactivateDoctorPopupButton = ({
  doctorId,
  doctorUserId,
  doctorCode,
  isButton
}: DeactivateDoctorPopupButtonProps) => {
  const { mutateAsync: deactivateDoctor } = useDeactivateDoctor();
  const { refetch } = useGetDoctorPatientAndConsultCount({ doctorId, autoRun: false });
  const { showModal } = useModal();

  const doDoctorDeactivation = async () => {
    try {
      await deactivateDoctor({ doctorId, userId: doctorUserId });
      toast.success(`Doctor (${doctorCode}) has been deactivated`);
    } catch {
      deactivateDoctorErrorModal();
    }
  };

  const deactivateDoctorSubmissionModal = () => {
    const modal = showModal(ConfirmDialog, {
      id: 'deactivate-doctor-popup-submission-content',
      title: `Deactivate Doctor`,
      children: (
        <>
          <Typography fontWeight={500}>
            Are you sure you want to deactivate this Doctor <strong>({doctorCode})</strong>?
          </Typography>
          <Typography color="error.main">This action is irreversible!</Typography>
        </>
      ),
      actions: (
        <>
          <Button
            data-testid="deactivate-doctor-popup-submission-content-cancel-button"
            variant="text"
            color="secondary"
            onClick={() => {
              modal.hide();
            }}
          >
            Cancel
          </Button>
          <Button
            data-testid="deactivate-doctor-popup-submission-content-deactivate-button"
            variant="contained"
            color="secondary"
            onClick={async () => {
              modal.hide();
              doDoctorDeactivation();
            }}
          >
            Deactivate
          </Button>
        </>
      )
    });
  };

  const deactivateDoctorInfoModal = ({
    consultationCount,
    patientCount
  }: {
    consultationCount: number;
    patientCount: number;
  }) => {
    const modal = showModal(ConfirmDialog, {
      id: 'deactivate-doctor-popup-active-info-content',
      title: `Deactivate Doctor`,
      children: (
        <>
          <Typography>
            This Doctor <strong>({doctorCode})</strong> has <strong>{consultationCount}</strong> active patients and{' '}
            <strong>{patientCount}</strong> active consultations.
          </Typography>
          <Typography>
            They cannot be deactivated until after all of their patients/consultations have been cancelled or
            reassigned.
          </Typography>
        </>
      ),
      actions: (
        <Button
          data-testid="deactivate-doctor-popup-active-info-content-cancel-button"
          variant="text"
          color="secondary"
          onClick={() => {
            modal.hide();
          }}
        >
          Cancel
        </Button>
      )
    });
  };

  const deactivateDoctorErrorModal = () => {
    const modal = showModal(ConfirmDialog, {
      id: 'deactivate-doctor-popup-error-content',
      title: `Deactivate Doctor`,
      children: (
        <Typography>
          There was an error Deactivating this Doctor <strong>({doctorCode})</strong>. Please try again later.
        </Typography>
      ),
      actions: (
        <>
          <Button
            data-testid="deactivate-doctor-popup-error-content-cancel-button"
            variant="text"
            color="secondary"
            onClick={() => {
              modal.hide();
            }}
          >
            Cancel
          </Button>
          <Button
            data-testid="deactivate-doctor-popup-error-content-retry-button"
            variant="contained"
            color="secondary"
            onClick={() => {
              modal.hide();
              doDoctorDeactivation();
            }}
          >
            Retry
          </Button>
        </>
      )
    });
  };

  const onDeactivateDoctor = async () => {
    const result = await refetch({ cancelRefetch: false });

    const consultationCount = result.data ? result.data.consultationCount : 0;
    const patientCount = result.data ? result.data.patientCount : 0;

    if (consultationCount > 0 || patientCount > 0) {
      deactivateDoctorInfoModal({ consultationCount, patientCount });
    } else {
      deactivateDoctorSubmissionModal();
    }
  };

  const PopupButton = (
    <Button
      color={'secondary'}
      onClick={onDeactivateDoctor}
      sx={{ textDecoration: 'none', cursor: 'pointer' }}
      data-testid="deactivate-direct-popup-button-trigger"
    >
      Deactivate
    </Button>
  );

  const PopupLink = (
    <Link
      onClick={onDeactivateDoctor}
      sx={{ textDecoration: 'none', cursor: 'pointer' }}
      data-testid="deactivate-direct-popup-link-trigger"
    >
      Deactivate
    </Link>
  );

  const PopupTrigger = isButton ? PopupButton : PopupLink;

  return PopupTrigger;
};

export default DeactivateDoctorPopupButton;
