import { CircularProgress, MenuItem, Select, Stack, styled, Typography } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import type { ScheduleResponse } from '../shared/types/calcom-schedule-types';
import { daysOfWeek } from '../shared/utils/days-of-week';
import Schedule from './components/Schedule';

/**
 * Availability Scheduler component
 *
 * @remarks
 * This component is part of the "UpdateScheduleForm" and allows the user to update a Calcom users availability
 *
 * @param scheduleResponse - The response from the API which includes availability and override data etc
 *
 * @returns JSXElement
 *
 */

/**
 *
 * Filtered out the Australian options for timezones from the API response when incorrect value is used
 */
const CalcomTimeZones = [
  'Australia/Darwin',
  'Australia/Perth',
  'Australia/Eucla',
  'Australia/Brisbane',
  'Australia/Lindeman',
  'Australia/Adelaide',
  'Australia/Hobart',
  'Australia/Melbourne',
  'Australia/Sydney',
  'Australia/Broken_Hill',
  'Australia/Lord_Howe',
  'Australia/ACT',
  'Australia/LHI',
  'Australia/NSW',
  'Australia/North',
  'Australia/Queensland',
  'Australia/South',
  'Australia/Tasmania',
  'Australia/Victoria',
  'Australia/West',
  'Australia/Yancowinna',
  'Australia/Canberra',
  'Australia/Currie'
];

type AvailabilitySchedulerProps = {
  scheduleResponse: ScheduleResponse | undefined;
};

const StyledCircularProgress = styled(CircularProgress)({
  margin: 0,
  position: 'absolute',
  top: 'calc(50% - 60px)',
  left: '50%',
  transform: 'translateY(-50%)'
});

const AvailabilityScheduler = ({ scheduleResponse }: AvailabilitySchedulerProps) => {
  const weekStart = daysOfWeek.indexOf('Sunday');
  const form = useFormContext();
  const [timeZone, setTimeZone] = useState(form.getValues('timeZone') ?? scheduleResponse?.timeZone);

  return scheduleResponse?.availability ? (
    <Stack spacing={6}>
      <Typography variant="h6" marginBottom={4} marginTop={3} textAlign={'center'}>
        Update users availability
      </Typography>

      {scheduleResponse?.timeZone && (
        <Stack direction={'row'} alignContent="center" alignItems="center" spacing={4}>
          <Typography variant="h6" textAlign={'center'}>
            Timezone:
          </Typography>

          <Select
            displayEmpty
            value={timeZone}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setTimeZone(selectedValue);
              form.setValue('timeZone', selectedValue, { shouldDirty: true });
            }}
          >
            {CalcomTimeZones.map((timeZone) => (
              <MenuItem key={timeZone} value={timeZone}>
                {timeZone}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      )}
      <Schedule control={form.control} name="schedule" weekStart={weekStart} />
    </Stack>
  ) : (
    <StyledCircularProgress data-testid="loading" />
  );
};

export default AvailabilityScheduler;
