import { Box, Typography } from '@mui/material';

import { useGetPrescriptionsWithOOSMedications } from '@/hooks/rest/useGetPrescriptionsWithOOSMedications';
import type { CatalogProduct } from '@/hooks/rest/useGetProductList';
import usePutPrescriptBatchReplace from '@/hooks/rest/usePutPrescriptBatchReplace';
import type { Prescription } from '@/types';
import { toast } from '@montugroup/design-system';
import type { RowSelectionState } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { SUCCESSFUL_SCRIPT_UPDATE_MESSAGE } from '../../Rescripting.constants';
import { useHandleRescriptingPermissions } from '../../hooks/useHandleRescriptingPermissions';
import { useRescriptAlternatives } from '../../hooks/useRescriptAlternatives';
import { RescriptingTable } from '../shared/RescriptingTable';
import { OutOfStockContent } from './OutOfStockContent';
import {
  buildOutOfStockMedicationTableHeaders,
  buildOutOfStockRescriptPutBody,
  buildOutOfStockTableData
} from './OutOfStockPanel.utils';

const OutOfStockPanel = () => {
  const [modifiedScripts, setModifiedScripts] = useState<Map<string, CatalogProduct>>(new Map());
  const [selectedUniqueRowKeys, setSelectedUniqueRowKeys] = useState<string[]>([]);
  const [internalTableRowSelectedState, setInternalTableRowSelectedState] = useState<RowSelectionState>({});

  const {
    data: getPrescriptionsResponse,
    loading: prescriptionRequestLoading,
    error: prescriptionRequestError,
    doGet: doGetPrescriptionsWithOOSMedications
  } = useGetPrescriptionsWithOOSMedications({
    limit: 10000,
    status: 'ACTIVE'
  });

  const {
    data: alternativeProductList,
    loading: productRequestLoading,
    error: productRequestError
  } = useRescriptAlternatives();

  const {
    loading: putPrescriptionLoading,
    error: putPrescriptionError,
    doPut: putPrescriptionBatchReplace
  } = usePutPrescriptBatchReplace();

  useHandleRescriptingPermissions({
    isGetPrescriptionError: Boolean(prescriptionRequestError),
    isPutPrescriptionError: Boolean(putPrescriptionError),
    isGetProductsError: Boolean(productRequestError)
  });

  const resetTableState = () => {
    setModifiedScripts(new Map());
    setSelectedUniqueRowKeys([]);
    setInternalTableRowSelectedState({});
  };

  const handleApproval = async (
    selectedUniqueRowKeys: string[],
    prescriptionsWithOOSMedications: Prescription[],
    modifiedScripts: Map<string, CatalogProduct>
  ) => {
    const resultArray = buildOutOfStockRescriptPutBody(
      selectedUniqueRowKeys,
      prescriptionsWithOOSMedications,
      modifiedScripts
    );

    const { data } = await putPrescriptionBatchReplace({ rescriptType: 'OUT_OF_STOCK', prescriptions: resultArray });

    if (data) {
      toast.success({ message: SUCCESSFUL_SCRIPT_UPDATE_MESSAGE });
      await doGetPrescriptionsWithOOSMedications();
    }

    resetTableState();
  };

  const loading = prescriptionRequestLoading || productRequestLoading || putPrescriptionLoading;

  const tableColumnHeaders = useMemo(
    () => buildOutOfStockMedicationTableHeaders(alternativeProductList, setModifiedScripts),
    [alternativeProductList]
  );

  const tableData = useMemo(
    () => buildOutOfStockTableData(getPrescriptionsResponse, modifiedScripts),
    [getPrescriptionsResponse, modifiedScripts]
  );

  return (
    <Box paddingX={2} paddingY={0}>
      <OutOfStockContent />
      <Box>
        <Typography variant="h3" fontSize="24px" mb="1" fontWeight={600}>
          Out of stock scripts
        </Typography>
        <RescriptingTable
          tableColumnHeaders={tableColumnHeaders}
          tableData={tableData}
          selectedUniqueRowKeys={selectedUniqueRowKeys}
          setSelectedUniqueRowKeys={setSelectedUniqueRowKeys}
          internalTableRowSelectedState={internalTableRowSelectedState}
          setInternalTableRowSelectedState={setInternalTableRowSelectedState}
          handleApproval={(uniqueRowKeys) =>
            handleApproval(uniqueRowKeys, getPrescriptionsResponse?.prescriptions ?? [], modifiedScripts)
          }
          isLoading={loading}
          rescriptingReason="oos"
        />
      </Box>
    </Box>
  );
};

export { OutOfStockPanel };
