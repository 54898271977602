import { Button } from '@montugroup/design-system';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';

import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import AppBanner from '../components/AppBanner';
import settings from '../data/constants';

const API_URL = settings.url;

const PatientConsentForm = () => {
  const [data, setData] = useState({
    questions: [],
    signatureURL: undefined
  });

  const [signature, setSignature] = useState({});
  const [isSubmitted, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Something went wrong. Please try again.');

  const { uuid, patientId } = useParams();

  const setSignUrl = () => setData({ ...data, signatureURL: signature.getTrimmedCanvas().toDataURL('image/png') });

  const getQuestions = useCallback(async () => {
    try {
      const { data: patient } = await axios.get(
        `${API_URL}/patient/consent-form/status?uuid=${uuid}&patientId=${patientId}`
      );
      if (!patient) {
        throw new Error('Patient data not found');
      }
      if (patient && patient.is_submitted) {
        setStatus(true);
        return;
      }
      const { data: questions } = await axios.get(`${API_URL}/patient/consent-form/questions`);
      if (questions?.length) {
        setData({
          first_name: patient.Patient.PatientUser.first_name,
          last_name: patient.Patient.PatientUser.last_name,
          questions
        });
      }
    } catch (error) {
      console.error('Error fetching patient data:', error);
      setHasError(true);
      setErrorMessage('This consent form has expired. Please contact the clinic for further assistance.');
    }
  }, [uuid, patientId]);

  useEffect(() => {
    setLoading(true);
    getQuestions().finally(() => setLoading(false));
  }, [getQuestions]);

  const [error, setError] = useState(false);

  const handleSubmit = async () => {
    if (!data.signatureURL) {
      document.getElementById('logo')?.scrollIntoView({ behavior: 'smooth' });
      setError(true);
      return;
    }
    const sendData = {
      uuid,
      signature: data.signatureURL,
      patientId,
      firstName: data.first_name,
      lastName: data.last_name
    };
    setLoading(true);

    const response = await axios.post(`${API_URL}/patient/consent-form/submit`, sendData).catch((_error) => {
      console.error(`Error submitting consent form.`, _error);
    });
    if (!response) {
      setHasError(true);
    } else {
      setStatus(true);
    }
    setLoading(false);
  };

  const renderConsentForm = () => {
    return (
      <>
        <Box marginTop={4}>
          {isSubmitted ? (
            <Alert severity="success" variant="outlined">
              Patient Consent Form Submitted Successfully
            </Alert>
          ) : (
            <Alert severity={error ? 'error' : 'info'} variant="outlined">
              All fields are mandatory *
            </Alert>
          )}
        </Box>
        <Typography variant="p" component="p" sx={{ fontSize: { xs: 30, md: 34 } }} mt={5} fontWeight={600}>
          {isSubmitted ? 'Thank You' : 'Treatment Consent Form'}
        </Typography>
        {isSubmitted && (
          <>
            <Typography
              variant="p"
              component="p"
              sx={{ marginTop: { xs: 1, md: 2 }, marginBottom: { xs: 5, md: 7 }, fontSize: { xs: 16, md: 18 } }}
            >
              You're all set for your upcoming consult.
            </Typography>
            <Button
              variant="contained"
              type="button"
              onClick={() => window.location.replace('https://www.alternaleaf.com.au/')}
              formTarget="_blank"
            >
              Go to Alternaleaf website
            </Button>
          </>
        )}
        {!isSubmitted && (
          <Box mt={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth>
                  <TextField value={data.first_name || ''} variant="standard" disabled />
                  <Box component={FormHelperText} sx={{ marginLeft: '0px' }}>
                    First Name
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth>
                  <TextField value={data.last_name || ''} variant="standard" disabled />
                  <Box component={FormHelperText} sx={{ marginLeft: '0px' }}>
                    Last Name
                  </Box>
                </FormControl>
              </Grid>
            </Grid>
            <Box mb={3} sx={{ mt: { xs: 1, md: 2 } }}>
              <FormControl fullWidth>
                <DatePicker value={DateTime.now()} readOnly format="dd MMM yyyy" disabled />
                <Box component={FormHelperText} sx={{ marginLeft: '0px' }}>
                  Date of signed consent form
                </Box>
              </FormControl>
            </Box>
            <Typography variant="p" component="p" lineHeight={1.5} mt={2} sx={{ fontSize: { xs: 16, md: 18 } }}>
              The purpose of this form is to obtain/confirm your informed consent for treatment if your doctor
              prescribes medical cannabis treatment for your condition.
            </Typography>
            <Typography variant="p" component="p" lineHeight={1.5} mt={2} sx={{ fontSize: { xs: 16, md: 18 } }}>
              Informed consent is a person's decision, given voluntarily, to agree to a healthcare treatment, procedure,
              or other intervention that is made:
            </Typography>
            <Typography variant="p" component="p" lineHeight={1.5} mt={2} sx={{ fontSize: { xs: 16, md: 18 } }}>
              Following the provision of accurate and relevant information about the healthcare intervention and
              alternate options available; and
            </Typography>
            <Typography variant="p" component="p" lineHeight={1.5} mt={2} sx={{ fontSize: { xs: 16, md: 18 } }}>
              With adequate knowledge and understanding of the benefits and material risks of the proposed intervention
              relevant to the person who would be having the treatment, procedure or other intervention. (Reference:
              Australian Commission on Safety and Quality in Health Care.)
            </Typography>
            <Typography variant="p" component="p" lineHeight={1.5} mt={2} sx={{ fontSize: { xs: 16, md: 18 } }}>
              Please review the following statements regarding your treatment and sign below to confirm that our
              clinicians have provided you information about these matters, given you the opportunity to ask questions
              and discuss concerns.
            </Typography>
            {data.questions.map((question, idx) => (
              <Box mt={3} key={`Consent-Question-${idx}`}>
                <Typography variant="p" component="p" lineHeight={2} fontSize={26} fontWeight={600}>
                  {question.category}
                </Typography>
                <Typography variant="p" component="p" sx={{ fontSize: { xs: 16, md: 18 } }}>
                  {question.description}
                </Typography>
              </Box>
            ))}
            <Box mt={4}>
              <Typography variant="p" component="p" fontSize={26} fontWeight={600} mb={2}>
                Signature
              </Typography>
              <FormControl fullWidth>
                {!data.signatureURL && (
                  <InputLabel htmlFor="sign-pad" style={{ left: 10, color: error ? '#ff4b4b' : 'grey' }}>
                    Click and sign
                  </InputLabel>
                )}
                <Box>
                  <SignatureCanvas
                    id="sign-pad"
                    penColor="green"
                    canvasProps={{ width: window.innerWidth > 600 ? 500 : window.innerWidth * 0.85, height: 180 }}
                    backgroundColor="#eaeaea"
                    ref={(ref) => setSignature(ref)}
                    onBegin={setSignUrl}
                    onEnd={setSignUrl}
                  />
                </Box>
                <Button
                  variant="text"
                  sx={{
                    cursor: 'pointer',
                    width: 'fit-content',
                    padding: '0px',
                    fontSize: '0.8rem',
                    textTransform: 'none',
                    color: 'grey',
                    '&:hover': {
                      background: 'none'
                    }
                  }}
                  component={FormHelperText}
                  onClick={() => {
                    signature.clear();
                    setData({ ...data, signatureURL: undefined });
                  }}
                >
                  Clear Signature
                </Button>
              </FormControl>
            </Box>
            <Box my={4}>
              <Button variant="contained" disabled={loading} onClick={handleSubmit}>
                Submit
              </Button>
            </Box>
          </Box>
        )}
      </>
    );
  };

  return (
    <AppBanner marginTop={-25}>
      <Box width="100%" sx={{ paddingY: { xs: 5, md: 10 } }}>
        <Container sx={{ maxWidth: { sm: '90vw', md: '40vw', lg: '35vw' }, minHeight: '100vh' }}>
          <img id="logo" width="200px" src="/LogoV2.png" alt="Alternaleaf | PMS" />
          {loading && <Typography variant="body1">Loading...</Typography>}
          {!loading && !hasError && renderConsentForm()}
          {hasError && (
            <Box mt={4}>
              <Typography variant="body1" component="p" sx={{ fontSize: { xs: 12, md: 18 } }}>
                {errorMessage}
              </Typography>
            </Box>
          )}
        </Container>
      </Box>
    </AppBanner>
  );
};

export default PatientConsentForm;
