import type { PatientDetailsData } from '@/hooks/patients/useGetPatientDetails';
import { Flag } from '@mui/icons-material';
import { Box, CircularProgress, Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';

interface PatientBasicInfoProps {
  isLoading: boolean;
  data: PatientDetailsData;
}

const PatientBasicInfo: React.FC<PatientBasicInfoProps> = ({ isLoading, data }) => {
  const basicInfoData = data?.basicInfo?.attributes;
  const redflagInfoData = data?.redFlagInfo?.attributes;

  if (isLoading) {
    return (
      <Box justifyContent="center" alignItems="center" gap="1" display="flex">
        <CircularProgress size={25} />
        <Typography variant="h4">Loading</Typography>
      </Box>
    );
  }
  return (
    <Box pl={0.5} my={3}>
      <Box>
        {isLoading && (
          <Box justifyContent="center" alignItems="center" gap="1" display="flex">
            <CircularProgress size={25} />
            <Typography variant="h4">Loading</Typography>
          </Box>
        )}
        <Typography fontWeight={600}>
          {basicInfoData?.firstName} {basicInfoData?.lastName}
          {redflagInfoData?.hasRedFlag && (
            <Tooltip title={redflagInfoData?.redFlagComment || ''}>
              <Flag fontSize="small" color="error" />
            </Tooltip>
          )}{' '}
        </Typography>
      </Box>
      <Typography basicInfoData-dd-privacy="allow" variant="body2">
        {basicInfoData?.patientCode}
      </Typography>
      <Typography variant="body2">
        DoB: {basicInfoData?.dob ? DateTime.fromISO(basicInfoData.dob).toFormat('dd-MMM-yyyy') : 'N/A'}
      </Typography>
      <Typography variant="body2">
        Age:{' '}
        {basicInfoData?.dob
          ? Math.floor(DateTime.now().diff(DateTime.fromISO(basicInfoData.dob), ['years']).years)
          : 'N/A'}
      </Typography>
    </Box>
  );
};

export default PatientBasicInfo;
