import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';

import styles from './styles';

const useStyles = makeStyles(styles);
const subLabelStyle = {
  fontSize: 12,
  color: 'red'
};
const labelStyle = {
  display: 'block'
};
/**
 * @deprecated due to the non-scalable composition of the component
 */
const SelectField = (props) => {
  const classes = useStyles();
  return (
    <Box
      component={Grid}
      width="100%"
      display={props.display ? props.display : 'flex'}
      m={props.margin !== undefined ? props.margin : 1}
      p={isNaN(props.padding) ? 0 : props.padding}
      container
    >
      <Grid xs={props.customGrid ? props.customGrid[0] : 4} item={true}>
        <Box
          component="div"
          textAlign={props.textAlign ? props.textAlign : 'left'}
          p={props.labelPadding ? props.labelPadding : '12px'}
          px={props.labelPadding ? '' : '10px'}
          className={`${props.disabled && classes.disabledLabel} ${props.labelClasses ? props.labelClasses : ''}`}
          fontWeight={600}
        >
          <label style={{ whiteSpace: 'nowrap' }} htmlFor={props.id}>
            {props.label}
          </label>
        </Box>
      </Grid>
      <Grid xs={props.customGrid ? props.customGrid[1] : 8} item={true}>
        <FormControl className={classes.TextField} fullWidth>
          <Box p={props.selectPadding !== undefined ? props.selectPadding : 0}>
            <Select
              id={props.id}
              value={props.value}
              onChange={props.onChange}
              displayEmpty
              fullWidth
              disabled={props.disabled}
              className={props.InputClasses ? props.InputClasses : ''}
              variant={props.varient ? props.varient : 'standard'}
              classes={{ select: classes.TextField }}
              style={{ color: '#0F455D' }}
              renderValue={props.renderValue}
            >
              {Array.isArray(props.options) &&
                props.options.map((itm, idx) => (
                  <MenuItem key={idx} value={itm.value} style={itm.error ? labelStyle : null}>
                    <div>{itm.name}</div>
                    {itm.error ? <div style={subLabelStyle}>{itm.subLabel}</div> : null}
                  </MenuItem>
                ))}
            </Select>
          </Box>
        </FormControl>
      </Grid>
    </Box>
  );
};

export default SelectField;
