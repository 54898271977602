import { documentServiceClient } from '@/api/serviceClients';
import { QueryKey } from '@/data/query';
import { getToken } from '@/data/service/authService';
import { useMemo } from 'react';

export const usePatientDocuments = (patientId: number) => {
  const queryKey = useMemo(() => [QueryKey.GetPatientDocuments, patientId], [patientId]);

  const { data, isError, isLoading, refetch } = documentServiceClient().getDocument.useQuery(
    queryKey,
    { params: { patientId }, headers: { authorization: `Bearer ${getToken()}` } },
    {
      queryKey,
      refetchOnMount: 'always'
    }
  );
  return {
    data,
    isError,
    isLoading,
    refetch
  };
};
