import previewNotificationFormClient from '@/api/document/previewNotificationFormClient';
import { QueryKey } from '@/data/query';
import { handleOnError } from './usePostPreviewNotificationForm.utils';

const usePostPreviewNotificationForm = () => {
  const queryKey = [QueryKey.PostPreviewNotificationForm];

  return previewNotificationFormClient.previewNotificationForm.useMutation({
    mutationKey: queryKey,
    onError: ({ status, body }) => handleOnError(status, body)
  });
};

export default usePostPreviewNotificationForm;
