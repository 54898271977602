interface DiscontinuedScriptMedicationCellData {
  name: string;
  isDiscontinued: boolean;
}

interface DiscontinuedTableRowData {
  uniqueRowKey: string;
  patientName: string;
  patientCode: string;
  patientId: string;
  discontinuedMedicationName: string;
  allMedicationsOnScript: DiscontinuedScriptMedicationCellData[];
  selectedAlternativeProductId: number;
  doctorName: string;
  ageOfRescriptRequest: string;
}

enum DiscontinuedTableColumnHeaders {
  Age_Of_Rescript_Request = 'Patient Requested',
  Patient_Name = 'Patient Name',
  Patient_Code = 'Patient Code',
  Discontinued_Medication = 'Discontinued Medication',
  Script_Medications = 'Script Medications',
  Alternative_Medication_List = 'Alternative Medication',
  Doctor_Name = 'Doctor'
}

export type { DiscontinuedScriptMedicationCellData, DiscontinuedTableRowData };

export { DiscontinuedTableColumnHeaders };
