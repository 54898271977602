import type { RescriptingReason } from '@/components/Rescripting/Rescripting.types';
import type { AutocompleteMultiSelectOption } from '@montugroup/design-system';
import type { DiscontinuedTableRowData } from '../../DiscontinuedPanel/DiscontinuedPanel.types';
import type { OutOfStockTableRowData } from '../../OutOfStockPanel/OutOfStockPanel.types';

export type TableFilersProps = {
  tableRowData: OutOfStockTableRowData[] | DiscontinuedTableRowData[];
  rescriptingReason: RescriptingReason;
  onPatientCodeSelect: (selected: AutocompleteMultiSelectOption[]) => void;
  onMedicationNameSelect: (selected: AutocompleteMultiSelectOption[]) => void;
  onDoctorNameSelect: (selected: AutocompleteMultiSelectOption[]) => void;
  setShouldResetPageIndex: React.Dispatch<React.SetStateAction<boolean>>;
};

export enum TableFilterLabels {
  patientCodes = 'Filter patient codes',
  medicationNames = 'Filter medication names',
  doctors = 'Filter doctor names'
}
