import { useEffect, useMemo } from 'react';

import { getUser } from '@/data/service/authService';
import { UserRole } from '@/types';
import { toast } from '@montugroup/design-system';
import { GENERIC_FETCH_ERROR_MESSAGE } from '../Rescripting.constants';

export type UseHandleRescriptingPermissionProps = {
  isGetPrescriptionError?: boolean;
  isPutPrescriptionError?: boolean;
  isGetProductsError?: boolean;
};

export type UseHandleRescriptingPermissionResult = {
  /**
   * Whether the error alert should be shown. This is dependent on the action that
   * the user can take, and whether the errors are "expected"
   */
  shouldShowErrorAlert: boolean | null | undefined;
};

export const useHandleRescriptingPermissions = ({
  isGetPrescriptionError,
  isPutPrescriptionError,
  isGetProductsError
}: UseHandleRescriptingPermissionProps): UseHandleRescriptingPermissionResult => {
  const user = getUser();
  const shouldShowErrorAlert = useMemo(() => {
    // if it's a non-Doctor role, ignore the other two errors, as they're expected to happen
    // i.e. Nurse can't do UPDATEs, so the 403 error is not seeing as an "error" to surface to the client
    if (user.role_id !== UserRole.Doctor) {
      return isGetPrescriptionError;
    }
    return isGetPrescriptionError || isPutPrescriptionError || isGetProductsError;
  }, [isGetPrescriptionError, isGetProductsError, isPutPrescriptionError, user.role_id]);

  useEffect(() => {
    if (shouldShowErrorAlert) {
      toast.error({ message: GENERIC_FETCH_ERROR_MESSAGE });
    }
  }, [shouldShowErrorAlert]);

  return {
    shouldShowErrorAlert
  };
};
