import { Button } from '@montugroup/design-system';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, CssBaseline, ThemeProvider, Typography } from '@mui/material';
import { useErrorBoundary } from 'react-error-boundary';

import type { ReactElement } from 'react';
import theme from '../../theme';

export const NotFoundPage = (): ReactElement => {
  const { resetBoundary } = useErrorBoundary();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box role="alert" display="flex" justifyContent="center" alignItems="center" mt={30}>
        <Box textAlign="center">
          <ErrorIcon color="error" sx={{ fontSize: 60 }} />
          <Typography variant="h4" component="h1">
            Cancel request endpoint is not found.
          </Typography>
          <Typography my={2}>There was a problem processing the request. Please try again.</Typography>
          <Button onClick={resetBoundary}>Reload</Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
