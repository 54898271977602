//! DO NOT EDIT THIS FILE IT IS GENERATED USING:
// yarn generate-flags

// Description: Enable a doctor to rescript out of stock products. This is part of GScripting project, but is a seperate functionality
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_DOCTOR_OOS_RESCRIPT = 'enable_doctor_oos_rescript';

// Description: Enables access to Doctors G Scripting Feature
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_DOCTORS_G_SCRIPT = 'enable_doctors_g_script';

// Description: PMS price for initial Nurse consultations
// Default On Variation: [object Object]
// Default Off Variation: [object Object]
export const FF_CONSULTATION_PRICE_JSON = 'consultation_price_json';

// Description: PMS price for follow up Doctor consultations
// Default On Variation: 59
// Default Off Variation: 59.99
export const FF_CONSULTATION_FOLLOW_UP_PRICE = 'consultation_follow_up_price';

// Description: Concession discount rate for Nurse/Doctor consultations
// Default On Variation: 10
// Default Off Variation: 0
export const FF_CONCESSION_DISCOUNT_RATE = 'concession_discount_rate';

// Description: This handles the discount code input on the payment page.
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_DISCOUNT_CODES = 'enable_discount_codes';

// Description: A feature flag to set the number of products shown in the product selection for doctors when adding medication
// Default On Variation: 5
// Default Off Variation: 3
export const FF_PRESCRIPTION_RECOMMENDED_PRODUCTS_COUNT = 'prescription_recommended_products_count';

// Description: It allows you to display or hide the promo code discount line in the payment step. (Appointment Summary)
// Default On Variation: true
// Default Off Variation: false
export const FF_DISPLAY_PROMO_CODE_DISCOUNT = 'display_promo_code_discount';

// Description: a feature flag to display a short generic “info” when recommendations are shown. ie. “Product recommendations are based on your
// selections and availability criteria”.
// Default On Variation: [object Object]
// Default Off Variation: [object Object]
export const FF_PRESCRIPTION_RECOMMENDED_INFO = 'prescription_recommended_info';

// Default On Variation: [object Object]
// Default Off Variation: [object Object]
export const FF_INVENTORY_INSIGHTS_ENFORCE_GREENA_REPEATS = 'inventory_insights_enforce_greena_repeats';

// Default On Variation: true
// Default Off Variation: false
export const FF_INVENTORY_INSIGHTS_FOR_CLINICAL_TUTORIAL = 'inventory_insights_for_clinical_tutorial';

// Default On Variation: true
// Default Off Variation: false
export const FF_PRESCRIPTION_ASSISTANT_PHASE_2 = 'prescription_assistant_phase_2';

// Description: https://montugroup.atlassian.net/browse/B2CS-98 https://montugroup.atlassian.net/browse/B2CS-238
// Default On Variation: true
// Default Off Variation: false
export const FF_USE_SHOPIFY_PRODUCT_IMAGES = 'use_shopify_product_images';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_SHOPIFY_PAYMENTS_ON_INITIAL_CONSULTATION = 'enable_shopify_payments_on_initial_consultation';

// Description: Window for time period in which the user has to pay the consultation booking, in SECONDS before it times out.
// Default On Variation: 600
// Default Off Variation: 300
export const FF_CONSULTATION_BOOKING_PAYMENT_WINDOW = 'consultation_booking_payment_window';

// Description: When enabled, the state for Prescription assistant tool and the prescription flow uses Zustand for state over react context
// Default On Variation: true
// Default Off Variation: false
export const FF_PRESCRIPTION_ASSISTANT_TOOL_USE_ZUSTAND = 'prescription_assistant_tool_use_zustand';

// Description: Turning this flag on will show the prescription assistant "View Script" feature
// Default On Variation: true
// Default Off Variation: false
export const FF_PRESCRIPTION_ASSISTANT_VIEW_SCRIPTS = 'prescription_assistant_view_scripts';

// Description: Turning this flag on will enable the prescribed medication cancellation workflow
// Default On Variation: true
// Default Off Variation: false
export const FF_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION =
  'prescription_assistant_cancel_prescribed_medication';

// Description: Display an alternative cancellation modal when a consultation is in 'Pending' status.
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_ALTERNATE_PENDING_CANCELLATION = 'enable_alternate_pending_cancellation';

// Description: Used to roll out v4 of the prescription assistant prescribed medication
// Default On Variation: false
// Default Off Variation: false
export const FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION =
  'v4_prescription_assistant_cancel_prescribed_medication';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_GOOGLE_PLACES_AUTOCOMPLETE = 'enable_google_places_autocomplete';

// Description: Enables Snowplow Tracking
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_SNOWPLOW = 'enable_snowplow';

// Description: Send Notification of Treatment form to WA DoH for WA patient
// Default On Variation: true
// Default Off Variation: false
export const FF_WA_NOTIFICATION_OF_TREATMENT = 'wa_notification_of_treatment';

// Description: Feature for Immutable Notes. Removing edibility for patient Notes and adding better audit tracking to consultation notes.
// Default On Variation: true
// Default Off Variation: false
export const FF_IMMUTABLE_CLINIC_NOTES = 'immutable_clinic_notes';

// Description: When enable doctors are redirected to the Out Of Stock script tab and cannot see or access the Discontinued scripts tab, while other
// roles such who have access can still see the Discontinued scripts tab with a warning message indicating its restriction for Doctors. When disabled,
// all roles, including doctors, can access the Discontinued scripts tab as usual without any warning messages.
// Default On Variation: true
// Default Off Variation: false
export const FF_PATV5_SCRIPT_REVIEW_PAGE_DISABLE_DISCONTINUED_TAB = 'patv5_script_review_page_disable_discontinued_tab';

// Default On Variation: true
// Default Off Variation: false
export const FF_IHI_UNVERIFIED_IHI_MODAL_QUESTIONS = 'ihi_unverified_ihi_modal_questions';

// Description: toggle visibility of Consultation Notes History within a single consultation
// Default On Variation: true
// Default Off Variation: false
export const FF_VIEW_CONSULTATION_NOTES_HISTORY = 'view_consultation_notes_history';

// Description: This will enable the refactored Patient Details V3 in PMS FE. https://montugroup.atlassian.net/browse/B2C-4860
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PMS_PATIENT_DETAILS_V3 = 'enable_pms_patient_details_v3';

// Description: This feature toggle enables the "Suitable Device" Modal prompt that displays when a script that includes a 'Herb, Dried' or
// 'Inhalation' formulation is created/updated. The modal prompts the doctor to ask the patient if they have a suitable vaping device for that
// medication.
// Default On Variation: true
// Default Off Variation: false
export const FF_PAT_PRESCRIBING_SUITABLE_DEVICE_MODAL = 'pat_prescribing_suitable_device_modal';

// Description: https://montugroup.atlassian.net/browse/B2CS-1217
// Default On Variation: true
// Default Off Variation: false
export const FF_CONSULTATION_CHARGES_UPLIFT = 'consultation_charges_uplift';

// Description: Used to enable / disable a prompt that shows up at the end of consult or prescription creation
// Default On Variation: true
// Default Off Variation: false
export const FF_CONSULTATION_COMPLETION_PROMPT = 'consultation-completion-prompt';

// Description: Allow booking of any doctor if current doctor is not active
// Default On Variation: true
// Default Off Variation: false
export const FF_BOOK_ANY_DOCTOR_IF_CURRENT_DOCTOR_INACTIVE = 'book_any_doctor_if_current_doctor_inactive';

// Description: Introduce blocking/non-blocking error handling framework as per RFC-019
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PMS_ERROR_HANDLING = 'enable_pms_error_handling';

// Description: Warning for when dosage entered is under the character limit.
// Default On Variation: true
// Default Off Variation: false
export const FF_PAT_PRESCRIPTION_DOSAGE_MIN_LENGTH_VALIDATION = 'pat_prescription_dosage_min_length_validation';

// Description: (Connect for Clinic) AWS Connect voice call that replaces the existing Aircall
// Default On Variation: true
// Default Off Variation: false
export const FF_C4C_VOICE_CALL = 'c4c_voice_call';

// Description: https://montugroup.atlassian.net/browse/B2C-5381
// Default On Variation: true
// Default Off Variation: false
export const FF_HIDE_RESIDENCE_FIELD_IN_ADDRESS_FORM = 'hide_residence_field_in_address_form';

// Description: Toggle button to allow updating the schedules of doctors in PMS
// Default On Variation: true
// Default Off Variation: false
export const FF_CALCOM_DOCTOR_SCHEDULE_API = 'calcom_doctor_schedule_api';

// Description: Enable devices to be prescribed via the Prescription Assistant Tool (PAT). When ON, the "Add Device" button is visible to the user.
// When OFF, the button is hidden to prevent devices being added to prescriptions.
// Default On Variation: true
// Default Off Variation: false
export const FF_PAT_ENABLE_DEVICE_PRESCRIPTION = 'pat_enable_device_prescription';

// Description: It disables the logout menu from popping up when hovering over your name in the sidebar. Helps prevent accidental logouts.
// Default On Variation: true
// Default Off Variation: false
export const FF_DISABLE_SIDEBAR_LOGOUT_HOVER = 'disable_sidebar_logout_hover';
