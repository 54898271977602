import type { DateTime } from 'luxon';
import settings from '../../data/constants';
import useRequest from './useRequest';

type ObjectValues<T> = T[keyof T];
type PatientDocumentUploadType = ObjectValues<typeof settings.patientDocumentUploadType>;

type UsePostPatientDocumentUploadPayload = {
  file: File;
  fileName?: string;
  expiryDate: DateTime | string;
  patientId: string | number;
  documentTypeId: PatientDocumentUploadType;
  approvalDate?: DateTime | string;
  ingredientId?: string | number;
  formulationId?: string | number;
  mbNumber?: string | number;
};

type UsePostPatientDocumentUploadResponse = {
  id: string | number;
};

/**
 * Transforms the patient document upload payload into a `FormData` object
 * for submission in an HTTP request (e.g., for file upload).
 *
 * @param root0 - The object containing document-related data.
 * @param root0.file - The file associated with the document.
 * @param root0.fileName - The name of the file. Optional, if not provided, `file.name` will be used.
 * @param root0.expiryDate - The expiration date of the document. Can be a `DateTime` object or a string.
 * @param root0.patientId - The ID of the patient associated with the document. Can be a string or number.
 * @param root0.documentTypeId - The ID of the document type. This is required and comes from the `patientDocumentUploadType` setting.
 * @param root0.approvalDate - The approval date for the document. Optional, will be included if provided.
 * @param root0.ingredientId - The ID of the ingredient related to the document. Optional, used only for specific document types.
 * @param root0.formulationId - The ID of the formulation related to the document. Optional.
 * @param root0.mbNumber - The Medical Board Number. Optional, used in specific document types (e.g., SAS).
 *
 * @returns - A `FormData` object that can be used in a `POST` request.
 */
function transformPayloadToFormData({
  file,
  fileName,
  expiryDate,
  patientId,
  documentTypeId,
  approvalDate,
  ingredientId,
  formulationId,
  mbNumber
}: UsePostPatientDocumentUploadPayload): FormData {
  const postBody = new FormData();
  postBody.append('file', file);
  if (fileName) {
    postBody.append('file_name', fileName);
  } else {
    postBody.append('file_name', file.name);
  }
  postBody.append('expiry_date', String(expiryDate));
  postBody.append('patient_id', String(patientId));
  postBody.append('document_type_id', String(documentTypeId));
  if (approvalDate) {
    postBody.append('approval_date', String(approvalDate));
  }
  if (ingredientId) {
    postBody.append('ingredient_id', String(ingredientId));
  }
  if (formulationId) {
    postBody.append('formulation_id', String(formulationId));
  }
  if (mbNumber) {
    postBody.append('mb_number', String(mbNumber));
  }
  return postBody;
}

/**
 * Custom hook for uploading a patient's document.
 *
 * This hook sends a POST request with the document data, and returns the
 * status of the request (loading, data, error) as well as a function (`doPost`)
 * to trigger the upload process.
 *
 * @returns - An object containing:
 *   - loading: A boolean indicating if the request is in progress.
 *   - data: The response data from the API after document upload.
 *   - error: Any error that occurred during the request.
 *   - doPost: A function that accepts the payload and triggers the upload.
 */
export function usePostPatientDocumentUpload() {
  const { loading, data, error, execute } = useRequest<UsePostPatientDocumentUploadResponse>({
    config: {
      url: `${settings.url}/document`,
      method: 'POST'
    }
  });

  /**
   * Function to initiate the document upload process.
   * It transforms the payload into FormData and then sends the request.
   *
   * @param body - The payload containing document data.
   * @returns - The response from the API after the document upload.
   */
  const doPost = async (body: UsePostPatientDocumentUploadPayload) => {
    const postBody = transformPayloadToFormData(body);
    return execute({ data: postBody });
  };

  return {
    loading,
    data,
    error,
    doPost
  };
}

export default usePostPatientDocumentUpload;
