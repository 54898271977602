import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import styles from './styles';

const useStyles = makeStyles(styles);
/**
 * @deprecated due to the non-scalable composition of the component
 */
const DateField = (props) => {
  const classes = useStyles();
  return (
    <Box
      component={Grid}
      width="100%"
      display={props.display ? props.display : 'flex'}
      m={props.margin !== undefined ? props.margin : 1}
      p={isNaN(props.padding) ? 0 : props.padding}
    >
      <Grid xs={props.customGrid ? props.customGrid[0] : 4} item={true}>
        <Box
          component="div"
          textAlign={props.textAlign ? props.textAlign : 'left'}
          p={props.labelPadding ? props.labelPadding : '12px'}
          className={`${props.disabled && classes.disabledLabel} ${props.labelClasses ? props.labelClasses : ''}`}
          fontWeight={600}
        >
          <label htmlFor={props.id}>{props.label}</label>
        </Box>
      </Grid>
      <Grid xs={props.customGrid ? props.customGrid[1] : 8} item={true}>
        <DatePicker
          id={props.id}
          inputVariant={props.variant ? props.variant : 'outlined'}
          value={props.value ? DateTime.fromISO(props.value) : null}
          onChange={(e) => props.onChange(e)}
          className={`${classes.TextField} ${props.InputClasses ? props.InputClasses : ''}`}
          disabled={props.disabled}
          format="dd-MMM-yyyy"
          emptyLabel=""
          clearable
          minDate={props.selectedApprovalDate}
        />
        {!!props.error && props.touched && <div className={classes.errorMessage}>{props.helperText}</div>}
      </Grid>
    </Box>
  );
};

export default DateField;
