import type { SxProps } from '@mui/material';
import { Box, Typography } from '@mui/material';
import type { ReactNode } from 'react';

export type SectionHeaderProps = {
  sx?: SxProps;
  headerText?: ReactNode;
  headingVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  actionButton?: ReactNode;
};

/** @deprecated needs to be refactored */
export const SectionHeader = ({ sx, headerText, actionButton, headingVariant = 'h2' }: SectionHeaderProps) => {
  return (
    <Box
      component="header"
      display="flex"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      sx={{ borderBottom: `1px solid`, borderColor: 'primary.light', ...sx }}
    >
      <Typography
        variant={headingVariant}
        sx={{
          color: 'primary.light', // this is the #1E88E5 blue that's currently being used for these headings
          fontSize: '16px',
          fontWeight: 600,
          pb: 1,
          flex: 1
        }}
      >
        {headerText}
      </Typography>
      {actionButton}
    </Box>
  );
};
