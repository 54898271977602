import { DateTime } from 'luxon';
import type { ConcessionCardType } from './types';
import { CONCESSION_CARD_TYPE } from './types';

export function transformLuxonToYearMonthDay(candidate: DateTime) {
  const year = candidate.year;
  const month = candidate.month;
  const day = candidate.day;
  return { year, month, day };
}

export function parseLuxonYearMonthDay(year: number, month: number, day: number) {
  return DateTime.fromObject({ year, month, day });
}

export function formatExpirationForCardType(expiry: DateTime, cardType: ConcessionCardType): string {
  if (cardType === CONCESSION_CARD_TYPE.VETERAN) {
    return expiry.toFormat('yyyy-MM');
  }
  return expiry.toFormat('yyyy-MM-dd');
}

export function formatExpirationForAnalytics(expiry: DateTime, cardType: ConcessionCardType): string | null {
  if (cardType === CONCESSION_CARD_TYPE.VETERAN) {
    return expiry.endOf('month').toISODate();
  }
  return expiry.toISODate();
}
