import { NoticeList } from '@/components/Rescripting/Rescripting.styled';
import { Box, Typography } from '@mui/material';

const OutOfStockContent = () => {
  return (
    <Box pt={4} pb={8} paddingX={2}>
      <Typography variant="body1">
        Please review the following prescriptions, which contain an out of stock medicine. In most cases, a substitution
        suggestion has been provided, based on comparable pharmacological features.
        <br />
        You are able to change the suggested replacement. Approving the OOS prescription will trigger the following
        actions:
        <br />
        <br /> A new script will be created, which will have the following features
      </Typography>
      <Typography component="div">
        <NoticeList>
          <li>Quantity 2</li>
          <li>Zero Repeats</li>
          <li>30 day expiry</li>
        </NoticeList>
        This will not effect the original script in any way.
      </Typography>
    </Box>
  );
};

export { OutOfStockContent };
