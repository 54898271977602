import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { GoogleLogin } from '@react-oauth/google';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { toast } from '@montugroup/design-system';
import FormInput from '../components/common/FormInput';
import settings from '../data/constants';
import { isAuthenticated, login, logout } from '../data/service/authService';

const LoginPage = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [errMessage, setErrMessage] = React.useState('');
  const navigate = useNavigate();
  const isAuthorized = isAuthenticated();

  React.useEffect(() => {
    if (isAuthorized) {
      navigate('/calendar');
    }
  }, [navigate, isAuthorized]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const goToRegister = (event) => {
    event.preventDefault();
    navigate('/register');
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const payload = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password })
    };
    try {
      const resp = await fetch(`${settings.url}/pub/login`, payload);
      const respData = await resp.json();
      if (resp.ok) {
        login(respData.data);
        if (respData.data.user.role_id === 4) {
          window.location.href = '/nurse-calendar';
        } else {
          window.location.href = '/calendar';
        }
      } else {
        const error = (respData && respData.message) || resp.statusText;
        if ([401, 403].indexOf(resp.status) !== -1) {
          toast.error(respData.message);
        }
        setErrMessage(error);
        // eslint-disable-next-line @typescript-eslint/return-await
        return Promise.reject(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const responseSuccessGoogle = async (res) => {
    const payload = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(res)
    };
    const resp = await fetch(`${settings.url}/pub/login`, payload);
    const respData = await resp.json();
    if (resp.ok) {
      login(respData);
      if (respData.user.role_id === 4) {
        window.location.href = '/nurse-calendar';
      } else {
        window.location.href = '/calendar';
      }
    } else {
      logout();
    }
  };

  return (
    <Grid className="login-container" container alignItems="center" direction="column" justifyContent="center">
      <Paper elevation={2} style={{ padding: '2rem', width: '400px' }}>
        <Typography align="center" variant="h4">
          PMS Login
        </Typography>
        {true ? (
          <Grid className="admin-login-button" alignItems="center" direction="column" justifyContent="center">
            <div style={{ width: '220px', margin: '0 auto' }}>
              <GoogleLogin theme="filled_blue" onSuccess={responseSuccessGoogle} onError={console.error} />
            </div>
          </Grid>
        ) : (
          <form className="form" onSubmit={handleLogin}>
            <FormInput
              labelText="Email"
              id="email"
              error={setErrMessage !== ''}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: email
              }}
              handleChange={(e) => setEmail(e.target.value)}
              type="email"
            />
            <FormInput
              labelText="Password"
              id="password"
              formControlProps={{
                fullWidth: true
              }}
              error={setErrMessage !== ''}
              inputProps={{
                value: password
              }}
              handleChange={(e) => setPassword(e.target.value)}
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Typography color="error" align="center" variant="body1">
              {errMessage !== '' && errMessage}
            </Typography>
            <Grid container justifyContent="space-between">
              <Button type="submit" variant="contained" color="secondary">
                Log in
              </Button>
              <Button type="button" onClick={goToRegister} variant="contained" color="secondary">
                Register
              </Button>
            </Grid>
          </form>
        )}
      </Paper>
    </Grid>
  );
};

export default LoginPage;
