import { documentContract } from '@montugroup/pms-api-contracts';
import { tsRestFetchApi } from '@ts-rest/core';
import { initQueryClient } from '@ts-rest/react-query';

import { normalizeHeaders, requestHeaders } from '@/api';
import settings from '@/data/constants';

const downloadDocumentClient = initQueryClient(documentContract, {
  baseUrl: settings.baseXApiUrl,
  baseHeaders: {},
  api: (args) => {
    args.headers = normalizeHeaders({ ...requestHeaders(), ...args.headers });
    return tsRestFetchApi(args);
  }
});

export default downloadDocumentClient;
