import { ListItemStyled } from './MedicationsOnScriptCell.styled';

type MedicationsOnScriptProps = {
  medications: {
    name: string;
    toFlag: boolean;
  }[];
  rowId: string;
};

const MedicationsOnScriptCell = ({ medications, rowId }: MedicationsOnScriptProps) => {
  return (
    <>
      {medications.map((medication) => (
        <ListItemStyled key={`${rowId}-${medication.name}`} medication={medication.toFlag}>
          • {medication.name}
        </ListItemStyled>
      ))}
    </>
  );
};

export { MedicationsOnScriptCell };
