import { Chip, styled } from '@mui/material';

const RequestCountStyled = styled(Chip)`
  padding: 0.2rem;
  border-radius: 1rem;
  font-weight: bold;
  background-color: #b3261e;
  color: #fff;
`;

export { RequestCountStyled };
