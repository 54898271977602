import { Box, Button, CircularProgress, Stack, styled, Typography } from '@mui/material';

import HorizontalTabs from '../../common/HorizontalTabs';
import AvailabilityScheduler from '../AvailabilityScheduler/AvailabilityScheduler';
import DateOverride from '../DateOverride/DateOverride';
import { Form } from '../shared/components/Form';
import type { CalcomUser } from '../shared/types/calcom-schedule-types';

import { useUpdateSchedule } from './hooks/use-update-schedule';

/**
 * Update Scheduler Form component
 *
 * @remarks
 * A form with two tabs, with 'Availability Scheduler' and 'Date Overrides' form fields
 * The UpdateScheduleForm lets a user update Calcom availabilities
 *
 * @param user.userType - The type of Calcom account being updated (i.e. 'nurse', 'doctor' etc)
 * @param user.name - The Calcom users name
 * @param user.calcom_id - The Calcom users calcom ID
 * @param handleUpdateSuccess - A callback for when the update is successful
 *
 * @returns JSXElement
 *
 */

type UpdateScheduleFormProps = {
  user: CalcomUser;
  handleUpdateSuccess: () => void;
};

const StyledHeading = styled(Typography)({
  textAlign: 'center',
  marginBottom: 5,
  fontSize: '1.4rem',
  fontWeight: '500'
});

const StyledMetaDetails = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  fontSize: '1rem',
  textAlign: 'center',
  marginBottom: 15
}));

const StyledFormContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column'
});

const StyledSaveButton = styled(Button)({
  padding: 10,
  width: '90%'
});

const StyledSaveButtonFixedContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  right: 0,
  width: '580px',
  background: theme.palette.common.white,
  display: 'flex',
  justifyContent: 'center',
  zIndex: 1,
  bottom: 0,
  padding: '30px 0',
  boxShadow: '0px 0px 25px 0px rgba(0,0,0,0.2)'
}));

const StyledCircularProgress = styled(CircularProgress)({
  margin: 0,
  position: 'absolute',
  top: 'calc(50% - 60px)',
  left: '50%',
  transform: 'translateY(-50%)'
});

const UpdateScheduleForm = (props: UpdateScheduleFormProps) => {
  const { user, handleUpdateSuccess } = props;

  const { form, scheduleResponse, selectedSchedule, handleScheduleSubmit, handleScheduleSelect, isLoading } =
    useUpdateSchedule({
      user,
      handleUpdateSuccess
    });

  const availabilityTab = {
    label: 'Availability',
    content: (
      <Box mb={30}>
        <AvailabilityScheduler scheduleResponse={selectedSchedule} />
      </Box>
    )
  };

  const dateOverridesTab = {
    label: 'Date overrides',
    content: (
      <DateOverride
      // TODO: workingHours may be needed for Phase 2. Keeping this here for now until confirmed it can be removed
      // workingHours={[
      //   {
      //     days: [1],
      //     startTime: 540,
      //     endTime: 1020
      //   }
      // ]}
      />
    )
  };
  const isMultipleSchedule = scheduleResponse?.length ? scheduleResponse.length > 1 : false;
  if (!isLoading && isMultipleSchedule && !selectedSchedule) {
    return (
      <>
        <StyledHeading variant="h4">Select schedule to update</StyledHeading>
        <Stack mt={10} spacing={4}>
          {scheduleResponse?.map((schedule) => (
            <Button key={schedule.scheduleId} variant="outlined" onClick={() => handleScheduleSelect(schedule)}>
              {schedule.name}
            </Button>
          ))}
        </Stack>
      </>
    );
  }

  return selectedSchedule ? (
    <>
      <StyledHeading variant="h4">Updating schedule for &apos;{user.name}&apos;</StyledHeading>
      <StyledHeading variant="h4">{selectedSchedule.name}</StyledHeading>
      <StyledMetaDetails className="meta-details">
        Calcom ID: {user.calcom_id} | Schedule ID: {selectedSchedule?.scheduleId}
      </StyledMetaDetails>
      <Form
        form={form}
        id="update-schedule-form"
        handleSubmit={handleScheduleSubmit}
        data-testid="update-schedule-form"
      >
        <StyledFormContainer>
          <StyledSaveButtonFixedContainer>
            <StyledSaveButton variant="contained" type="submit" disabled={isLoading || !form.formState.isDirty}>
              {isLoading ? 'Saving...' : 'Save'}
            </StyledSaveButton>
          </StyledSaveButtonFixedContainer>

          <HorizontalTabs tabs={[availabilityTab, dateOverridesTab]}></HorizontalTabs>
        </StyledFormContainer>
      </Form>
    </>
  ) : (
    <StyledCircularProgress />
  );
};

export default UpdateScheduleForm;
