import { SearchTextField, Table, toast } from '@montugroup/design-system';
import { Box, styled } from '@mui/material';
import { DateTime } from 'luxon';
import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';

import CreateCalcomAccountButton from '@/components/calcom/CreateCalcomAccountButton';
import { useTableUtils } from '@/components/tables/hooks/useTableUtils';
import { FF_CALCOM_DOCTOR_SCHEDULE_API } from '@/constants/featureFlags';
import { getAuthData } from '@/data/service/authService';
import { useFeatureFlags } from '@/hooks';
import { useGetDoctors } from '@/hooks/rest/useGetDoctors';
import type { Doctor, DoctorTableFormattedRow } from '@/types';
import { DoctorTableColumnHeaders } from '@/types';
import { buildDoctorBookingLink } from '@/utils/calendar';
import UpdateScheduleDrawer from '../calcom/UpdateScheduleDrawer/UpdateScheduleDrawer';
import type { CalcomUser } from '../calcom/shared/types/calcom-schedule-types';
import DeactivateDoctorPopupButton from './components/DeactivateDoctorPopupButton';

export type DoctorsTableProps = {
  handleSetExportData: (formattedTableRows: DoctorTableFormattedRow[]) => void;
};

const StyledBox = styled(Box)({
  marginTop: 10,
  width: '100%'
});

const StyledTable = styled(Table)({
  '.MuiTableCell-root': {
    fontSize: '1rem'
  }
});

/**
 * DoctorsTable component
 *
 * @remarks
 * Renders a table of Doctors for the Doctors page
 *
 * @returns JSXElement
 *
 */

export const DoctorsTable = ({ handleSetExportData }: DoctorsTableProps) => {
  const { flags } = useFeatureFlags();
  const isCalcomScheduleApiEnabled = flags[FF_CALCOM_DOCTOR_SCHEDULE_API];

  const { isSuperAdmin, isManager, isAdminOnly } = getAuthData();

  const [searchFilterText, setSearchFilterText] = useState('');
  const {
    data: doctors,
    isLoading: isLoadingDoctors,
    isError: isErrorDoctors,
    refetch: refetchDoctors
  } = useGetDoctors();

  const { createColumnDefinition } = useTableUtils();

  const resultsNotFoundMessage = searchFilterText.length
    ? 'No matching doctors found for that search'
    : 'No doctors found';

  if (isErrorDoctors) {
    toast.error('There has been an error loading the Doctors');
  }

  const handleSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilterText(event.target.value);
  };

  const handleClearSearchFilter = () => {
    setSearchFilterText('');
  };

  const formatDeactivateLink = (doctor: Doctor): string | ReactNode => {
    if (!doctor.is_active) {
      return 'Inactive';
    }

    return (
      <DeactivateDoctorPopupButton
        doctorId={doctor.doctor_id}
        doctorUserId={doctor.doctor_user_id}
        doctorCode={doctor.id}
      />
    );
  };

  const formatInitialConsult = (doctor: Doctor) => {
    if (!doctor.is_active) {
      return null;
    }

    if (!doctor.calcom_doctor_id || !doctor.calcom_initial_consult_slug) {
      return (
        <CreateCalcomAccountButton
          practitioner={{
            id: doctor.doctor_user_id,
            name: doctor.doctor_name
          }}
          refetchTableData={refetchDoctors}
        />
      );
    }

    return buildDoctorBookingLink(doctor, doctor.calcom_initial_consult_slug);
  };

  const formatFollowUpConsult = (doctor: Doctor) => {
    if (!doctor.is_active) {
      return null;
    }

    return doctor.calcom_follow_up_consult_slug
      ? buildDoctorBookingLink(doctor, doctor.calcom_follow_up_consult_slug)
      : '';
  };

  const formatJoinedDate = (doctor: Doctor) => {
    return DateTime.fromISO(doctor.joined_date).toFormat('dd-MMM-yyyy');
  };

  const formatCalcomSchedule = (doctor: Doctor) => {
    if (!doctor.calcom_doctor_id) {
      return null;
    }

    const formattedUser: CalcomUser = {
      userType: 'doctor',
      name: `${doctor.doctor_name}`,
      calcom_id: doctor.calcom_doctor_id
    };

    return <UpdateScheduleDrawer user={formattedUser} />;
  };

  const formattedDoctors = doctors?.map((doctor: Doctor) => {
    return {
      doctor_id: doctor.id,
      doctor_name: doctor.doctor_name,
      doctor_email: doctor.doctor_email,
      doctor_phone: doctor.doctor_phone,
      joined_date: formatJoinedDate(doctor),
      aphra_number: doctor.ahpra_no,
      prescriber_number: doctor.prescriber_number,
      hpii_number: doctor.hpii_number,
      primary_clinic: doctor.primary_clinic?.name,
      patient_count: doctor.patientCount,
      authorised_prescriber: doctor.can_eprescribe ? 'Yes' : 'No',
      state: doctor.state,
      deactivate: formatDeactivateLink(doctor),
      initial_consultation: formatInitialConsult(doctor),
      follow_up_consultation: formatFollowUpConsult(doctor),
      calcom_id: doctor.calcom_doctor_id ?? '-',
      calcom_schedule: formatCalcomSchedule(doctor)
    };
  });

  useEffect(() => {
    handleSetExportData(formattedDoctors || []);
  }, [doctors]);

  const columns = [
    createColumnDefinition('doctor_id', DoctorTableColumnHeaders.DoctorId),
    createColumnDefinition('doctor_name', DoctorTableColumnHeaders.Name),
    createColumnDefinition('doctor_email', DoctorTableColumnHeaders.Email),
    createColumnDefinition('doctor_phone', DoctorTableColumnHeaders.Phone),
    createColumnDefinition('joined_date', DoctorTableColumnHeaders.JoinedDate),
    createColumnDefinition('aphra_number', DoctorTableColumnHeaders.AhpraNumber),
    createColumnDefinition('prescriber_number', DoctorTableColumnHeaders.PrescriberNumber),
    createColumnDefinition('hpii_number', DoctorTableColumnHeaders.HpiiNumber),
    createColumnDefinition('primary_clinic', DoctorTableColumnHeaders.PrimaryClinic),
    createColumnDefinition('patient_count', DoctorTableColumnHeaders.PatientCount),
    createColumnDefinition('authorised_prescriber', DoctorTableColumnHeaders.AuthorisedPrescriber),
    createColumnDefinition('state', DoctorTableColumnHeaders.State),
    createColumnDefinition('deactivate', DoctorTableColumnHeaders.Deactivate),
    createColumnDefinition('initial_consultation', DoctorTableColumnHeaders.InitialConsultation),
    createColumnDefinition('follow_up_consultation', DoctorTableColumnHeaders.FollowUpConsultation),
    createColumnDefinition('calcom_id', DoctorTableColumnHeaders.CalcomId),
    isCalcomScheduleApiEnabled
      ? createColumnDefinition('calcom_schedule', DoctorTableColumnHeaders.CalcomSchedule)
      : undefined
  ].filter(Boolean);

  return (
    <StyledBox>
      <SearchTextField
        handleChange={handleSearchOnChange}
        handleClear={handleClearSearchFilter}
        labelText="Search"
        enableClear={true}
      />
      <StyledTable
        data={formattedDoctors || []}
        columns={columns}
        isLoading={isLoadingDoctors}
        pageSize={20}
        globalFilter={searchFilterText.trim()}
        noDataFoundMessage={resultsNotFoundMessage}
        columnVisibility={{
          doctor_email: !isAdminOnly,
          doctor_phone: !isAdminOnly,
          joined_date: !isAdminOnly,
          aphra_number: !isAdminOnly,
          hpii_number: !isAdminOnly,
          primary_clinic: !isAdminOnly,
          patient_count: !isAdminOnly,
          authorised_prescriber: !isAdminOnly,
          state: !isAdminOnly,
          deactivate: !!(isSuperAdmin || isManager),
          initial_consultation: !!(isSuperAdmin || isManager),
          follow_up_consultation: !!(isSuperAdmin || isManager)
        }}
        showPagination
        pageSizeOptions={[5, 10, 20, 50]}
      />
    </StyledBox>
  );
};
