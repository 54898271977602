export enum StatusCode {
  MultiStatus = 207,
  NotModified = 304,
  BadRequest = 400,
  Unauthorised = 401,
  Forbidden = 403,
  NotFound = 404,
  InternalServerError = 500
}

export interface NetworkError extends Error {
  status: number;
  response?: Response;
}
