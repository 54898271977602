import type { ReactNode } from 'react';

import type { UserAttribute } from '@/types/patient.types';

import type { ISODateString } from './common';

export interface DoctorUserWithDoctorId {
  DoctorUser: UserAttribute;
  id: number;
}

export interface DoctorModel extends DoctorUserWithDoctorId {
  user_id: number;

  [key: string]: any;
}

export type Doctor = {
  ahpra_no: string;
  calcom_doctor_id: number;
  calcom_follow_up_consult_slug: string;
  calcom_initial_consult_slug: string;
  can_eprescribe: boolean;
  doctor_email: string;
  doctor_id: number;
  doctor_name: string;
  doctor_phone: string;
  doctor_user_id: number;
  erx_entity_id: string;
  hpii_number: string;
  id: string;
  is_active: boolean;
  joined_date: string;
  patientCount: number;
  phone: string;
  prescriber_number: string;
  prescriptions: number;
  primary_clinic: {
    name: string;
  };
  qualifications: string;
  state: string;
};

export type DoctorTableFormattedRow = {
  doctor_id: string;
  doctor_name: string;
  doctor_email: string;
  doctor_phone: string;
  joined_date: ISODateString;
  aphra_number: string;
  prescriber_number: string;
  hpii_number: string;
  primary_clinic: string;
  patient_count: number;
  authorised_prescriber: string;
  state: string;
  deactivate: ReactNode;
  initial_consultation: ReactNode | undefined;
  follow_up_consultation: ReactNode | undefined;
};

export enum DoctorTableColumnHeaders {
  DoctorId = 'Doctor ID',
  Name = 'Name',
  Email = 'Email',
  JoinedDate = 'Joined Date',
  Phone = 'Phone',
  AhpraNumber = 'AHPRA',
  PrescriberNumber = 'Prescriber Number',
  HpiiNumber = 'HPII Number',
  PrimaryClinic = 'Primary Clinic',
  PatientCount = 'Patients',
  AuthorisedPrescriber = 'Authorised Prescriber',
  State = 'State',
  Deactivate = 'Deactivate',
  InitialConsultation = 'Initial Consultation',
  FollowUpConsultation = 'Follow-up Consultation',
  CalcomId = 'Calcom ID',
  CalcomSchedule = 'Calcom Schedule'
}
