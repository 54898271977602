import { css, styled, Tab, Tabs } from '@mui/material';

export const StyledTabs = styled(Tabs)(
  ({ theme }) => css`
    border: 1px solid ${theme.palette.primary.main};
    border-radius: 4px;
    .MuiTabs-flexContainer {
      position: relative;
      z-index: 2;

      .Mui-selected {
        color: ${theme.palette.common.white};
      }
    }
    .MuiTabs-indicator {
      height: 100%;
      border-radius: 2px;
    }
  `
);

export const StyledTab = styled(Tab)(
  ({ theme }) => css`
    color: ${theme.palette.primary.main};
    text-transform: none;
  `
);
