import type { DesktopDatePickerProps } from '@mui/x-date-pickers/DesktopDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DateTime } from 'luxon';

import type { ConcessionCardType } from '..';
import { CONCESSION_CARD_TYPE } from '..';

export type ConcessionCardExpirationDatePickerProps<T> = Pick<
  DesktopDatePickerProps<T>,
  'onChange' | 'value' | 'onError' | 'disabled' | 'slotProps'
> & {
  cardType: ConcessionCardType | '';
};

export const ConcessionCardExpirationDatePicker = ({
  cardType,
  ...otherProps
}: ConcessionCardExpirationDatePickerProps<Date | DateTime>) => {
  const commonDatePickerProps = {
    inputVariant: 'outlined',
    variant: 'inline',
    minDate: otherProps.value ? DateTime.now() : undefined,
    label: 'Expiration date',
    InputLabelProps: {
      shrink: true
    },
    autoOk: true
  };

  if (cardType === CONCESSION_CARD_TYPE.VETERAN) {
    return <DesktopDatePicker format={'MM/yy'} views={['month']} {...commonDatePickerProps} {...otherProps} />;
  }

  return <DesktopDatePicker format={'dd/MM/yy'} views={['day']} {...commonDatePickerProps} {...otherProps} />;
};

export default ConcessionCardExpirationDatePicker;
