import { patientDetailsClient } from '@/api/patients/patientDetailsClient';
import { QueryKey } from '@/data/query';
import type { GetPatientDetailsDto } from '@montugroup/pms-api-contracts';

export enum PatientDetailsIncludeEnum {
  BasicInfo = 'patient-basic-info',
  EmergencyContact = 'patient-emergency-contact',
  RedFlagInfo = 'patient-red-flag-info',
  IdentificationInfo = 'patient-identification-info',
  MedicalInfo = 'patient-medical-info',
  CareInfo = 'patient-care-info'
}

export type PatientDetailsData = GetPatientDetailsDto;

export type PatientDetailsInclude = [PatientDetailsIncludeEnum, ...PatientDetailsIncludeEnum[]];

const allPatientDetailsEndpoints: string = [
  PatientDetailsIncludeEnum.BasicInfo,
  PatientDetailsIncludeEnum.EmergencyContact,
  PatientDetailsIncludeEnum.MedicalInfo,
  PatientDetailsIncludeEnum.CareInfo,
  PatientDetailsIncludeEnum.IdentificationInfo,
  PatientDetailsIncludeEnum.RedFlagInfo
].join(',');

const queryClient = patientDetailsClient();

export const useGetPatientDetails = (
  patientId: number | undefined,
  endpoints: string | undefined = allPatientDetailsEndpoints
) => {
  const queryResult = queryClient.getPatientDetails.useQuery(
    [QueryKey.GetPatientDetails, patientId, endpoints],
    {
      // Casting as 'number' to bypass type error. {'enabled: Boolean(patientId)'} will
      // prevent calls when 'patientId' is not defined.
      params: { id: patientId as number },
      query: { includes: endpoints }
    },
    {
      queryKey: [QueryKey.GetPatientDetails, patientId, endpoints],
      enabled: Boolean(patientId)
    }
  );

  return {
    data: queryResult.data?.body?.data ?? null,
    isError: queryResult.isError,
    isLoading: queryResult.isLoading
  };
};
