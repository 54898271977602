import type { QueryClientConfig } from '@tanstack/react-query';
import { MutationCache, QueryCache } from '@tanstack/react-query';

export const generateQueryConfig = (
  enableErrorHandling = false,
  handleErrorPage: (error: unknown) => boolean,
  handleErrorAlert: (error: unknown) => void
): QueryClientConfig => {
  const defaultQueriesOptions = {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 1
  };

  if (!enableErrorHandling) {
    return {
      defaultOptions: {
        queries: defaultQueriesOptions
      }
    };
  }

  return {
    defaultOptions: {
      queries: {
        ...defaultQueriesOptions,
        // enable error page boundary
        throwOnError: handleErrorPage,
        // https://tanstack.com/query/latest/docs/framework/react/guides/network-mode#network-mode-always
        networkMode: 'always'
      },
      mutations: {
        // enable error page boundary
        throwOnError: handleErrorPage,
        networkMode: 'always'
      }
    },
    queryCache: new QueryCache({
      // enable global alert
      onError: handleErrorAlert
    }),
    mutationCache: new MutationCache({
      // enable global alert
      onError: handleErrorAlert
    })
  };
};
