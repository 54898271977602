import { Button, toast } from '@montugroup/design-system';
import { Alert, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

import BasicModal from '@/components/common/BasicModal';
import usePostCalcomOnboardPractitioner from '@/hooks/rest/usePostCalcomOnboardPractitioner';

const StyledBasicModal = styled(BasicModal)({
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '45%',
  height: 'auto'
});

const StyledLink = styled(Link)({
  cursor: 'pointer',
  textDecoration: 'none'
});

type CalcomErrors = {
  calcomAccountSuccess: boolean;
  pmsUpdateSuccess: boolean;
  associationSuccess: boolean;
  scheduleSuccess: boolean;
  initialEventSuccess?: boolean;
  followupEventSuccess?: boolean;
};

type CreateCalcomPracitioner = {
  id: number;
  name: string;
};

type CreateCalcomAccountButtonProps = {
  isNurse?: boolean;
  practitioner: CreateCalcomPracitioner;
  refetchTableData: () => void;
};

const CreateCalcomAccountButton = ({
  isNurse = false,
  practitioner,
  refetchTableData
}: CreateCalcomAccountButtonProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [calcomErrors, setCalcomErrors] = useState<CalcomErrors | null>(null);
  const { doPost: onboardToCalcom, loading: submitting } = usePostCalcomOnboardPractitioner();

  const handleClose = () => {
    if (submitting) {
      return;
    }
    setCalcomErrors(null);
    setModalOpen(false);
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleCalcomOnboarding = async () => {
    if (submitting || !practitioner.id) {
      return;
    }
    try {
      const response = await onboardToCalcom({
        practitionerId: practitioner.id,
        isNurse
      });
      if (response.error) {
        toast.error('Something went wrong: Calcom setup');
        return;
      }

      if (response.data && Object.values(response.data).includes(false)) {
        setCalcomErrors(response.data);
        toast.error('Something went wrong: Calcom setup');
        return;
      }

      toast.success('Calcom Account created successfully');
      handleClose();
    } catch {
      toast.error({
        title: 'Something went wrong: Calcom setup',
        position: 'topRight'
      });
    } finally {
      refetchTableData();
    }
  };

  if (!practitioner) {
    return null;
  }

  return (
    <>
      <StyledLink
        data-testid={`${practitioner.id}_${isNurse ? 'nurse' : 'doctor'}_calcomId_account_create_trigger`}
        onClick={handleOpen}
      >
        Create Cal.Com Account
      </StyledLink>
      <StyledBasicModal open={modalOpen} handleClose={handleClose}>
        <Box
          data-testid={`${practitioner.id}_${isNurse ? 'nurse' : 'doctor'}_calcom_create_modal`}
          bgcolor="white"
          p={5}
        >
          <Stack spacing={2}>
            <Typography variant="h6" color="textPrimary">
              Create a Cal.com Account for {practitioner.name}?
            </Typography>
            {!calcomErrors && (
              <Stack spacing={2}>
                <Typography variant="body1" color="textPrimary">
                  This process will create the {isNurse ? 'Nurse' : 'Doctor'}&apos;s account in Cal.com, but they will
                  still need to be added to the Round Robin manually through the Calcom dashboard.
                </Typography>
                <Stack direction="row" justifyContent={'flex-end'} spacing={1}>
                  <Button disabled={submitting} variant="text" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    disabled={submitting}
                    variant="contained"
                    color="secondary"
                    data-testid="calcom-modal-create-button"
                    onClick={handleCalcomOnboarding}
                  >
                    Create
                  </Button>
                </Stack>
              </Stack>
            )}
            {calcomErrors && (
              <Stack spacing={2}>
                <Typography variant="body1" color="textPrimary">
                  Something went wrong completing the Cal.com account. You will need to check with Support
                </Typography>
                <Stack spacing={1}>
                  <Alert
                    data-testid="calcomResult-1"
                    severity={calcomErrors.calcomAccountSuccess ? 'success' : 'error'}
                  >
                    Calcom account creation
                  </Alert>
                  <Alert data-testid="calcomResult-2" severity={calcomErrors.pmsUpdateSuccess ? 'success' : 'error'}>
                    Calcom to PMS Id sync
                  </Alert>
                  <Alert data-testid="calcomResult-3" severity={calcomErrors.associationSuccess ? 'success' : 'error'}>
                    Calcom Team Membership creation
                  </Alert>
                  <Alert data-testid="calcomResult-4" severity={calcomErrors.scheduleSuccess ? 'success' : 'error'}>
                    Calcom Schedule creation
                  </Alert>
                  {!isNurse && (
                    <Alert
                      data-testid="calcomResult-5"
                      severity={calcomErrors.initialEventSuccess ? 'success' : 'error'}
                    >
                      Initial Doctor consultation event creation
                    </Alert>
                  )}
                  {!isNurse && (
                    <Alert
                      data-testid="calcomResult-6"
                      severity={calcomErrors.followupEventSuccess ? 'success' : 'error'}
                    >
                      Followup Doctor consultation event creation
                    </Alert>
                  )}
                </Stack>
                <Stack direction="row" justifyContent={'flex-end'} spacing={1}>
                  <Button disabled={submitting} variant="text" onClick={handleClose}>
                    Close
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Box>
      </StyledBasicModal>
    </>
  );
};
export default CreateCalcomAccountButton;
