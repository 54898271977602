import { IhiReviewLogsTable } from '@/components/tables/IhiReviewLogsTable/IhiReviewLogsTable';
import { alternaleaf, BrandThemeProvider } from '@montugroup/themes';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const IhiReviewLogs = () => {
  return (
    <BrandThemeProvider theme={alternaleaf}>
      <Box paddingX={15} paddingY={10}>
        <Typography variant={'h4'} marginBottom={5}>
          IHI Review Logs
        </Typography>
        <IhiReviewLogsTable />
      </Box>
    </BrandThemeProvider>
  );
};
