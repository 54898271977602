import MaterialTable from '@material-table/core';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, Paper, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { useAccessControl } from '@/hooks';
import { UserRole } from '@/types';

import { ReactComponent as EPrescription } from '../assets/images/e-prescription.svg';
import { ReactComponent as PaperPrescription } from '../assets/images/paper - prescritpion.svg';

const MaterialTablePrescription = (props) => {
  const { restrictAccess } = useAccessControl();
  const rowBackgroundColors = (status) => {
    let color;

    if (status === 'Active') {
      color = '#A7FFC2';
    }
    if (status === 'Deleted' || status === 'Cancelled') {
      color = '#FFCCCB';
    }
    if (status === 'Expired') {
      color = '#EAEAEA';
    }
    return color;
  };

  const marginAlign = (status) => {
    let val;

    if (status === 'Deleted' || status === 'Expired' || status === 'Active') {
      val = '27%';
    }
    if (status === 'Cancelled') {
      val = '27.4%';
    }
    return val;
  };

  const handleDeletePrescriptionBtn = () => {
    const deletePrescriptionBtn = (
      <Link onClick={() => props.getUrlOfPrescription(props.rowHeader.script, true, 1)}>
        <Button style={{ backgroundColor: 'transparent', height: '10%' }} marginright="auto">
          <VisibilityIcon fontSize="small" data-testid="visiblity-icon" />
        </Button>
      </Link>
    );

    return restrictAccess([UserRole.Admin, UserRole.Nurse], deletePrescriptionBtn);
  };

  return (
    <Box
      my={1.5}
      height="auto"
      border={1}
      borderColor={props.rowHeader.Status === 'Active' ? '#6FB6C8' : '#D6D6D6'}
      marginBottom="1rem"
      padding="10px"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        component="div"
        borderBottom="1px solid #D8D8D8"
        paddingTop="0px"
      >
        <Box mt={1} marginLeft="0.3rem" width="5%" marginRight="0px">
          {props.rowHeader.order_code.startsWith('ALEPS') ? (
            <>
              <Tooltip title="E-Script">
                <span>
                  <EPrescription data-testid="e-prescription-icon" />
                </span>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title="Paper-Script">
                <PaperPrescription data-testid="paper-script-icon" />
              </Tooltip>
            </>
          )}
        </Box>
        <Box mt={1} marginRight="0.4rem" fontSize={13} style={{ fontWeight: 'bold' }} width="18%">
          {props.rowHeader.order_code}
        </Box>
        <Box mt={1} width="5%">
          {handleDeletePrescriptionBtn()}
        </Box>
        <Box mt={1} marginLeft="2rem">
          <div
            data-testid="status"
            style={{
              backgroundColor: rowBackgroundColors(props.rowHeader.Status),
              fontSize: '12px',
              textAlign: 'center',
              padding: '2px 5px',
              width: '70px'
            }}
          >
            {props.rowHeader.Status}
          </div>
        </Box>
        <Box style={{ marginLeft: marginAlign(props.rowHeader.Status), fontSize: '12px', fontWeight: 'bold' }} mt={1}>
          {props.rowHeader.Date}
        </Box>
        <Box mt={1} marginLeft="auto" fontSize={12} marginRight="1.8%">
          {props.rowHeader.order_code.startsWith('ALPR') &&
            props.rowHeader.Status === 'Active' &&
            props.validateRole() &&
            props.patientActive &&
            !props.dischargeProgressStatus && (
              <Link onClick={() => props.deleteRecord(props.rowHeader.delete_id)}>
                <Button style={{ backgroundColor: 'transparent', marginBottom: '5px' }} data-testid="delete-icon">
                  <DeleteIcon fontSize="small" />
                </Button>
              </Link>
            )}
        </Box>
      </Box>
      <MaterialTable
        columns={props.refillColumn}
        data={props.refillRows}
        components={{
          Container: (containerProps) => <Paper {...containerProps} elevation={0} />
        }}
        options={{
          rowStyle: (rowData) => ({
            height: rowData.parent === 2 ? '2px' : '10px',
            fontSize: '12px',
            borderBottom: rowData.isFinal ? '1px solid #D3D3D3' : ''
          }),
          showTitle: false,
          paging: false,
          toolbar: false,
          draggable: false,
          sorting: false
        }}
        parentChildData={(row, rows) => rows.find((a) => a.group_id === row.base_id)}
      />
      {props?.children}
    </Box>
  );
};

export default MaterialTablePrescription;
