import { SearchTextField, Table, toast } from '@montugroup/design-system';
import { Box, styled } from '@mui/material';
import React, { useState } from 'react';

import { useTableUtils } from '@/components/tables/hooks/useTableUtils';
import { FF_ENABLE_ALTERNATE_PENDING_CANCELLATION } from '@/constants/featureFlags';
import { getAuthData } from '@/data/service/authService';
import { useFeatureFlags } from '@/hooks';
import { useGetDoctorCalendarConsultations } from '@/hooks/consult/useGetDoctorCalendarConsultations';
import type { DoctorConsultation, ISODateString } from '@/types';
import { ConsultationTableColumnHeaders } from '@/types';

import {
  createCancelLink,
  createRescheduleLink,
  createViewLink,
  formatPatientDob,
  formatPatientName,
  formatStartTime
} from './components/SharedValuesCalendarColumns';

const StyledBox = styled(Box)({
  marginTop: 10,
  width: '100%'
});

const StyledTable = styled(Table)({
  '.MuiTableCell-root': {
    fontSize: '1rem'
  }
});

type ConsultationTabOverviewTableProps = {
  getQueryParams: {
    selectedDoctor: number | string;
    dateIsoString: ISODateString;
  };
};

/**
 * DoctorCalendarTable component
 *
 * @remarks
 * Renders a table of consultations for doctors on the Doctor Calendar page
 *
 * @typedef {object} GetQueryParams
 * @property {string} doctor - The doctor ID
 * @property {string} dateISOString - The ISODateString of the consultations
 *
 * @param {GetQueryParams} getQueryParams - Contains 'doctor' and 'date' used as params for the GET query
 *
 * @returns {JSX.Element} JSXElement
 */

export const DoctorCalendarTable = ({ getQueryParams }: ConsultationTabOverviewTableProps) => {
  const { flags } = useFeatureFlags();
  const ffAlterativePendingFlow = flags[FF_ENABLE_ALTERNATE_PENDING_CANCELLATION];

  const [searchFilterText, setSearchFilterText] = useState('');
  const { isDoc } = getAuthData();

  const { createColumnDefinition } = useTableUtils();

  const {
    doctorCalendarConsultations,
    isError: isErrorDoctorConsultations,
    isLoading: isLoadingDoctorConsultations,
    refetch: refetchGetDoctorsConsultations
  } = useGetDoctorCalendarConsultations({
    selectedDoctor: getQueryParams.selectedDoctor,
    dateIsoString: getQueryParams.dateIsoString
  });

  const resultsNotFoundMessage = searchFilterText.length
    ? 'No matching consultations found for that search'
    : 'No consultations found';

  if (isErrorDoctorConsultations) {
    toast.error('There has been an error loading the Doctor Consultations');
  }

  const handleSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilterText(event.target.value);
  };

  const handleClearSearchFilter = () => {
    setSearchFilterText('');
  };

  const formattedNurseConsultations = doctorCalendarConsultations?.map((consultation: DoctorConsultation) => {
    return {
      id: consultation.consultation_code,
      appointment_time: formatStartTime(consultation),
      practitioner: consultation.doctor_name,
      patient_name: formatPatientName(consultation),
      patient_phone: consultation.patient_phone,
      patient_email: consultation.patient_email,
      patient_code: consultation.patient_code,
      patient_dob: formatPatientDob(consultation),
      consultation_type: `${consultation.consult_type} - Doctor`,
      consultation_status: consultation.status,
      view: createViewLink(consultation),
      reschedule: createRescheduleLink(consultation, refetchGetDoctorsConsultations),
      cancel: createCancelLink(consultation, ffAlterativePendingFlow, refetchGetDoctorsConsultations)
    };
  });

  const columns = [
    createColumnDefinition('view', ConsultationTableColumnHeaders.ViewLink),
    createColumnDefinition('id', ConsultationTableColumnHeaders.ConsultID),
    createColumnDefinition('appointment_time', ConsultationTableColumnHeaders.AppointmentTime),
    createColumnDefinition('practitioner', ConsultationTableColumnHeaders.Practitioner),
    createColumnDefinition('patient_name', ConsultationTableColumnHeaders.PatientName),
    createColumnDefinition('patient_phone', ConsultationTableColumnHeaders.PatientPhone),
    createColumnDefinition('patient_email', ConsultationTableColumnHeaders.PatientEmail),
    createColumnDefinition('patient_code', ConsultationTableColumnHeaders.PatientId),
    createColumnDefinition('patient_dob', ConsultationTableColumnHeaders.PatientDob),
    createColumnDefinition('consultation_type', ConsultationTableColumnHeaders.ConsultationType),
    createColumnDefinition('consultation_status', ConsultationTableColumnHeaders.ConsultationStatus),
    createColumnDefinition('reschedule', ConsultationTableColumnHeaders.RescheduleLink),
    createColumnDefinition('cancel', ConsultationTableColumnHeaders.CancelLink)
  ];

  return (
    <StyledBox>
      <SearchTextField
        handleChange={handleSearchOnChange}
        handleClear={handleClearSearchFilter}
        labelText="Search"
        enableClear={true}
      />
      <StyledTable
        data={formattedNurseConsultations || []}
        columns={columns}
        isLoading={isLoadingDoctorConsultations}
        pageSize={20}
        globalFilter={searchFilterText}
        noDataFoundMessage={resultsNotFoundMessage}
        columnVisibility={{
          reschedule: !isDoc, // 'Reschedule' is hidden to doctors
          cancel: !isDoc, // 'Cancel' is hidden to doctors
          practitioner: !isDoc // 'Practitioner' is hidden to doctors
        }}
        showPagination
      />
    </StyledBox>
  );
};
