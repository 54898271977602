import settings from '../../data/constants';

import useRequest from './useRequest';

type UsePostPaymentsChargeForConsultPayload = {
  consultationId: number;
  emailId: string; // It's actually the email address
  amount: string; // String representation of amount to charge e.g. '59'
  discountRate: string; // String representation of discount percent e.g. '10' == 10% off amount
  discountType?: 'CONCESSION' | 'COMPLIMENTARY'; // Optional discount reason for accounting if discountRate is present
  paymentMethod?: 'INVOICE' | 'CREDIT_CARD';
};

export type UsePostPaymentsChargeForConsultResponse = {
  payment_queued?: boolean;
  xero_consultation_code?: string;
  message?: string;
};

export function usePostPaymentsChargeForConsult() {
  const { loading, data, error, execute } = useRequest<UsePostPaymentsChargeForConsultResponse>({
    config: {
      url: `${settings.url}/payments/charge-for-consult`,
      method: 'POST'
    }
  });

  const doPost = async (body: UsePostPaymentsChargeForConsultPayload) => {
    return execute({ data: body });
  };

  return {
    loading,
    data,
    error,
    doPost
  };
}

export default usePostPaymentsChargeForConsult;
