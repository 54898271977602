import type { StateCreator } from 'zustand';

import type { CancelMedicationItemType } from '@/components/PatientPageV2/components/PrescriptionPad';
import { initialPrescriptionHistoryState, initialPrescriptionPadState, initialWAHealthState } from '../state';
import type { PrescriptionAssistantSliceType } from '../types';
import { createReissuedMedication } from '../utils';
import {
  determineReissueOrCancelWhenChangingProducts,
  getUpdateMedicationPadItems
} from '../utils/updateMedicationPadItems.utils';

export const prescriptionAssistantSlice: StateCreator<
  PrescriptionAssistantSliceType,
  [['zustand/immer', unknown], ['zustand/devtools', unknown]]
> = (set, get) => ({
  // state
  prescriptionAssistant: {
    prescriptionHistory: initialPrescriptionHistoryState,
    prescriptionPad: initialPrescriptionPadState,
    waHealth: initialWAHealthState
  },
  // computed state
  isValidPrescriptionPad: () => {
    const state = get();
    const itemsToCancel = state?.prescriptionAssistant?.prescriptionHistory?.itemsToCancel || [];
    const medicationPadItems = state?.prescriptionAssistant?.prescriptionPad?.medicationPadItems || [];
    const isMedicationPadItemFilterInprogress = medicationPadItems.some((item) => !!item.filters);

    // check if there are items to cancel & no other medications in the pad
    // check if there are any empty medications (without filters)
    if (Boolean(itemsToCancel.length) && !isMedicationPadItemFilterInprogress) {
      return true;
    }

    const hasMedicationsWithErrorMessage = get()?.prescriptionAssistant?.prescriptionPad?.medicationPadItems?.some(
      (item) => !!item?.errorMessage
    );

    return (
      // check if there are medications in the precription pad
      Boolean(get()?.prescriptionAssistant?.prescriptionPad?.medicationPadItems.length) &&
      // check if all the medications in the prescription pad have selected product
      Boolean(
        get()?.prescriptionAssistant?.prescriptionPad?.medicationPadItems?.every((item) => !!item.medicationPadItem)
      ) &&
      // Check that no medications in the prescription pad have an error message
      Boolean(!hasMedicationsWithErrorMessage)
    );
  },
  // prescription pad actions
  updateMedicationPadItems: (medicationPadItems) =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        state.prescriptionAssistant.prescriptionPad.medicationPadItems = medicationPadItems;
      },
      false,
      'prescriptionPad/updateMedicationPadItems'
    ),
  updateMedicationPadItemById: (
    updatedMedicationPadItem,
    { ffPatPrescriptionDosageMinLengthValidation, ffV4PrescriptionAssistantCancelPrescribedMedication }
  ) => {
    const { prescriptionAssistant, updatePrescriptionHistoryItemsToReissue, moveItemToCancelFromReissue } = get();

    // Update medication pad items
    const { updatedItems, previousProductId } = getUpdateMedicationPadItems(
      prescriptionAssistant.prescriptionPad.medicationPadItems,
      updatedMedicationPadItem,
      ffPatPrescriptionDosageMinLengthValidation
    );

    set(
      (state) => {
        state.prescriptionAssistant.prescriptionPad.medicationPadItems = updatedItems;
      },
      false,
      'prescriptionPad/updateMedicationPadItemsById'
    );

    // Determine reissue or cancel logic in case products have changed
    const result = determineReissueOrCancelWhenChangingProducts(
      prescriptionAssistant.prescriptionHistory.itemsToReissue,
      previousProductId,
      updatedMedicationPadItem.medicationPadItem?.productId ?? -1,
      ffV4PrescriptionAssistantCancelPrescribedMedication
    );

    // Handle action based on determined result
    if (result.type === 'UPDATE_REISSUE') {
      updatePrescriptionHistoryItemsToReissue(result.updatedItemsToReissue);
    } else if (result.type === 'MOVE_TO_CANCEL') {
      moveItemToCancelFromReissue(result.itemToCancel);
    }
  },
  addReissuedMedicationToPrescriptionPad: (
    availableMedications,
    reissuedMedication,
    ffPatPrescriptionDosageMinLengthValidation
  ) =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        const existingMedicationPadItems = state.prescriptionAssistant.prescriptionPad.medicationPadItems.filter(
          (item) => !!item?.medicationPadItem
        );
        const newReissuedMedication = createReissuedMedication(
          availableMedications,
          reissuedMedication,
          ffPatPrescriptionDosageMinLengthValidation
        );

        if (!newReissuedMedication) {
          return;
        }

        const updatedPadMedications = [newReissuedMedication, ...existingMedicationPadItems];
        state.prescriptionAssistant.prescriptionPad.medicationPadItems = updatedPadMedications;
      },
      false,
      'prescriptionPad/addReissuedMedication'
    ),
  updateDevicePadItems: (devicePadItems) =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        state.prescriptionAssistant.prescriptionPad.devicePadItems = devicePadItems;
      },
      false,
      'prescriptionPad/updateDevicePadItems'
    ),
  updatePrescriptionPadReviewAction: (action) =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        const updatedActions = state.prescriptionAssistant.prescriptionPad.actions.map((actionItem) => {
          if (actionItem.id === action.id) {
            return action;
          }

          return actionItem;
        });
        state.prescriptionAssistant.prescriptionPad.actions = updatedActions;
      },
      false,
      'prescriptionPad/updatePrescriptionPadReviewAction'
    ),
  updatePrescriptionPadStep: (step) =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        state.prescriptionAssistant.prescriptionPad.step = step;
      },
      false,
      'prescriptionPad/updatePrescriptionPadStep'
    ),
  resetPrescriptionPad: () =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        state.prescriptionAssistant.prescriptionPad = initialPrescriptionPadState;
      },
      false,
      'prescriptionPad/reset'
    ),
  // prescription history actions
  updatePrescriptionHistoryItemsToReissue: (itemsToReissue) =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        state.prescriptionAssistant.prescriptionHistory.itemsToReissue = itemsToReissue;
      },
      false,
      'prescriptionHistory/updatePrescriptionHistoryItemsToReissue'
    ),
  updatePrescriptionHistoryItemsToCancel: (itemsToCancel) =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        state.prescriptionAssistant.prescriptionHistory.itemsToCancel = itemsToCancel;
      },
      false,
      'prescriptionHistory/updatePrescriptionHistoryItemsToCancel'
    ),
  updatePrescriptionHistoryItemsToAction: (itemsToAction) =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        state.prescriptionAssistant.prescriptionHistory.itemsToAction = itemsToAction;
      },
      false,
      'prescriptionHistory/updatePrescriptionHistoryItemsToAction'
    ),

  resetPrescriptionHistory: () =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        state.prescriptionAssistant.prescriptionHistory = initialPrescriptionHistoryState;
      },
      false,
      'prescriptionHistory/reset'
    ),

  moveItemToCancelFromReissue: (itemToCancel: CancelMedicationItemType) =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        if (!itemToCancel.productId || !itemToCancel.displayName) {
          return;
        }
        // If the item is in the list of items to reissue, then we want to cancel it
        state.prescriptionAssistant.prescriptionHistory.itemsToReissue
          .filter((itemToReissue) => itemToReissue.medicationPadItem?.productId === itemToCancel.productId)
          // This should happen at most 1 time
          .forEach((_) => {
            state.prescriptionAssistant.prescriptionHistory.itemsToCancel = [
              ...state.prescriptionAssistant.prescriptionHistory.itemsToCancel.filter(
                (item) => item.productId !== itemToCancel.productId
              ),
              itemToCancel
            ];
          });

        // If the item is in the list of items to reissue, then we want to remove it from that list
        state.prescriptionAssistant.prescriptionHistory.itemsToReissue =
          state.prescriptionAssistant.prescriptionHistory.itemsToReissue.filter(
            (item) => item.medicationPadItem?.productId !== itemToCancel.productId
          );
      },
      false,
      'prescriptionHistory/moveItemToCancelFromReissue'
    ),
  setIsWaPatient: (isWa: boolean) =>
    set(
      (state: PrescriptionAssistantSliceType) => {
        state.prescriptionAssistant.waHealth.isWaPatient = isWa;
      },
      false,
      'waHealth/setIsWaPatient'
    )
});
