import { ihiReviewLogClient } from '@/api/patients/ihiReviewLogClient';
import { toast } from '@montugroup/design-system';
import { useQueryClient } from '@tanstack/react-query';

interface IhiResolutionReasonParams {
  reason: string;
  reviewId: number;
}

export const usePostIhiResolutionReason = () => {
  const queryClient = useQueryClient();
  const ihiQueryClient = ihiReviewLogClient();

  const { mutateAsync, isError, isSuccess, data, error } = ihiQueryClient.reviewIhiReviewLog.useMutation();

  const postResolutionReason = async ({ reason, reviewId }: IhiResolutionReasonParams) => {
    const payload = {
      params: { reviewId },
      body: { data: { reason } }
    };

    try {
      const result = await mutateAsync(payload);

      // Invalidate the relevant query to refresh the data
      queryClient.invalidateQueries();

      toast.success('Reason added');

      return result;
    } catch (err) {
      toast.error('There was an error when adding the reason');
      throw err;
    }
  };

  return {
    postResolutionReason,
    isError,
    isSuccess,
    data,
    error
  };
};
