import type { RowModel } from '@tanstack/react-table';
import type { DiscontinuedTableRowData } from '../components/DiscontinuedPanel/DiscontinuedPanel.types';
import type { OutOfStockTableRowData } from '../components/OutOfStockPanel/OutOfStockPanel.types';

const getPatientRescriptRequestCount = (
  rowModel: RowModel<DiscontinuedTableRowData> | RowModel<OutOfStockTableRowData>
) => {
  return rowModel.rows.reduce((count, row) => {
    const result = row.getValue<string>('ageOfRescriptRequest');
    const isPatientRequested = result.length > 0;
    return isPatientRequested ? count + 1 : count;
  }, 0);
};

export { getPatientRescriptRequestCount };
