import { normalizeHeaders, requestHeaders } from '@/api';
import settings from '@/data/constants';
import { tsRestFetchApi } from '@ts-rest/core';
import { initQueryClient } from '@ts-rest/react-query';

interface Contract {
  [key: string]: any;
}

interface CreateQueryClientArgs<T extends Contract> {
  contract: T;
  baseUrl?: string;
  baseHeaders?: Record<string, string>;
}

export function createQueryClient<T extends Contract>({
  contract,
  baseUrl = settings.baseXApiUrl,
  baseHeaders = {}
}: CreateQueryClientArgs<T>) {
  return initQueryClient(contract, {
    baseUrl,
    baseHeaders,
    api: (args) => {
      args.headers = normalizeHeaders({ ...requestHeaders(), ...args.headers });
      return tsRestFetchApi(args);
    }
  });
}
