interface OutOfStockScriptMedicationCellData {
  name: string;
  isOutOfStock: boolean;
}

interface OutOfStockTableRowData {
  uniqueRowKey: string;
  patientName: string;
  patientCode: string;
  patientId: string;
  outOfStockMedicationName: string;
  allMedicationsOnScript: OutOfStockScriptMedicationCellData[];
  selectedAlternativeProductId: number;
  doctorName: string;
  ageOfRescriptRequest: string;
}

enum OutOfStockTableColumnHeaders {
  Age_Of_Rescript_Request = 'Patient Requested',
  Patient_Name = 'Patient Name',
  Patient_Code = 'Patient Code',
  Out_Of_Stock_Medication = 'Out of Stock Medication',
  Script_Medications = 'Script Medications',
  Alternative_Medication_List = 'Alternative Medication',
  Doctor_Name = 'Doctor'
}

export { OutOfStockTableColumnHeaders };
export type { OutOfStockScriptMedicationCellData, OutOfStockTableRowData };
