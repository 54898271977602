import type { GetDoctorPatientAndConsultCountResponse } from '@/api/doctor/getDoctorPatientAndConsultCount';
import { getDoctorPatientAndConsultCount } from '@/api/doctor/getDoctorPatientAndConsultCount';
import { QueryKey } from '@/data/query';

import { useQuery } from '@tanstack/react-query';

type UseGetDoctorPatientAndConsultCountParams = {
  doctorId: number;
  autoRun?: boolean;
};

export const useGetDoctorPatientAndConsultCount = ({
  doctorId,
  autoRun = true
}: UseGetDoctorPatientAndConsultCountParams) => {
  const { data, error, isLoading, refetch } = useQuery<GetDoctorPatientAndConsultCountResponse>({
    queryKey: [QueryKey.GetDoctorPatientAndConsultCount, doctorId],
    queryFn: async () => {
      return await getDoctorPatientAndConsultCount(doctorId);
    },
    enabled: autoRun
  });

  return {
    data,
    error,
    isLoading,
    refetch
  };
};
