import type { PrescriptionFormProduct } from '@/hooks/prescription/usePrescriptionForm';
import { Logger } from '@/utils/logger';
import { useCallback, useRef, useState } from 'react';
import { extractErrorMessage, transformDataForRequest, validateResponse } from './useNotificationOfTreatmentPdf.utils';
import usePostPreviewNotificationForm from './usePostPreviewNotificationForm';

export interface FetchPdfParams {
  patientId: string;
  medicationProductsPayload: PrescriptionFormProduct[];
}

export const useNotificationOfTreatmentPdf = () => {
  const [pdfString, setPdfString] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { mutateAsync: previewNotificationForm } = usePostPreviewNotificationForm();

  // Reference to store the last fetch parameters and their corresponding PDF string
  const cacheRef = useRef<{
    params: FetchPdfParams;
    result: string;
  } | null>(null);

  // Function to convert Blob to base64 string
  const generatePdfString = useCallback((pdfBlob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        if (reader.result) {
          resolve(reader.result as string);
        } else {
          const logger = new Logger('generatePdfString');

          logger.error('successfully read pdf blob but result is empty');

          reject(new Error());
        }
      };

      reader.onerror = () => {
        const logger = new Logger('generatePdfString');

        logger.error('error occurred while reading pdf blob');

        reject(new Error());
      };

      reader.readAsDataURL(pdfBlob);
    });
  }, []);

  const shouldUseCache = (data: FetchPdfParams): boolean => {
    const { patientId, medicationProductsPayload } = data;

    // Check if the current params match the cached params
    const isSamePatient = cacheRef.current?.params.patientId === patientId;
    const isSamePayload =
      cacheRef.current &&
      JSON.stringify(cacheRef.current.params.medicationProductsPayload) === JSON.stringify(medicationProductsPayload);

    return Boolean(isSamePatient && isSamePayload);
  };

  const fetchPdf = useCallback(
    async (data: FetchPdfParams) => {
      if (shouldUseCache(data)) {
        setPdfString(cacheRef.current?.result ?? null);
        return;
      }

      setIsLoading(true);
      setError(null);

      const requestBody = transformDataForRequest(data);

      try {
        const { body: responseBody } = await previewNotificationForm({ body: requestBody });
        const pdfBlob = validateResponse(responseBody);
        const base64data = await generatePdfString(pdfBlob);

        setPdfString(base64data);

        // Update the cache with the current parameters and result
        cacheRef.current = {
          params: data,
          result: base64data
        };
      } catch (err) {
        setError(new Error(extractErrorMessage(err)));
      } finally {
        setIsLoading(false);
      }
    },
    [previewNotificationForm, generatePdfString]
  );

  return { pdfString, isLoading, error, fetchPdf };
};

export default useNotificationOfTreatmentPdf;
