import { z } from 'zod';

import type { Filters } from '@/shared-ui/ProductFilter/ProductFilter';

import type { MedicationPrescriptionStatus } from '@montugroup/prescription-contracts';
import type { MedicationType } from '../ProductDetailsCard/ProductDetailsCard.types';

export enum PrescriptionPadStep {
  Create = 1,
  Confirm = 2
}

export type PrescriptionPadReviewAction = {
  id: number;
  label: string;
  checked: boolean;
};

export type AnswerDisplayPositionType = {
  answerId: number;
  displayPosition: number;
};

export type CancelMedicationItemType = {
  productId: number;
  displayName: string;
};

export type FeedbackType = {
  questionAsked: number;
  answerSelected: number;
  answerDisplayPositions?: AnswerDisplayPositionType[];
};

export type MedicationPadItemError = {
  title: string;
  description: string;
};

export type MedicationPadItemCancelData = {
  productId: number;
  supplierName?: string;
  medicationName: string;
};

export type MedicationPadItemType = {
  medicationPadItemId: string;
  medicationPadItem?: MedicationType;
  moreProductsLoaded?: boolean;
  filters?: Filters;
  feedback?: FeedbackType;
  reissueStatus?: ReissueStatusType;
  prescriptionStatus?: MedicationPrescriptionStatus;
  errorMessage?: MedicationPadItemError | null;
};

export type FeedbackPayload = {
  answerSelected: number;
  filters: Filters;
  productChosen: number;
  questionAsked: number;
};

export const ReissueStatus = z.object({
  supplierName: z.string().optional(),
  productName: z.string().optional(),
  description: z.string().optional(),
  isReissued: z.boolean().optional(),
  productId: z.number().optional(),
  /**
   * this means that an out of stock medication was selected during re-issue
   */
  isOutOfStock: z.boolean().optional()
});

export type ReissueStatusType = z.infer<typeof ReissueStatus>;
