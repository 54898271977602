import { AlertModal } from '@/shared-ui';

import { DEFAULT_NOTIFICATION_OF_TREATMENT_MODAL_PROPS } from './NotificationOfTreatmentModal.config';

export type NotificationOfTreatmentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  children: React.ReactNode;
  confirmText: string;
};

/** @deprecated please use ConfirmDialog component under the design-system */
const NotificationOfTreatmentModal = ({
  isOpen,
  onClose,
  onConfirm,
  children,
  confirmText
}: NotificationOfTreatmentModalProps) => (
  <AlertModal
    isOpen={isOpen}
    onClose={onClose}
    onConfirm={onConfirm}
    confirmText={confirmText}
    {...DEFAULT_NOTIFICATION_OF_TREATMENT_MODAL_PROPS}
    sx={{
      width: {
        xs: '95%', // Mobile devices (0px - 600px)
        sm: '90%', // Tablets and small desktops (600px - 1200px)
        lg: '70%' // Large desktops (1200px and up)
      },
      maxWidth: '1400px'
    }}
  >
    {children}
  </AlertModal>
);

export default NotificationOfTreatmentModal;
