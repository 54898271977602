import CircleIcon from '@mui/icons-material/Circle';
import { Typography } from '@mui/material';
import { PriorityChipStyled } from './PriorityChip.styled';

type PriorityChipProps = {
  value: string;
};

const PriorityChip = ({ value }: PriorityChipProps) => {
  const rescriptHasBeenRequested = value.length > 0;
  if (!rescriptHasBeenRequested) {
    return null;
  }
  return (
    <>
      <PriorityChipStyled icon={<CircleIcon />} label="Priority" size="small" />
      <Typography sx={{ marginTop: '0.2rem' }} variant="body2">
        {value}
      </Typography>
    </>
  );
};

export { PriorityChip };
