import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import useStyles from '../assets/js/patientsV2';
import CheckBoxField from '../components/fields/CheckBoxField';
import InputField from '../components/fields/InputField';
import { makePOST } from '../data/service/dataService';

import { toast } from '@montugroup/design-system';
import '../assets/scss/onboardNurse.scss';

const validationSchema = yup.object().shape({
  email: yup
    .string('Enter your email')
    .trim('Email cannot contain spaces')
    .email('Enter a valid email')
    .required('Email is required'),
  firstName: yup.string('Enter your first name').required('First name is required'),
  lastName: yup.string('Enter your last name').required('Last name is required'),
  coviuUrl: yup
    .string()
    .url('Coviu meeting link must be a valid url') // always validate url when entered
    .matches(
      /\/t\/[^/]+\/join$/,
      'Coviu meeting link must contain "/t/" and end with "/join"'
    )
    .when('createCalcomAccount', {
      is: (createCalcomAccount) => createCalcomAccount,
      then: yup
        .string()
        .url('Coviu meeting link must be a valid url')
        .matches(
          /\/t\/[^/]+\/join$/,
          'Coviu meeting link must contain "/t/" and end with "/join"'
        )
        .required('A Coviu meeting url is required when creating a Calcom account')
    }),
  createCalcomAccount: yup.boolean()
});

const OnBoardNurse = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const [calcomFailed, setCalcomFailed] = useState(null);
  const [nurseName, setNurse] = useState('');

  const handleNurseFormSubmit = async (values) => {
    setSubmitting(true);
    const sendBody = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      coviu_url: values.coviuUrl || undefined, // send undefined instead of empty string.  or backend zod url validation fails
      create_calcom_account: values.createCalcomAccount
    };
    try {
      const response = await makePOST('nurse/create', sendBody, 'OnBoardNurse');
      if (response.status === 200) {
        if (response.data?.calcomAccountResult && Object.values(response.data.calcomAccountResult).includes(false)) {
          setCalcomFailed(response.data.calcomAccountResult);
          setNurse(`${sendBody.first_name} ${sendBody.last_name}`);
          toast.error('Something went wrong: Calcom setup');
          return;
        }
        toast.success('New Nurse created successfully');
        navigate('/nurse-calendar');
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      coviuUrl: '',
      createCalcomAccount: false
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => handleNurseFormSubmit(values)
  });

  if (calcomFailed) {
    return (
      <Box px={4}>
        <Box mt={5} mb={1} textAlign="left">
          <Typography variant="h5" color="textPrimary">
            Onboard New Nurse
          </Typography>
        </Box>
        <Box maxWidth="1024px" className="less-padding">
          <Box mt={5} component="p" className={classes.heading}>
            Nurse
          </Box>
          <Stack data-testid="calcomResult-warning" mt={5} spacing={2}>
            <Alert severity="success">Nurse creation for {nurseName} was successful.</Alert>
            <Stack spacing={1}>
              <Alert severity="warning">
                There was a problem with the Calcom setup for this Nurse. You will need to complete this manually or
                contact support.
              </Alert>
              <Stack pl={5} spacing={1}>
                <Alert data-testid="calcomResult-1" severity={calcomFailed.calcomAccountSuccess ? 'success' : 'error'}>
                  Calcom account creation
                </Alert>
                <Alert data-testid="calcomResult-2" severity={calcomFailed.pmsUpdateSuccess ? 'success' : 'error'}>
                  Calcom to PMS Id sync
                </Alert>
                <Alert data-testid="calcomResult-3" severity={calcomFailed.associationSuccess ? 'success' : 'error'}>
                  Calcom Team Membership creation
                </Alert>
                <Alert data-testid="calcomResult-4" severity={calcomFailed.scheduleSuccess ? 'success' : 'error'}>
                  Calcom Schedule creation
                </Alert>
              </Stack>
            </Stack>
          </Stack>
          <Box textAlign="right" marginTop="30px">
            <Button type="submit" variant="contained" color="secondary" onClick={() => navigate('/nurse-calendar')}>
              Go to nurse calendar
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box px={4}>
      <Box mt={5} mb={1} textAlign="left">
        <Typography variant="h5" color="textPrimary">
          Onboard New Nurse
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box maxWidth="1024px" className="less-padding">
          <Box mt={5} component="p" className={classes.heading}>
            Nurse
          </Box>
          <Grid container mt={5} className="onBoardNurse">
            <InputField
              id="firstName"
              type="text"
              label="First Name"
              labelPadding="7px 5px"
              className={classes.inputField}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
            <InputField
              id="lastName"
              label="Last Name"
              type="text"
              labelPadding="7px 5px"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
            <InputField
              id="email"
              label="Email"
              type="text"
              labelPadding="7px 5px"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <InputField
              id="coviuUrl"
              label="Coviu Meeting Link"
              type="text"
              labelPadding="7px 5px"
              value={formik.values.coviuUrl}
              onChange={formik.handleChange}
              error={formik.touched.coviuUrl && Boolean(formik.errors.coviuUrl)}
              helperText={formik.touched.coviuUrl && formik.errors.coviuUrl}
            />
            <CheckBoxField
              icon={
                <Box width="17px" height="17px" ml={0.5} mt={0.5} border={1} borderRadius="2px" borderColor="#c4c4c4" />
              }
              id="createCalcomAccount"
              name="createCalcomAccount"
              label="Create Calcom Account"
              labelPadding="7px 5px"
              labelFontWeight={900}
              color="secondary"
              checked={formik.values.createCalcomAccount}
              onChange={formik.handleChange('createCalcomAccount')}
            />
            <Box textAlign="right" marginTop="30px" height="30px">
              <Button data-testid="submitBtn" disabled={submitting} type="submit" variant="contained" color="secondary">
                Onboard Nurse
              </Button>
            </Box>
          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default OnBoardNurse;
