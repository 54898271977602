import { ListItem, styled } from '@mui/material';

interface StyledListItemProps {
  medication?: boolean;
}

const ListItemStyled = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'medication'
})<StyledListItemProps>(({ medication }) => ({
  fontSize: '12px',
  color: medication ? 'red' : 'inherit'
}));

export { ListItemStyled };
