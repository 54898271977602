export type GTMType = Partial<{
  id: number;
  event: string;
  payment_value: string | null;
  consultant_name: string | null;
  status: 'Successful' | 'payment_error';
}>;

export enum GoogleAnalyticsEventName {
  PAYMENT_DETAILS_START = 'payment_details_start',
  PAYMENT_DETAILS_SUBMIT = 'payment_details_submit',
  PAYMENT_DETAILS_ERROR = 'payment_details_error',
  PRESCREENING_AND_BOOKING_COMPLETED = 'prescreening_and_booking_completed'
}

export enum GoogleAnalyticsEventId {
  PAYMENT_DETAILS_START = 6,
  PAYMENT_DETAILS_SUBMIT = 7,
  PAYMENT_DETAILS_ERROR = 8,
  PRESCREENING_AND_BOOKING_COMPLETED = 9
}

export type TrackingTypes = GTMType;
