const EMPTY_TABLE_CELL_PLACEHOLDER = '---';
const BLANK_URL = '#';
const TABLE_PAGE_SIZE = 50;
const GENERIC_FETCH_ERROR_MESSAGE = 'An error has occurred';
const SUCCESSFUL_SCRIPT_UPDATE_MESSAGE = 'Patient scripts successfully created';

export {
  BLANK_URL,
  EMPTY_TABLE_CELL_PLACEHOLDER,
  GENERIC_FETCH_ERROR_MESSAGE,
  SUCCESSFUL_SCRIPT_UPDATE_MESSAGE,
  TABLE_PAGE_SIZE
};
