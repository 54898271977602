import type { CatalogProduct } from '@/hooks/rest/useGetProductList';
import type { ModifiedPrescriptionMap } from '../Rescripting.types';
import { parseUniqueRowKey } from './uniqueRowKey';

type ApprovedProductMap = Map<number, CatalogProduct | undefined>;
type ApprovedScriptPerApprovedProductMap = Map<number, ApprovedProductMap>;

/**
 * Loops over the prescriptions that have been approved for rescript
 * and builds a map that contains the scriptId with its associated
 * modified products.
 * @param approvedPrescriptionKeys an array of prescription keys
 * @param modifiedPrescriptions a map that holds prescription key
 * to modified product associations
 * @returns a map of the approved scriptsId
 */
const makeApprovedPrescriptionChangeset = (
  approvedPrescriptionKeys: string[],
  modifiedPrescriptions: ModifiedPrescriptionMap
): ApprovedScriptPerApprovedProductMap => {
  const result = new Map();
  approvedPrescriptionKeys.forEach((prescriptionKey: string) => {
    const { scriptId, productId } = parseUniqueRowKey(prescriptionKey);
    if (!result.has(scriptId)) {
      result.set(scriptId, new Map());
    }
    const productMap = result.get(scriptId) as ApprovedProductMap;
    const modifiedProduct = modifiedPrescriptions.get(prescriptionKey);
    productMap.set(productId, modifiedProduct);
  });
  return result;
};

export { makeApprovedPrescriptionChangeset };
