import { Box, styled } from '@mui/material';

// Base styles shared by Viewer components
const baseViewerStyles = {
  width: '100%',
  height: '60vh',
  border: 'none'
};

// Viewer component (iframe)
export const IframeViewer = styled('iframe')(({ theme }) => ({
  ...baseViewerStyles,
  marginBottom: theme.spacing(4)
}));

// BoxViewer component (div)
export const BoxViewer = styled('div')(({ theme }) => ({
  ...baseViewerStyles,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(4)
}));

export const DefaultErrorViewer = styled('div')(({ theme }) => ({
  ...baseViewerStyles,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  borderRadius: theme.spacing(1.5),
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(8),
  marginBottom: theme.spacing(4),
  boxSizing: 'border-box'
}));

export const PrewrapBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(6),
  whiteSpace: 'pre-wrap'
}));

export const Bold = styled('span')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold
}));

export const Paragraph = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4)
}));
