import type { ConsultationFields } from '@/components/PatientPageV2/ConsultationTab.types';
import {
  compareValuesPMSVersusIHI,
  formatPatientDobIhiModals,
  getIHINumberFromUrl
} from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/IhiVerifyAndUpdateModals.utils';

import { IhiModalField } from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/components/ModalFields/IhiModalField';
import { IhiModalFieldRadioGroup } from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/components/ModalFields/IhiModalFieldRadioGroup';
import { ModalIhiQuestions } from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/components/ModalIhiQuestions/ModalIhiQuestions';
import type { RadioSelectionState } from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/components/ihiVerifyAndUpdateModals.types';
import { ModalIhiFieldNames } from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/components/ihiVerifyAndUpdateModals.types';
import type {
  HandleUpdateIhiDetailsArgs,
  PatientIHISavePayload,
  PatientProfileUpdatePayload,
  PatientVerifyIhiResponseData
} from '@/components/PatientPageV2/components/IhiVerifyAndUpdateModals/hooks/usePatientIhi';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Paper, RadioGroup, Stack, styled } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import { blueGrey, brown } from '@mui/material/colors';
import type { ChangeEvent } from 'react';
import { useEffect, useMemo, useState } from 'react';

export type ModalChooseIhiNumberProps = {
  patientVerifyIhiResponseData: PatientVerifyIhiResponseData;
  patientDetailsInPMS: ConsultationFields;
  unverifiedIhiNumber: string | null;
  handleUpdateIhiDetails: (args: HandleUpdateIhiDetailsArgs) => void;
  handleModalClose: () => void;
};

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'scroll'
});

const StyledModalContents = styled(Paper)({
  padding: 24,
  width: 916,
  outline: 'none',
  color: blueGrey[900]
});

/**
 * ModalChooseIhiNumber
 *
 * This component renders a modal that allows users to choose and update the IHI number
 * for a patient. It displays options for both verified and unverified IHI numbers, along with other
 * patient information fields that may need to be aligned with the HI Service.
 *
 * @param {ModalChooseIhiNumberProps} props - The props for the component.
 * @param {PatientVerifyIhiResponseData} props.patientVerifyIhiResponseData - The response data containing patient IHI verification details.
 * @param {ConsultationFields} props.patientDetailsInPMS - The patient details from PMS.
 * @param {string | null} props.unverifiedIhiNumber - The unverified IHI number for the patient.
 * @param {(args: HandleUpdateIhiDetailsArgs) => void} props.handleUpdateIhiDetails - The function to handle updating the IHI details.
 * @param {() => void} props.handleModalClose - The function to handle closing the modal.
 *
 * @returns {JSX.Element} The rendered component.
 */

export const ModalChooseIhiNumber = (props: ModalChooseIhiNumberProps) => {
  const [reasonForUpdate, setReasonForUpdate] = useState<string | null>(null);
  const [selectedIhiRadio, setSelectedIhiRadio] = useState('verifiedIhiNumber');
  const [otherFieldsRadioSelected, setOtherFieldsRadioSelected] = useState<RadioSelectionState>();

  const {
    patientVerifyIhiResponseData,
    patientDetailsInPMS,
    unverifiedIhiNumber,
    handleUpdateIhiDetails,
    handleModalClose
  } = props;

  const patientInIHI = patientVerifyIhiResponseData.patient;
  const verifiedIhiNumber = getIHINumberFromUrl(patientVerifyIhiResponseData.patient?.ihiNumber);

  const ihiPatientFieldsWithDifferences = useMemo(() => {
    return compareValuesPMSVersusIHI({
      patientInPMS: patientDetailsInPMS,
      patientInIHI: patientVerifyIhiResponseData.patient
    });
  }, [patientDetailsInPMS, patientVerifyIhiResponseData]);

  // Determine if there are any extra fields apart from ihiNumber which need to be selected (firstName, lastName, medicareNumber etc)
  const { ihiNumber: _, ...allOtherIhiFields } = ihiPatientFieldsWithDifferences;
  const hasExtraFields = Object.values(allOtherIhiFields).filter((value) => value).length > 0;

  const hasChosenVerifiedIhiNumber = selectedIhiRadio === 'verifiedIhiNumber';
  const hasChosenVerifiedWithExtraFields = hasChosenVerifiedIhiNumber && hasExtraFields;
  const isConfirmButtonDisabled = !hasChosenVerifiedIhiNumber && !reasonForUpdate;

  const modalTitle = hasChosenVerifiedWithExtraFields ? 'Confirm IHI Number (and other fields)' : 'Confirm IHI Number';

  useEffect(() => {
    const derivedRadioButtonState = Object.fromEntries(
      Object.entries(ihiPatientFieldsWithDifferences)
        .filter(([_key, value]) => value)
        .map(([key]) => [key, true])
    );
    setOtherFieldsRadioSelected(derivedRadioButtonState);
  }, [ihiPatientFieldsWithDifferences]);

  const handleUpdateSubmit = () => {
    const updateProfile: PatientProfileUpdatePayload = {
      ...(otherFieldsRadioSelected?.firstName &&
        patientInIHI?.firstName !== null && { firstName: patientInIHI?.firstName }),
      ...(otherFieldsRadioSelected?.lastName &&
        patientInIHI?.lastName !== null && { lastName: patientInIHI?.lastName }),
      ...(otherFieldsRadioSelected?.medicareNumber &&
        patientInIHI?.medicareNumber !== null && { medicareNumber: patientInIHI?.medicareNumber }),
      ...(otherFieldsRadioSelected?.medicareRefNumber &&
        patientInIHI?.medicareRefNumber !== null && { medicareRefNumber: Number(patientInIHI?.medicareRefNumber) }),
      ...(otherFieldsRadioSelected?.dob &&
        patientInIHI?.doB !== null && { dob: patientInIHI?.doB ? new Date(patientInIHI.doB) : undefined }),
      ...(otherFieldsRadioSelected?.gender && patientInIHI?.sex !== null && { gender: patientInIHI?.sex })
    };

    const updatedPatientDetailsInIHI: PatientIHISavePayload = {
      requestMessageID: patientVerifyIhiResponseData.requestMessageID as string | null,
      responseMessageID: patientVerifyIhiResponseData.responseMessageID as string | null,
      errorCode: patientVerifyIhiResponseData.errorCode,
      ihiStatus: hasChosenVerifiedIhiNumber
        ? patientVerifyIhiResponseData.ihiStatus
          ? patientVerifyIhiResponseData.ihiStatus
          : null
        : null,
      ihiRecordStatus: hasChosenVerifiedIhiNumber
        ? patientVerifyIhiResponseData.ihiRecordStatus
          ? patientVerifyIhiResponseData.ihiRecordStatus
          : null
        : null,
      patient: {
        ihiNumber: hasChosenVerifiedIhiNumber ? (verifiedIhiNumber as string) : (unverifiedIhiNumber as string)
      }
    };

    handleUpdateIhiDetails({
      patientIHISavePayload: updatedPatientDetailsInIHI,
      ...(hasChosenVerifiedWithExtraFields && { updateProfile }),
      ...(reasonForUpdate && { reasonForUpdate })
    });

    if (handleModalClose) {
      handleModalClose();
    }
  };

  const handleIhiChangeRadio = (_event: ChangeEvent<HTMLInputElement>, value: string) => {
    setSelectedIhiRadio(value);
    setReasonForUpdate(null);
  };

  const handleOtherFieldChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const booleanValue = JSON.parse(value);

    setOtherFieldsRadioSelected((prevState) => ({
      ...prevState,
      [name]: booleanValue
    }));
  };

  const handleFeedbackOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReasonForUpdate(event.target.value);
  };

  function getAlert() {
    const noteText =
      !hasChosenVerifiedWithExtraFields && !hasChosenVerifiedIhiNumber
        ? "Note: If the patient has confirmed the unverified IHI is correct, please answer why you want to save it, then press 'confirm' and request supporting documentation from the patient."
        : 'Note: Please ask the patient to verify the correct information before you choose the patient’s information you wish to align with the HI Service.';

    return (
      <Alert severity="warning">
        <Typography>{noteText}</Typography>
      </Alert>
    );
  }

  return (
    <StyledModal open={true} closeAfterTransition>
      <StyledModalContents>
        <Box padding={'1rem'}>
          <Stack direction={'row'} alignItems={'baseline'} paddingBottom={5}>
            <Box paddingRight={4}>
              <WarningAmberIcon color={'warning'} />
            </Box>
            <Typography variant={'h4'} color={brown[700]}>
              {modalTitle}
            </Typography>
          </Stack>
          <Box paddingBottom={8}>
            <Typography>
              You have manually updated the IHI number, but it differs from the IHI number in the HI Service for this
              patient. Please select which IHI number you wish to use.
            </Typography>
          </Box>
          <Box marginBottom={2}>
            <RadioGroup value={selectedIhiRadio} onChange={handleIhiChangeRadio}>
              <Stack direction={'row'} spacing={6}>
                <Box position={'relative'} flex={1}>
                  <IhiModalField
                    label={'Unverified IHI Number'}
                    value={unverifiedIhiNumber}
                    highlightColour={'error'}
                    helperText={'This number could not be verified in the HI Service for this patient.'}
                  />
                  <Box position={'absolute'} right={0} top={6}>
                    <Radio value={'unverfiedIhiNumber'} checked={selectedIhiRadio === 'unverfiedIhiNumber'} />
                  </Box>
                </Box>
                <Box position={'relative'} flex={1}>
                  <IhiModalField
                    label={'Verified IHI Number'}
                    value={verifiedIhiNumber}
                    highlightColour={'success'}
                    helperText={'This number is verified in the HI Service for this patient.'}
                  />
                  <Box position={'absolute'} right={0} top={6}>
                    <Radio value={'verifiedIhiNumber'} checked={selectedIhiRadio === 'verifiedIhiNumber'} />
                  </Box>
                </Box>
              </Stack>
            </RadioGroup>
          </Box>
          {!hasChosenVerifiedIhiNumber && (
            <Box marginBottom={6}>
              <ModalIhiQuestions handleFeedbackOnChange={handleFeedbackOnChange} />
            </Box>
          )}

          {hasChosenVerifiedWithExtraFields && (
            <>
              <Typography fontWeight={'bold'} mb={1}>
                Please confirm the information below
              </Typography>
              <Typography>
                Since you have selected to use the verified IHI number, here are some more of the patient details which
                you can choose to align with HI Service. Please select the patient information you wish to align with HI
                Service.
              </Typography>
              <Box mt={5}>
                {ihiPatientFieldsWithDifferences?.firstName && (
                  <IhiModalFieldRadioGroup
                    labelPartial={ModalIhiFieldNames.FirstName}
                    fieldName={'firstName'}
                    pmsFieldValue={patientDetailsInPMS.FirstName}
                    ihiFieldValue={patientInIHI?.firstName}
                    radioSelectionState={otherFieldsRadioSelected || {}}
                    handleRadioChange={handleOtherFieldChangeRadio}
                  />
                )}
                {ihiPatientFieldsWithDifferences?.lastName && (
                  <IhiModalFieldRadioGroup
                    labelPartial={ModalIhiFieldNames.LastName}
                    fieldName={'lastName'}
                    pmsFieldValue={patientDetailsInPMS.LastName}
                    ihiFieldValue={patientInIHI?.lastName}
                    radioSelectionState={otherFieldsRadioSelected || {}}
                    handleRadioChange={handleOtherFieldChangeRadio}
                  />
                )}
                {ihiPatientFieldsWithDifferences?.medicareNumber && (
                  <IhiModalFieldRadioGroup
                    labelPartial={ModalIhiFieldNames.MedicareNumber}
                    fieldName={'medicareNumber'}
                    pmsFieldValue={patientDetailsInPMS.medicareNumber}
                    ihiFieldValue={patientInIHI?.medicareNumber}
                    radioSelectionState={otherFieldsRadioSelected || {}}
                    handleRadioChange={handleOtherFieldChangeRadio}
                  />
                )}
                {ihiPatientFieldsWithDifferences?.medicareRefNumber && (
                  <IhiModalFieldRadioGroup
                    labelPartial={ModalIhiFieldNames.MedicareReferenceNumber}
                    fieldName={'medicareRefNumber'}
                    pmsFieldValue={patientDetailsInPMS.medicare_ref_number}
                    ihiFieldValue={patientInIHI?.medicareRefNumber}
                    radioSelectionState={otherFieldsRadioSelected || {}}
                    handleRadioChange={handleOtherFieldChangeRadio}
                  />
                )}
                {ihiPatientFieldsWithDifferences?.gender && (
                  <IhiModalFieldRadioGroup
                    labelPartial={ModalIhiFieldNames.Gender}
                    fieldName={'gender'}
                    pmsFieldValue={patientDetailsInPMS.gender}
                    ihiFieldValue={patientInIHI?.sex}
                    radioSelectionState={otherFieldsRadioSelected || {}}
                    handleRadioChange={handleOtherFieldChangeRadio}
                  />
                )}
                {ihiPatientFieldsWithDifferences?.dob && (
                  <IhiModalFieldRadioGroup
                    labelPartial={ModalIhiFieldNames.DateOfBirth}
                    fieldName={'dob'}
                    pmsFieldValue={formatPatientDobIhiModals(patientDetailsInPMS.DoB)}
                    ihiFieldValue={formatPatientDobIhiModals(patientInIHI?.doB)}
                    radioSelectionState={otherFieldsRadioSelected || {}}
                    handleRadioChange={handleOtherFieldChangeRadio}
                  />
                )}
              </Box>
            </>
          )}

          <Box marginTop={3}>{getAlert()}</Box>

          <Stack direction={'row'} justifyContent={'end'} paddingTop={6}>
            <Box marginRight={5}>
              <Button onClick={handleModalClose} color="secondary" variant="text">
                <Typography>Cancel</Typography>
              </Button>
            </Box>
            <Button
              onClick={handleUpdateSubmit}
              variant="contained"
              color="secondary"
              disabled={isConfirmButtonDisabled}
            >
              <Typography>Confirm</Typography>
            </Button>
          </Stack>
        </Box>
      </StyledModalContents>
    </StyledModal>
  );
};
