import type { ScriptProduct } from '@/types';

/**
 * This method returns the first alternative medication
 * on a given script product as the default medication
 * @param product the script product
 * @returns an alternative product or undefined if one
 * can't be determined
 */
const getDefaultAlternativeMedication = (product?: ScriptProduct) => {
  return product?.alternativeProducts[0];
};

export { getDefaultAlternativeMedication };
