import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { ErrorBoundary } from 'react-error-boundary';
import App from './App';
import { errorLogger, generateErrorPage } from './error-handling';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <ErrorBoundary fallbackRender={generateErrorPage} onError={errorLogger}>
      <App />
    </ErrorBoundary>
  </StrictMode>
);
