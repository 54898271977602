import theme from '@/theme';
import { Chip, chipClasses, css, styled } from '@mui/material';

const PriorityChipStyled = styled(Chip)(
  () => css`
    background-color: ${theme.palette.pillCard.light};
    .${chipClasses.icon} {
      color: ${theme.palette.error.dark};
    }
  `
);

export { PriorityChipStyled };
