import type { CatalogProduct } from '@/hooks/rest/useGetProductList';
import { Checkbox, Tooltip } from '@mui/material';
import { useMemo } from 'react';

type CheckBoxCellProps = {
  isRowSelected: boolean;
  selectedAlternativeProductId: number;
  alternativeProductList: CatalogProduct[];
};

const CheckboxCell = ({ isRowSelected, selectedAlternativeProductId, alternativeProductList }: CheckBoxCellProps) => {
  const hasAlternative = useMemo(
    () =>
      Boolean(
        selectedAlternativeProductId &&
          (alternativeProductList || []).find((product) => product.id === selectedAlternativeProductId)
      ),
    [selectedAlternativeProductId, alternativeProductList]
  );

  return (
    <Tooltip
      title={hasAlternative ? 'Select this medication for approval' : 'Please provide an alternative medication first'}
      placement="right"
    >
      <span>
        <Checkbox color="primary" checked={isRowSelected} disabled={!hasAlternative} />
      </span>
    </Tooltip>
  );
};

export { CheckboxCell };
