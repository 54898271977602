import { Logger } from '@/utils/logger';
import { ErrorResponseSchema } from '@montugroup/pms-api-contracts';
import type { z } from 'zod';

const parseApiError = (error: z.infer<typeof ErrorResponseSchema>) => {
  const errorMessages = error.errors.map((err) => err.message);
  //this formatting allows preservation of error message separation within a single string.
  //downstream components render this in pre-wrap styled element
  return `• ${errorMessages.join('\n• ')}`;
};

export const handleOnError = (status: number, error: z.infer<typeof ErrorResponseSchema> | unknown): void => {
  if (status !== 400) {
    throw new Error();
  }

  const { success } = ErrorResponseSchema.safeParse(error);

  if (!success) {
    const logger = new Logger('handleOnError');

    logger.error('failed to parse error response from api', error);

    throw new Error();
  }

  const errorMessage = parseApiError(error as z.infer<typeof ErrorResponseSchema>);

  throw new Error(errorMessage);
};
