import type { EventInput, EventSourceFuncArg } from '@fullcalendar/core';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import GridOnIcon from '@mui/icons-material/GridOn';
import { TextField, styled } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import CalendarView from '@/components/calendar/CalendarView';
import { NurseCalendarTable } from '@/components/tables/NurseCalendarTable';
import { getAuthData } from '@/data/service/authService';
import { makeGET } from '@/data/service/dataService';
import type { Nurse, NurseConsultation } from '@/types';

import { DateTime } from 'luxon';
import FullCalendarStyles from '../assets/js/fullCalendar';
import GoToDateField from '../components/fields/GoToDateField';
import useGetNurseConsultations from '../hooks/rest/useGetNurseConsultations';

interface NurseListItem {
  label: string;
  id: number | string;
  nurseUserId?: number;
}

const StyledButton = styled(Button)({
  lineHeight: '25px'
});

const useCalendarStyles = makeStyles(() => FullCalendarStyles());

const NurseCalendar = () => {
  const calendarClasses = useCalendarStyles();
  const { doGet: getNurseConsultations } = useGetNurseConsultations();
  const [date, setDate] = useState(DateTime.now().toUTC().toISO());
  const [showCalendarView, setShowCalendarView] = useState(false);
  const [nursesList, setNursesList] = useState<NurseListItem[]>([]);
  const { user, isAdmin, isNurse, isManager } = getAuthData();
  const defaultSelectedNurse = useMemo(() => ({ label: 'All Nurses', id: 'all' }), []);
  const [selectedNurse, setSelectedNurse] = useState<NurseListItem>(defaultSelectedNurse);
  const [nurseOverride, setNurseOverride] = useState(false);

  const formatDataForCalendar = (nurseConsultations: NurseConsultation[]) => {
    return (nurseConsultations ?? []).map((consultation) => ({
      consultation_id: consultation.id,
      consultation_code: consultation.consultation_code,
      title: `${consultation.patient_name} - ${consultation.consultation_type}`,
      start: consultation.start_time,
      end: consultation.end_time,
      start_time: consultation.start_time,
      patient_id: consultation.patient_id,
      patient_code: consultation.patient_code,
      eventDetails: consultation,
      backgroundColor: 'transparent',
      borderColor: 'transparent'
    }));
  };

  const handleDateChange = (e: DateTime | null) => {
    const isoString = e?.toUTC().toISO() ?? '';
    setDate(isoString);
  };

  const handleToggleCalendarView = () => {
    setShowCalendarView((prevState) => !prevState);
  };

  const handleSelectNurseChange = (_event: React.SyntheticEvent<Element, Event>, value: NurseListItem | null) => {
    setNurseOverride(true);
    setSelectedNurse(value || defaultSelectedNurse);
  };

  const handleCalendarCallback = useCallback(
    async (_: EventSourceFuncArg, successCallback: (eventInputs: EventInput[]) => void) => {
      if (!selectedNurse.id) {
        successCallback([]);
      }

      const { data: selectedNurseConsultations } = await getNurseConsultations({
        params: { nurse: selectedNurse.id }
      });

      const consultations = selectedNurseConsultations?.length ? formatDataForCalendar(selectedNurseConsultations) : [];

      successCallback(consultations as EventInput[]);
    },
    [getNurseConsultations, selectedNurse.id]
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    makeGET('nurse', 'getNurse-NurseCalander').then((nurses: any) => {
      if (nurses) {
        const listOfNurses: NurseListItem[] = nurses.map((nurse: Nurse) => ({
          label: `${nurse?.NurseUser?.first_name} ${nurse?.NurseUser?.last_name} - ${nurse.nurse_code}`,
          id: nurse.id, // This is the ID of the nurse in PMS (which is different from the nurses user id for some reason)
          nurseUserId: nurse?.NurseUser?.id // This is the User ID of the logged in Nurse
        }));

        listOfNurses.unshift(defaultSelectedNurse);

        if (isNurse) {
          const findIndex = listOfNurses.findIndex((nurse) => {
            return nurse.nurseUserId === user?.id;
          });
          const loggedInNurse = listOfNurses[findIndex];

          if (loggedInNurse) {
            listOfNurses.splice(findIndex, 1);
            listOfNurses.unshift(loggedInNurse);
            setSelectedNurse(loggedInNurse);
          }

          setNursesList(listOfNurses);
        } else {
          setNursesList(listOfNurses);
        }
      }
    });
  }, [defaultSelectedNurse, isNurse, user?.id]);

  if (isNurse && selectedNurse.id === 'all' && !nurseOverride) {
    const selNurse = nursesList.find((nurseFromList) => nurseFromList.id === user.id);
    if (selNurse) {
      setSelectedNurse(selNurse);
    }
  }

  return (
    <Box component="div">
      <Grid container py={4} px={5} width="100%">
        <Box display="flex" alignItems={'center'} width="100%" justifyContent={'space-between'} flexWrap="wrap">
          <Typography variant="h5" color="textPrimary">
            Nurse Calendar
          </Typography>
          <Box display="flex">
            <Box mx={3} p={1}>
              <StyledButton
                color="primary"
                variant="contained"
                onClick={() => {
                  setDate(DateTime.now().toUTC().toISO());
                }}
              >
                Today
              </StyledButton>
            </Box>
            {!showCalendarView && (
              <Box width={266} mr={2}>
                <GoToDateField
                  label="Date"
                  margin={0}
                  customGrid={[1, 12]}
                  value={DateTime.fromISO(date)}
                  onChange={handleDateChange}
                />
              </Box>
            )}
            {(isAdmin || isNurse || isManager) && (
              <Box className="inputfield-container" data-testid="nurse-select">
                <Autocomplete
                  sx={{
                    width: '250px',
                    minWidth: '250px'
                  }}
                  options={nursesList}
                  filterSelectedOptions
                  defaultValue={selectedNurse || defaultSelectedNurse}
                  value={selectedNurse}
                  onChange={handleSelectNurseChange}
                  renderInput={(params) => {
                    const labelValue = nursesList.length === 0 ? 'Loading nurses...' : 'Select a nurse';
                    return <TextField {...params} label={labelValue} placeholder="Select a nurse" size="small" />;
                  }}
                />
              </Box>
            )}
            {selectedNurse.id !== 'all' && (
              <Box data-testid="calendar-view-switch" ml={3}>
                <Button onClick={handleToggleCalendarView} variant="outlined">
                  <Typography marginRight={2}>Switch to {showCalendarView ? 'List view' : 'Calendar View'}</Typography>
                  {showCalendarView ? <GridOnIcon fontSize="small" /> : <CalendarViewWeekIcon fontSize="small" />}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
        {showCalendarView && selectedNurse.id !== 'all' ? (
          <Box marginTop={3} className={calendarClasses.fullCalendar} width="100%">
            <CalendarView eventCallback={handleCalendarCallback} initialDate={date} />
          </Box>
        ) : (
          <NurseCalendarTable
            getQueryParams={{
              selectedNurseIds: [selectedNurse.id],
              dateIsoString: date
            }}
          />
        )}{' '}
      </Grid>
    </Box>
  );
};

export default NurseCalendar;
