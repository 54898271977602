import type { CancelMedicationItemType } from '@/components/PatientPageV2/components/PrescriptionPad';
import { FF_ENABLE_PMS_ERROR_HANDLING } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import { useAppStore } from '@/state-management';
import { Logger } from '@/utils/logger';
import { toast } from '@montugroup/design-system';
import usePutCancelPrescribedProducts from './usePutCancelPrescribedProducts';

export const useSubmitCancelPrescribedMedications = (): {
  submitCancelPrescribedMedications: (patientId: string, onSuccess: () => void) => Promise<void>;
  isError: boolean;
} => {
  const logger = new Logger('CancelPrescribedMedicationModal');
  const { flags } = useFeatureFlags();
  const ffEnableErrorHandling = flags[FF_ENABLE_PMS_ERROR_HANDLING];
  const { mutateAsync, isError, reset, error } = usePutCancelPrescribedProducts();
  const {
    prescriptionHistory: { itemsToCancel }
  } = useAppStore.use.prescriptionAssistant();
  const updatePrescriptionHistoryItemsToCancel = useAppStore.use.updatePrescriptionHistoryItemsToCancel();

  const submitCancelPrescribedMedications = async (patientId: string, onSuccess: () => void): Promise<void> => {
    // We have check the items length it should not be 0 anymore
    // but we still need to do a casting here typescript seems still not able to infer
    const productIds = itemsToCancel.map((item: CancelMedicationItemType) => item.productId) as [number, ...number[]];

    const response = await mutateAsync({
      params: { patientId },
      body: { productIds }
    });

    if (response.status === 200) {
      toast.success({
        message: `Prescriptions are successfully cancelled!`
      });
      updatePrescriptionHistoryItemsToCancel([]);
      onSuccess();
    }
  };

  if (isError) {
    if (!ffEnableErrorHandling) {
      logger.error(`Failed to cancel prescriptions.`, error);
      toast.error({
        message: 'Failed to cancel prescriptions. Please try again.'
      });
    }

    // need to reset the query state as having cache don't allow for retries if any errors
    reset();
  }

  return { submitCancelPrescribedMedications, isError };
};
