import type { TabsProps as MuiTabsProps } from '@mui/material';

import { StyledTabs } from './Tabs.styled';
import type { TabStyleVariant } from './Tabs.types';

export type TabsProps = MuiTabsProps & {
  styleVariant: TabStyleVariant;
};

export const Tabs = ({ children, ...props }: MuiTabsProps) => {
  return <StyledTabs {...props}>{children}</StyledTabs>;
};

export default Tabs;
