import type { ReactElement } from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { BadRequestPage, ErrorPage, ForbiddenPage, UnauthorisedPage } from '../pages';
import { NotFoundPage } from '../pages/NotFound';
import { StatusCode } from '../types';

export const generateErrorPage = ({ error }: FallbackProps): ReactElement => {
  const statusCode = error.status;
  switch (statusCode) {
    case StatusCode.Unauthorised:
      return <UnauthorisedPage />;

    case StatusCode.BadRequest:
      return <BadRequestPage />;

    case StatusCode.Forbidden:
      return <ForbiddenPage />;

    case StatusCode.NotFound:
      return <NotFoundPage />;

    default:
      return <ErrorPage />;
  }
};
