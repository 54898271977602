import { Button } from '@montugroup/design-system';
import Close from '@mui/icons-material/Close';
import { IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

import { CallPatientButton } from '@/components/PatientPageV2/components/AWSConnect/ClickToCall/CallPatientButton';
import DisplayField from '@/components/fields/DisplayField';
import { FF_C4C_VOICE_CALL } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import { SectionBody } from '@/shared-ui/PageElements/SectionBody';
import { SectionHeader } from '@/shared-ui/PageElements/SectionHeader';
import type { ConsultationModel } from '@/types';
import { luxonFormatWithOrdinalSuffix } from '@/utils/luxon';
import { DateTime } from 'luxon';

export type ConsultationDetailsProps = {
  consultation: ConsultationModel;
  onClose?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
};

export const ConsultationDetails = ({ consultation, onClose }: ConsultationDetailsProps) => {
  const getPractitionerName = () => {
    if (consultation?.Doctor?.DoctorUser) {
      return `${consultation.Doctor.DoctorUser?.first_name} ${consultation.Doctor.DoctorUser?.last_name}`;
    }
    if (consultation?.Nurse?.NurseUser) {
      return `${consultation.Nurse?.NurseUser?.first_name} ${consultation.Nurse?.NurseUser?.last_name}`;
    }

    // when there's nothing, return empty string
    return '';
  };
  const { flags } = useFeatureFlags();

  const startDateTime = DateTime.fromISO(consultation.start_time || '');
  const endDateTime = DateTime.fromISO(consultation.end_time || '');
  const durationMins = consultation.ConsultationType.duration_mins;

  const appointmentTime = `${startDateTime.toFormat('MMM')} ${luxonFormatWithOrdinalSuffix(startDateTime.day)} ${startDateTime.toFormat('yyyy, h:mm a').toLowerCase()} - ${endDateTime.toFormat('h:mm a').toLowerCase()} (${durationMins} mins)`;
  const isVoiceCallEnabled = flags[FF_C4C_VOICE_CALL];

  return (
    <Grid component="section">
      <SectionHeader
        headerText="Consultation Details"
        actionButton={
          <IconButton onClick={onClose} size="small">
            <Close />
          </IconButton>
        }
      />
      {/* Section Content */}
      <SectionBody>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={12} md={8}>
            <Typography
              variant="h4"
              sx={{
                fontSize: '15px',
                fontWeight: 700,
                lineHeight: '150%',
                letterSpacing: '0.15px',
                paddingY: 3,
                textAlign: 'left'
              }}
            >
              {consultation.doctor_id ? 'Doctor Consultation' : 'Nurse Consultation'} ({consultation.consultation_code})
            </Typography>

            <DisplayField
              id="doctor-appointment-time"
              label="Practitioner"
              fieldText={getPractitionerName()}
              margin={0}
              labelPadding="0px"
              fieldPadding="0px"
              labelFontWeight={400}
              customGrid={[3, 9]}
            />
            <DisplayField
              id="doctor-appointment-time"
              label="Appointment Time"
              fieldText={appointmentTime}
              margin={0}
              labelPadding="0px"
              fieldPadding="0px"
              labelFontWeight={400}
              customGrid={[3, 9]}
            />
            <DisplayField
              id="doctor-zoom-link"
              label="Zoom link"
              fieldText={
                <Button
                  variant="text"
                  onClick={() => {
                    if (consultation?.zoom_url) {
                      window.open(consultation.zoom_url);
                    }
                  }}
                >
                  {consultation.zoom_url || ''}
                </Button>
              }
              margin={0}
              labelPadding="0px"
              fieldPadding="0px"
              labelFontWeight={400}
              customGrid={[3, 9]}
            />
            <DisplayField
              id="doctor-appointment-status"
              label="Current Status"
              fieldText={consultation.ConsultationStatus?.name || ''}
              margin={0}
              labelPadding="0px"
              fieldPadding="0px"
              labelFontWeight={400}
              customGrid={[3, 9]}
            />
          </Grid>
          {isVoiceCallEnabled && (
            <Grid item xs={12} md={4}>
              <CallPatientButton />
            </Grid>
          )}
        </Grid>
      </SectionBody>
    </Grid>
  );
};
