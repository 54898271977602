import { useTableManualPagination } from '@/components/tables/hooks/useTableManualPagination';
import { getAuthData } from '@/data/service/authService';
import type { AutocompleteMultiSelectOption } from '@montugroup/design-system';
import { PaginationVariant, Table } from '@montugroup/design-system/Table';
import { Box, Paper } from '@mui/material';
import type { Cell, ColumnDef, Row, RowSelectionState } from '@tanstack/react-table';
import { useState } from 'react';
import { TABLE_PAGE_SIZE } from '../../Rescripting.constants';
import type { RescriptingReason } from '../../Rescripting.types';
import type { SearchElements } from '../../utils/rescriptingTableRowFilterFn';
import { rescriptingTableRowFilterFn } from '../../utils/rescriptingTableRowFilterFn';
import type { DiscontinuedTableRowData } from '../DiscontinuedPanel/DiscontinuedPanel.types';
import type { OutOfStockTableRowData } from '../OutOfStockPanel/OutOfStockPanel.types';
import { EnhancedToolbar } from './EnhancedToolbar';
import { TableFilters } from './TableFilters/TableFilters';

type TableProps = {
  tableColumnHeaders: ColumnDef<DiscontinuedTableRowData | OutOfStockTableRowData>[] | unknown;
  tableData: DiscontinuedTableRowData[] | OutOfStockTableRowData[];
  selectedUniqueRowKeys: string[];
  setSelectedUniqueRowKeys: React.Dispatch<React.SetStateAction<string[]>>;
  internalTableRowSelectedState: RowSelectionState;
  setInternalTableRowSelectedState: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  handleApproval: (selectedUniqueRowKeys: string[]) => Promise<void>;
  isLoading: boolean;
  rescriptingReason: RescriptingReason;
};

const RescriptingTable = ({
  tableColumnHeaders,
  tableData,
  selectedUniqueRowKeys,
  setSelectedUniqueRowKeys,
  internalTableRowSelectedState,
  setInternalTableRowSelectedState,
  handleApproval,
  isLoading,
  rescriptingReason
}: TableProps) => {
  const { isDoc } = getAuthData();
  const [selectedPatientCodes, setSelectedPatientCodes] = useState<AutocompleteMultiSelectOption[]>([]);
  const [selectedMedicationNames, setSelectedMedicationNames] = useState<AutocompleteMultiSelectOption[]>([]);
  const [selectedDoctorNames, setSelectedDoctorNames] = useState<AutocompleteMultiSelectOption[]>([]);

  const { paginationModel, shouldResetPageIndex, setShouldResetPageIndex } = useTableManualPagination({
    page: 0,
    pageSize: TABLE_PAGE_SIZE
  });

  const handleGlobalTableFilter = (row: Row<DiscontinuedTableRowData | OutOfStockTableRowData>) => {
    const searchElements: SearchElements = {
      patientCodeSearchElement: row.getValue<string>('patientCode'),
      medicationNameSearchElement: row.getValue<string>(
        rescriptingReason === 'discontinued' ? 'discontinuedMedicationName' : 'outOfStockMedicationName'
      ),
      doctorNameSearchElement: row.getValue<string>('doctorName')
    };

    return rescriptingTableRowFilterFn(
      searchElements,
      selectedPatientCodes,
      selectedMedicationNames,
      selectedDoctorNames,
      isDoc
    );
  };

  const handleOnClickRow = (
    _: Cell<DiscontinuedTableRowData | OutOfStockTableRowData, unknown>,
    row: Row<DiscontinuedTableRowData | OutOfStockTableRowData>
  ) => {
    const originalRowData = row.original;
    if (originalRowData.selectedAlternativeProductId > 0) {
      row.toggleSelected();

      setSelectedUniqueRowKeys((prevKeys) => {
        if (!prevKeys.includes(originalRowData.uniqueRowKey)) {
          return [...prevKeys, originalRowData.uniqueRowKey];
        }

        return prevKeys.filter((key) => key !== originalRowData.uniqueRowKey);
      });
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TableFilters
        tableRowData={tableData}
        rescriptingReason={rescriptingReason}
        onPatientCodeSelect={setSelectedPatientCodes}
        onMedicationNameSelect={setSelectedMedicationNames}
        onDoctorNameSelect={setSelectedDoctorNames}
        setShouldResetPageIndex={setShouldResetPageIndex}
      />
      <Paper sx={{ width: '100%', mb: 2 }}>
        {isDoc && (
          <EnhancedToolbar
            numSelected={selectedUniqueRowKeys.length}
            onClick={() => {
              handleApproval(selectedUniqueRowKeys);
            }}
          />
        )}
        <Table<DiscontinuedTableRowData | OutOfStockTableRowData>
          isLoading={isLoading}
          columns={tableColumnHeaders}
          compact={true}
          data={tableData}
          rowSelection={internalTableRowSelectedState}
          onRowSelectionChange={setInternalTableRowSelectedState}
          showPagination
          skeletonCount={paginationModel.pageSize}
          pageSize={paginationModel.pageSize}
          paginationVariant={PaginationVariant.FIXED_PAGE_SIZE}
          globalFilter={`${selectedPatientCodes.toString()}-${selectedMedicationNames.toString()}-${selectedDoctorNames.toString()}`}
          globalFilterFn={handleGlobalTableFilter}
          onClickRow={handleOnClickRow}
          columnVisibility={{
            checkBoxCell: isDoc,
            selectedAlternativeProductId: isDoc,
            doctorName: !isDoc
          }}
          shouldResetPageIndex={shouldResetPageIndex}
        />
      </Paper>
    </Box>
  );
};

export { RescriptingTable };
