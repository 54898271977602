import { Button } from '@montugroup/design-system';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

interface EnhancedToolbarProps {
  numSelected: number;
  onClick: () => void;
  approveButtonDisabled?: boolean;
}

const EnhancedToolbar = ({ numSelected, onClick, approveButtonDisabled = false }: EnhancedToolbarProps) => {
  const handleClick = () => {
    onClick();
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle2">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="subtitle2" color="primary" id="tableTitle">
          Use the checkboxes to select scripts for approval
        </Typography>
      )}
      {numSelected > 0 && (
        <Tooltip title="Approve Selected">
          <Button
            variant="contained"
            color="success"
            sx={{ whiteSpace: 'nowrap', minWidth: 'auto' }}
            onClick={handleClick}
            disabled={approveButtonDisabled}
          >
            Approve Selected
          </Button>
        </Tooltip>
      )}
    </Toolbar>
  );
};

export { EnhancedToolbar };
