import { Table } from '@montugroup/design-system';

import { usePatientDocuments } from '@/hooks';
import { NOTIFICATION_OF_TREATMENT_DOCUMENT_TYPE_ID } from '../../DocumentsTab.config';
import { downloadFile, filterByDocumentTypeId } from '../../DocumentsTab.utils';
import { formatTableColumns } from './NotificationOfTreatmentTable.config';
import type { NotificationOfTreatmentTableProps } from './NotificationOfTreatmentTable.types';

export const NotificationOfTreatmentTable = ({ patientId }: NotificationOfTreatmentTableProps) => {
  const { data, isLoading } = usePatientDocuments(patientId);

  const filteredDocuments =
    data?.status === 200 ? filterByDocumentTypeId(data.body, NOTIFICATION_OF_TREATMENT_DOCUMENT_TYPE_ID) : [];

  return (
    <Table
      data={filteredDocuments}
      columns={formatTableColumns(downloadFile)}
      noDataFoundMessage="No documents found"
      isLoading={isLoading}
      skeletonCount={5}
    />
  );
};
