import { getProductRescriptAlternatives } from '@/api/product/getProductRescriptAlternatives';
import { QueryKey } from '@/data/query';
import { isDoctor } from '@/data/service/authService';
import { useQuery } from '@tanstack/react-query';

const useRescriptAlternatives = () => {
  const queryKey = [QueryKey.GetRescriptAlternatives];
  const { isLoading, data, error, refetch } = useQuery({
    queryKey,
    queryFn: () => getProductRescriptAlternatives(),
    enabled: isDoctor()
  });

  return {
    loading: isLoading,
    data: data ? data.products : [],
    error,
    doGet: refetch
  };
};

export { useRescriptAlternatives };
