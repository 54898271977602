import axios from 'axios';

import type { PaginatedRequest, Prescription } from '@/types';

import settings from '../../data/constants';

interface DiscontinuedMedicationsParams extends Omit<PaginatedRequest, 'offset'> {
  status: string;
}

export type DiscontinuedMedicationsResponse = {
  prescriptions: Prescription[];
  count: number;
};

const getPrescriptionsWithDiscontinuedMedications = async ({ status, limit }: DiscontinuedMedicationsParams) => {
  const response = await axios.get<DiscontinuedMedicationsResponse>(
    `${settings.url}/prescription/generativeScripting`,
    {
      method: 'GET',
      params: {
        filter_generative_scripts: true,
        status: status,
        limit: limit
      }
    }
  );

  return response?.data;
};

export { getPrescriptionsWithDiscontinuedMedications };
export type { DiscontinuedMedicationsParams };
