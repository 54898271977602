import BookSpecificDoctorButton from '@/components/calcom/BookSpecificDoctorButton';
import CalendarPopupButton from '@/components/calcom/CalendarPopupButton';
import settings from '@/data/constants';
import { toast } from '@montugroup/design-system';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Menu, MenuItem } from '@mui/material';
import type { MouseEvent } from 'react';
import React, { useState } from 'react';

interface BookConsultationMenuProps {
  fieldsToPrefillBookingForm: any;
  onBookingSuccessful: any;
  doctorsList: any;
  doctor: any;
  enableBookAnyDoctorButton: any;
  enableBookSpecificDoctorButton: any;
  initialDoctorConsultCompleted: any;
  initialCompletedNurseConsults: any;
}

export const BookConsultationMenu: React.FC<BookConsultationMenuProps> = (props: BookConsultationMenuProps) => {
  const {
    fieldsToPrefillBookingForm,
    onBookingSuccessful,
    doctorsList,
    doctor,
    enableBookAnyDoctorButton,
    enableBookSpecificDoctorButton,
    initialDoctorConsultCompleted,
    initialCompletedNurseConsults
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const disabledBookAnyDoctorButtonText = !initialCompletedNurseConsults.length
    ? `the patient has not completed an 'Initial - Nurse' consult`
    : 'the patient has already been assigned a doctor';
  const disabledBookSpecificDoctorButtonText = !initialCompletedNurseConsults.length
    ? `the patient has not completed an 'Initial - Nurse' consult`
    : 'there is already a scheduled doctors consult';

  const handleOpen = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleBookAnyDoctor = (event: MouseEvent) => {
    if (!enableBookAnyDoctorButton) {
      // Manually stop propagation (instead of disabling the element) so element is clickable and can trigger custom message
      event.stopPropagation();
      toast.warning(`This option is disabled because ${disabledBookAnyDoctorButtonText}`);
    }
  };

  const handleBookSpecificDoctor = (event: MouseEvent) => {
    if (!enableBookSpecificDoctorButton) {
      // Manually stop propagation (instead of disabling the element) so element is clickable and can trigger custom message
      event.stopPropagation();
      toast.warning(`This option is disabled because ${disabledBookSpecificDoctorButtonText}`);
    }
  };

  return (
    <div>
      <Button variant="contained" color="secondary" onClick={handleOpen}>
        Book a consultation
        <ArrowDropDownIcon />
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={handleBookAnyDoctor}
          data-testid={`book-any-doctor-option${!enableBookAnyDoctorButton ? '-disabled' : ''}`}
        >
          <CalendarPopupButton
            formData={fieldsToPrefillBookingForm}
            link={
              initialDoctorConsultCompleted
                ? settings.calendar.doctorFollowupRoundRobin
                : settings.calendar.doctorRoundRobin
            }
            onBookingSuccessful={onBookingSuccessful}
            disabled={!enableBookAnyDoctorButton}
          >
            Book any doctor consult
          </CalendarPopupButton>
        </MenuItem>
        <MenuItem
          onClick={handleBookSpecificDoctor}
          data-testid={`book-specific-doctor-option${!enableBookSpecificDoctorButton ? '-disabled' : ''}`}
        >
          <BookSpecificDoctorButton
            formData={fieldsToPrefillBookingForm}
            doctors={doctorsList}
            assignedDoctor={doctor}
            disabled={!enableBookSpecificDoctorButton}
            disableBookingTypeButton={initialDoctorConsultCompleted}
          >
            Book specific doctor consult
          </BookSpecificDoctorButton>
        </MenuItem>
      </Menu>
    </div>
  );
};
