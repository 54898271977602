import axios from 'axios';

import settings from '@/data/constants';
import type { CatalogProduct, FormulationItem, Supplier } from '@/hooks/rest/useGetProductList';

export type GetProductRescriptAlternativesResponse = {
  formulation: FormulationItem[];
  products: CatalogProduct[];
  suppliers: Supplier[];
};

const getProductRescriptAlternatives = async () => {
  const response = await axios.get<GetProductRescriptAlternativesResponse>(`${settings.url}/product/for-rescript`, {
    method: 'GET'
  });

  return response?.data;
};

export { getProductRescriptAlternatives };
