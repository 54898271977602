/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button } from '@montugroup/design-system';
import { Box, Collapse, Link, Stack, styled } from '@mui/material';
import type { ChangeEvent } from 'react';
import { useEffect, useRef, useState } from 'react';

import type { FeedbackRadioGroupProps, RadioOption } from '../../../../shared-ui/FeedbackRadioGroup/FeedbackRadioGroup';
import FeedbackRadioGroup from '../../../../shared-ui/FeedbackRadioGroup/FeedbackRadioGroup';
import { shuffleSurveryAnswers } from '../PrescriptionPad/PrescriptionPad.utils';

const StyledFeedbackRadioGroup = styled(FeedbackRadioGroup)(({ theme }) => ({
  '& .MuiRadio-root': {
    color: theme.palette.orange
  }
}));

export interface ProductSelectionFeedbackProps extends FeedbackRadioGroupProps {
  answers: RadioOption[];
  questionId: number;
  label: string;
  subText: string;
  onConfirm?: (value: number, randomAnswers: RadioOption[]) => void;
}

export default function ProductSelectionFeedback({
  label,
  subText,
  title,
  answers,
  onConfirm
}: ProductSelectionFeedbackProps) {
  const confirmBtnRef = useRef<HTMLDivElement>(null);
  const [feedback, setFeedback] = useState('');
  const [isFeedbackShown, showFeedback] = useState(false);
  const [randomAnswers, setRandomAnswers] = useState<RadioOption[]>(answers);

  // to force the confirm btn into view for resized screens
  useEffect(() => {
    if (!isFeedbackShown) {
      return;
    }

    setTimeout(() => {
      confirmBtnRef?.current?.scrollIntoView?.({ behavior: 'smooth' });
    }, 500);
  }, [isFeedbackShown]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setFeedback(value);
  };

  const handleViewMoreProductLink = () => {
    setRandomAnswers(shuffleSurveryAnswers(answers));
    showFeedback(!isFeedbackShown);
  };

  return (
    <Box p={8}>
      {!isFeedbackShown && (
        <Link component="button" onClick={handleViewMoreProductLink} sx={{ cursor: 'pointer' }}>
          {label}
        </Link>
      )}
      <Collapse in={isFeedbackShown}>
        <StyledFeedbackRadioGroup
          title={title}
          subText={subText}
          options={randomAnswers}
          value={feedback}
          onChange={handleOnChange}
        />
        <Stack alignItems="flex-end" ref={confirmBtnRef}>
          <Button
            color={feedback ? 'secondary' : 'inherit'}
            variant="contained"
            onClick={() => {
              if (onConfirm) {
                onConfirm(Number(feedback), randomAnswers);
              }
            }}
            disabled={!feedback}
          >
            Confirm
          </Button>
        </Stack>
      </Collapse>
    </Box>
  );
}
