import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useCallback, useState } from 'react';

import { useAccessControl } from '@/hooks';
import { UserRole } from '@/types';
import { getCalcomUsernameFromEmail } from '@/utils/calendar';

import type { Doctor, DoctorWithSlugs, PrefillCalCom } from './BookSpecificDoctorButton';
import BookSpecificDoctorListItem from './BookSpecificDoctorListItem';

type BookSpecificDoctorListProps = {
  assignedDoctor: Doctor | null;
  doctors: Doctor[];
  formData: PrefillCalCom;
  disableBookingTypeButton: boolean;
  handleClose: () => void;
};

const StyledListContainer = styled(Paper)({
  padding: '40px 30px 30px 30px',
  overflow: 'scroll',
  height: '100%'
});

const StyledTypography = styled(Typography)({
  marginBottom: 20,
  fontSize: 16,
  textAlign: 'center'
});

const StyledList = styled('ul')({
  overflow: 'scroll',
  paddingBottom: 20
});

const StyledAssignedDoctorContainer = styled('div')({
  marginBottom: 50
});

const StyledListSearch = styled('div')({
  display: 'flex',
  alignItems: 'baseline',
  fontSize: 16,
  marginBottom: 20,
  '> input': {
    padding: 8,
    fontSize: 16,
    width: '250px'
  }
});

const BookSpecificDoctorList = (props: BookSpecificDoctorListProps) => {
  const { doctors, assignedDoctor, formData, disableBookingTypeButton, handleClose } = props;
  const [searchFilter, setSearchFilter] = useState('');
  const { restrictAccess } = useAccessControl();

  const handleOnChangeSearchFilter = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchFilter(event?.target?.value);
  };

  const isDoctorAssigned = assignedDoctor?.id;
  const assignedDoctorsName =
    isDoctorAssigned && `Dr ${assignedDoctor.DoctorUser.first_name} ${assignedDoctor.DoctorUser.last_name}`;

  const doctorsWithCalcomSlugs = doctors
    .map((doctor: Doctor) => {
      if (doctor?.calcom_initial_consult_event || doctor?.calcom_follow_up_consult_event) {
        return {
          name: `Dr ${doctor.DoctorUser.first_name} ${doctor.DoctorUser.last_name}`,
          id: doctor.id,
          doctor_code: doctor.doctor_code,
          calcom_username: getCalcomUsernameFromEmail(doctor.DoctorUser.email),
          calcom_initial_consult_event: doctor.calcom_initial_consult_event,
          calcom_follow_up_consult_event: doctor.calcom_follow_up_consult_event
        };
      }
    })
    .filter(Boolean);

  const getAssignedDoctorListItem = () => {
    return (doctorsWithCalcomSlugs as DoctorWithSlugs[])
      .map((doctor: DoctorWithSlugs) => {
        const isCurrentDoctor = assignedDoctor?.id === doctor.id;
        return (
          isCurrentDoctor && (
            <BookSpecificDoctorListItem
              doctor={doctor}
              formData={formData}
              disableBookingTypeButton={disableBookingTypeButton}
              handleClose={handleClose}
            />
          )
        );
      })
      .filter(Boolean);
  };

  const doctorsList = getAssignedDoctorListItem().length
    ? doctorsWithCalcomSlugs.filter((doctor) => doctor?.id !== assignedDoctor?.id)
    : doctorsWithCalcomSlugs;

  const filteredDoctorsList = useCallback(() => {
    if (searchFilter?.length === 0) {
      return doctorsList;
    }

    const filteredList = (doctorsList as DoctorWithSlugs[])
      .filter((doctor: DoctorWithSlugs) => {
        const doctorMeta = `${doctor.name.toLowerCase()} ${doctor.doctor_code.toLowerCase()}`;
        if (doctorMeta.includes(searchFilter.toLowerCase())) {
          return doctor;
        }
      })
      .filter(Boolean);

    return filteredList;
  }, [doctorsList, searchFilter]);
  return (
    <StyledListContainer>
      <Typography variant="h4" align="center" marginBottom={4}>
        Alternaleaf - Doctors
      </Typography>

      {isDoctorAssigned && (
        <>
          <StyledAssignedDoctorContainer data-testid="patient-assigned-text">
            <StyledTypography>
              This patient is currently assigned
              <strong> &quot;{assignedDoctorsName}&quot; </strong>
              as a doctor. Book again with:
            </StyledTypography>
            {getAssignedDoctorListItem()}
          </StyledAssignedDoctorContainer>
          {restrictAccess([UserRole.Admin], <StyledTypography>Or choose a different doctor:</StyledTypography>)}
        </>
      )}
      <>
        {restrictAccess(
          [UserRole.Admin],
          <>
            <StyledListSearch>
              <input
                value={searchFilter}
                placeholder="Search list by doctors name"
                onChange={(event) => handleOnChangeSearchFilter(event)}
                autoFocus={!!searchFilter.length}
                onKeyDown={(e) => {
                  // This stopPropagation is a temporary fix to prevent the B key causing the doctor search to lose focus
                  e.stopPropagation();
                }}
              />
            </StyledListSearch>
            <StyledList data-testid="doctors-list">
              {(filteredDoctorsList() as DoctorWithSlugs[]).map((doctor: DoctorWithSlugs) => {
                return (
                  <BookSpecificDoctorListItem
                    key={doctor.id}
                    doctor={doctor}
                    formData={formData}
                    disableBookingTypeButton={disableBookingTypeButton}
                    handleClose={handleClose}
                  />
                );
              })}
            </StyledList>
          </>
        )}
      </>
    </StyledListContainer>
  );
};

export default BookSpecificDoctorList;
