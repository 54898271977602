import { getAuthData } from '@/data/service/authService';
import { Button, ConfirmDialog, Table, toast } from '@montugroup/design-system';

import { makePUT } from '@/data/service/dataService';
import { usePatientDocuments } from '@/hooks';
import { useModal } from 'mui-modal-provider';
import { formatTableColumns } from './DocumentTable.config';
import { downloadFile, filterByDocumentTypeId } from './DocumentsTab.utils';

type DocumentData = {
  id: number;
  document_path: string;
};

type DocumentTableProps = {
  patientActive: boolean;
  patientId: number;
};

const DocumentTable = ({ patientActive, patientId }: DocumentTableProps) => {
  const { data, isLoading, refetch } = usePatientDocuments(patientId);

  const { isSuperAdmin, isDoc, isNurse, isManager } = getAuthData();
  const canDeleteDocument = Boolean((isSuperAdmin || isDoc || isNurse || isManager) && patientActive);
  const canVerifyDocument = isDoc;
  const { showModal } = useModal();

  const updateDocument = async (sendBody: object, deleted = false) => {
    const resp = await makePUT(`document`, sendBody, 'verifyDocument-DocumentTable');
    if (resp) {
      refetch();
      toast.success(deleted ? 'Document deleted' : 'Document status updated');
    } else {
      toast.error(`${deleted ? 'Failed to delete' : 'Failed to Verify'}. Please try again later.`);
    }
  };

  const changeDocumentVerified = (original: DocumentData, verified: boolean) => {
    updateDocument({ verified, id: original.id }, false);
  };

  const deleteDocument = (original: DocumentData) => {
    const modal = showModal(ConfirmDialog, {
      title: 'Delete document',
      actions: (
        <>
          <Button
            variant="outlined"
            onClick={() => {
              modal.hide();
            }}
          >
            Cancel
          </Button>
          <Button
            color="error"
            onClick={() => {
              modal.hide();
              updateDocument({ active: false, id: original.id }, true);
            }}
          >
            Delete
          </Button>
        </>
      ),
      children: (
        <>
          Are you sure you want to delete <b>{original.document_path}</b>?
        </>
      )
    });
  };

  const filteredDocuments = data?.status === 200 ? filterByDocumentTypeId(data.body) : [];

  return (
    <Table
      data={filteredDocuments}
      columns={formatTableColumns({
        downloadFile,
        deleteDocument,
        changeDocumentVerified,
        canDeleteDocument,
        canVerifyDocument
      })}
      noDataFoundMessage="No documents found"
      isLoading={isLoading}
      skeletonCount={5}
    />
  );
};

export default DocumentTable;
