import { Button } from '@montugroup/design-system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Card, Divider, Stack } from '@mui/material';

import { FF_CONSULTATION_COMPLETION_PROMPT } from '@/constants/featureFlags';
import { useFeatureFlags } from '@/hooks';
import { ConfirmationCheckList } from '@/shared-ui/ConfirmationCheckList/ConfirmationCheckList';
import type { ConfirmPrescriptionCardProps } from './ConfirmPrescriptionCard.types';
import { PatientHeadings, PrescriberHeadings, PrescriptionHeadings } from './ConfirmPrescriptionCard.types';
import { InformationBlock } from './InformationBlock';

export const ConfirmPrescriptionCard = ({
  prescriberNumber,
  prescriberDetails,
  patientDetails,
  createdPrescriptionDetails,
  // createdVaporizer,
  createdDevices,
  reviewActions,
  onReturn,
  onActionClick
}: ConfirmPrescriptionCardProps) => {
  const { flags } = useFeatureFlags();
  const isConfirmationCheckListEnabled: boolean = flags[FF_CONSULTATION_COMPLETION_PROMPT];

  return (
    <Stack spacing={4}>
      <Stack alignItems="flex-start">
        <Button
          variant="outlined"
          color="primary"
          startIcon={<ArrowBackIcon />}
          sx={{ borderRadius: 1, textTransform: 'none', fontWeight: 'bold', letterSpacing: 1 }}
          onClick={onReturn}
        >
          Return to prescriptions
        </Button>
      </Stack>
      <Card raised>
        {/* Prescriber Details */}
        <InformationBlock
          heading={PrescriberHeadings.MainHeading}
          rowsOfText={[
            {
              id: 'prescriber-number',
              heading: PrescriberHeadings.PrescriberNumber,
              body: prescriberNumber || '-'
            },
            {
              id: 'prescriber-clinic',
              heading: PrescriberHeadings.Clinic,
              body: prescriberDetails?.clinic || '-'
            },
            {
              id: 'prescriber-name',
              heading: PrescriberHeadings.PrescriberName,
              body: `${prescriberDetails?.first_name} ${prescriberDetails?.last_name}`
            },
            {
              id: 'prescriber-address',
              heading: PrescriberHeadings.Address,
              body: prescriberDetails?.address || '-'
            },
            {
              id: 'prescriber-phone',
              heading: PrescriberHeadings.Phone,
              body: prescriberDetails?.phone || '-'
            }
          ]}
        />

        <Divider />

        {/* Patient Details */}
        <InformationBlock
          heading={PatientHeadings.MainHeading}
          rowsOfText={[
            {
              id: 'patient-medicare-number',
              heading: PatientHeadings.Medicare,
              body: patientDetails?.medicare_number || '-'
            },
            {
              id: 'patient-name',
              heading: PatientHeadings.Name,
              body: `${patientDetails?.first_name} ${patientDetails?.last_name}`
            },
            {
              id: 'patient-dob',
              heading: PatientHeadings.DOB,
              body: patientDetails.dob || '-'
            },
            {
              id: 'patient-address',
              heading: PatientHeadings.Address,
              body: patientDetails?.address || '-'
            },
            {
              id: 'patient-phone',
              heading: PatientHeadings.Phone,
              body: patientDetails?.phone || '-'
            }
          ]}
        />

        <Divider />

        {/* Medications */}
        {createdPrescriptionDetails?.map((prescription) => (
          <InformationBlock
            key={prescription.productId}
            heading={PrescriptionHeadings.MainHeading}
            rowsOfText={[
              {
                id: 'prescription-name',
                heading: PrescriptionHeadings.Name,
                body: prescription?.supplierName
                  ? prescription?.supplierName + ' | ' + prescription?.productName
                  : prescription?.productName
              },
              {
                id: 'prescription-quantity',
                heading: PrescriptionHeadings.Quantity,
                body: prescription?.quantity || '-'
              },
              {
                id: 'prescription-repeats',
                heading: PrescriptionHeadings.Repeats,
                body: prescription?.repeats || '-'
              },
              {
                id: 'prescription-interval',
                heading: PrescriptionHeadings.Interval,
                body: prescription?.interval || '-'
              },
              {
                id: 'prescription-dosage',
                heading: PrescriptionHeadings.Dosage,
                body: prescription?.dosage || '-'
              }
            ]}
          />
        ))}
        {/* Vaporizer (if any) */}

        {(createdDevices || []).map(
          (createdDevice, index) =>
            createdDevice && (
              <InformationBlock
                key={index}
                heading={PrescriptionHeadings.DevicesHeading}
                rowsOfText={[
                  {
                    id: 'prescription-add-vaporizer-product-name',
                    heading: PrescriptionHeadings.DeviceName,
                    body: createdDevice?.name
                  },
                  {
                    id: 'prescription-add-vaporizer-product-quantity',
                    heading: PrescriptionHeadings.Quantity,
                    body: createdDevice?.quantity
                  }
                ]}
              />
            )
        )}
      </Card>
      {isConfirmationCheckListEnabled && (
        <Card raised>
          <ConfirmationCheckList actions={reviewActions} onClick={onActionClick} />
        </Card>
      )}
    </Stack>
  );
};
