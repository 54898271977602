import { StatusCode } from '../types';

export const generateErrorMessage = (statusCode: StatusCode, isOffline = false) => {
  if (isOffline) {
    return 'No internet connection. Please try again.';
  }

  switch (statusCode) {
    case StatusCode.NotModified:
      return 'Request has already been made in this session.';

    case StatusCode.MultiStatus:
      return 'Some productIds were successfully cancelled & some failed.';

    case StatusCode.InternalServerError:
      return 'The prescriptions were not able to be cancelled, we encountered issues. This failure has been logged and the support team will address this.';

    default:
      return 'Something went wrong. Please try again';
  }
};
