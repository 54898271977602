import { FF_PATV5_SCRIPT_REVIEW_PAGE_DISABLE_DISCONTINUED_TAB } from '@/constants/featureFlags';
import { getAuthData } from '@/data/service/authService';
import { useFeatureFlags } from '@/hooks';
import { Alert } from '@mui/material';

const DiscontinuedFeatureDisabledAlert = () => {
  const { flags } = useFeatureFlags();
  const { isDoc } = getAuthData();
  const disableDiscontinuedTab = flags[FF_PATV5_SCRIPT_REVIEW_PAGE_DISABLE_DISCONTINUED_TAB];

  if (disableDiscontinuedTab || isDoc) {
    return null;
  }

  return (
    <Alert severity="info">
      Doctors are not able to see the Discontinued scripts tab, they are not able to action Discontinued scripts. The
      functionality will be updated for use in the future.
    </Alert>
  );
};

export { DiscontinuedFeatureDisabledAlert };
