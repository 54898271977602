import theme from '@/theme';
import type { SxProps, Theme } from '@mui/material';

const HyperLinkStyle: SxProps<Theme> = {
  textDecoration: 'none',
  cursor: 'pointer',
  fontSize: '12px',
  color: theme.palette.secondary.main,
  background: 'none !important',
  border: 'none',
  ':hover': {
    textDecoration: 'underline'
  }
};

export { HyperLinkStyle };
