import type { GetDocumentResponse } from '@montugroup/pms-api-contracts';
import { Checkbox, Link, Tooltip, styled } from '@mui/material';
import type { CellContext } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import type { downloadFileParamsType } from './DocumentsTab.utils';

const StyledLink = styled(Link, {
  shouldForwardProp: () => true
})({
  width: 250,
  minWidth: 50,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  textAlign: 'left'
}) as typeof Link;

export const renderDocumentName = ({
  documentId,
  documentPath,
  downloadFile
}: {
  documentPath: string;
  documentId: number;
  downloadFile: ({ documentId, documentPath }: downloadFileParamsType) => void;
}) => {
  const documentName = documentPath?.split('/').pop();
  return (
    <Tooltip title={documentName}>
      <StyledLink component="button" onClick={() => downloadFile({ documentPath, documentId })} data-key={documentName}>
        {documentName}
      </StyledLink>
    </Tooltip>
  );
};

export const renderDocumentType = (type: string) => type;
export const renderDocumentCategory = (category: string) => category;
export const renderDocumentFormulation = (item: string) => item;
export const renderDocumentUploadedDate = (item: string) => item && DateTime.fromISO(item).toFormat('dd MMM yyyy');
export const renderDocumentUploadedBy = (type: string) => type;
export const renderDocumentVerified = (
  item: CellContext<GetDocumentResponse, any>,
  changeDocumentVerified: (document: GetDocumentResponse, verified: boolean) => void,
  canVerifyDocument: boolean
) => {
  return (
    <Checkbox
      checked={Boolean(item.row.original.verified)}
      onChange={(e) => changeDocumentVerified(item.row.original, e.target.checked)}
      disabled={!canVerifyDocument}
      color="default"
    />
  );
};
type DocumentDeleteProps = {
  item: CellContext<GetDocumentResponse, any>;
  deleteDocument: (document: GetDocumentResponse) => void;
  canDeleteDocument: boolean;
};

export const renderDocumentVerifiedDate = (item: string) => item && DateTime.fromISO(item).toFormat('dd MMM yyyy');
export const renderDocumentDelete = ({ item, deleteDocument, canDeleteDocument }: DocumentDeleteProps) => {
  return (
    canDeleteDocument && (
      <Link
        component="button"
        data-key={item.row.original.id}
        data-name={item.row.original.document_path}
        onClick={() => {
          deleteDocument(item.row.original);
        }}
      >
        Delete
      </Link>
    )
  );
};

const renderFullName = (item: CellContext<GetDocumentResponse, any>) => {
  const firstName = item.row.original?.CreatedBy?.first_name;
  const lastName = item.row.original?.CreatedBy?.last_name;
  if (!firstName && !lastName) {
    return '';
  }
  return `${firstName} ${lastName}`;
};

export const formatTableColumns = ({
  downloadFile,
  deleteDocument,
  changeDocumentVerified,
  canDeleteDocument,
  canVerifyDocument
}: {
  downloadFile: ({ documentId, documentPath }: downloadFileParamsType) => void;
  deleteDocument: (document: GetDocumentResponse) => void;
  changeDocumentVerified: (document: GetDocumentResponse, verified: boolean) => void;
  canDeleteDocument: boolean;
  canVerifyDocument: boolean;
}) => {
  return [
    {
      accessorKey: 'document_path',
      header: 'Document Name',
      width: 100,
      enableSorting: true,
      cell: (item: CellContext<GetDocumentResponse, string>) =>
        renderDocumentName({
          documentPath: item.row.original.document_path,
          documentId: item.row.original.id,
          downloadFile: downloadFile
        })
    },
    {
      accessorKey: 'DocumentType.name',
      header: 'Type',
      width: 100,
      enableSorting: true,
      cell: (item: CellContext<GetDocumentResponse, string>) => renderDocumentType(item.getValue())
    },
    {
      accessorFn: (item: GetDocumentResponse) => item.ProductCategory?.name,
      header: 'Category',
      width: 110,
      enableSorting: true,
      cell: (item: CellContext<GetDocumentResponse, string>) => renderDocumentCategory(item.getValue())
    },
    {
      accessorFn: (item: GetDocumentResponse) => item.ProductFormulation?.name,
      header: 'Formulation',
      width: 160,
      enableSorting: true,
      cell: (item: CellContext<GetDocumentResponse, any>) => renderDocumentFormulation(item.getValue())
    },
    {
      accessorKey: 'upload_date',
      header: 'Uploaded Date',
      width: 160,
      enableSorting: true,
      cell: (item: CellContext<GetDocumentResponse, any>) => renderDocumentUploadedDate(item.getValue())
    },
    {
      accessorKey: 'CreatedBy.first_name',
      header: 'Uploaded By',
      width: 160,
      enableSorting: true,
      cell: (item: CellContext<GetDocumentResponse, any>) => renderFullName(item)
    },
    {
      accessorKey: 'verified',
      header: 'Verified',
      width: 160,
      enableSorting: true,
      cell: (item: CellContext<GetDocumentResponse, any>) =>
        renderDocumentVerified(item, changeDocumentVerified, canVerifyDocument)
    },
    {
      accessorKey: 'verified_date',
      header: 'Verified Date',
      width: 160,
      enableSorting: true,
      cell: (item: CellContext<GetDocumentResponse, any>) => renderDocumentVerifiedDate(item.getValue())
    },
    {
      accessorKey: 'delete',
      header: 'Delete',
      width: 160,
      enableSorting: true,
      cell: (item: CellContext<GetDocumentResponse, any>) =>
        renderDocumentDelete({ item, deleteDocument, canDeleteDocument })
    }
  ];
};
