import type { AutocompleteMultiSelectOption } from '@montugroup/design-system';

type SearchElements = {
  patientCodeSearchElement: string;
  medicationNameSearchElement: string;
  doctorNameSearchElement: string;
};

/**
 * This method facilitates the filtering logic between the
 * filter selectors and the table rows.
 *
 * @param searchElements The elements of the row currently being evaluated
 * @param selectedPatientCodes An array of objects representing selected patient codes
 * @param selectedMedicationNames An array of objects representing selected medication names
 * @param selectedDoctorNames An array of objects representing selected doctors
 * @param isDoctor Flag to represent if the user is a doctor
 * @returns returns true if all the search elements match the selected values
 */
const rescriptingTableRowFilterFn = (
  searchElements: SearchElements,
  selectedPatientCodes: AutocompleteMultiSelectOption[],
  selectedMedicationNames: AutocompleteMultiSelectOption[],
  selectedDoctorNames: AutocompleteMultiSelectOption[],
  isDoctor: boolean
) => {
  const { patientCodeSearchElement, medicationNameSearchElement, doctorNameSearchElement } = searchElements;

  const patientCodeMatch = selectedPatientCodes.some((option) => option.title === patientCodeSearchElement);
  const medicationNameMatch = selectedMedicationNames.some((option) => option.title === medicationNameSearchElement);

  if (!isDoctor) {
    const doctorNameMatch = selectedDoctorNames.some((option) => option.title === doctorNameSearchElement);

    return (
      (selectedMedicationNames.length === 0 || medicationNameMatch) &&
      (selectedPatientCodes.length === 0 || patientCodeMatch) &&
      (selectedDoctorNames.length === 0 || doctorNameMatch)
    );
  }

  return (
    (selectedMedicationNames.length === 0 || medicationNameMatch) &&
    (selectedPatientCodes.length === 0 || patientCodeMatch)
  );
};

export { rescriptingTableRowFilterFn };
export type { SearchElements };
