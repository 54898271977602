export const makeCustomErrorText = (isForCancellation: boolean) => ({
  title: 'The following issues have been identified:',
  partOne: `You may still proceed by clicking `,
  partTwo: `"Approve ${isForCancellation ? 'Cancellation' : 'Prescription'}"`,
  partThree: ` to ${isForCancellation ? 'cancel' : 'prescribe'} the product.`,
  partFour: `Once all errors are corrected by administrative staff,  the Notification of Treatment form will be automatically signed and submitted to the WA Department of Health on your behalf.`
});

export const makeDefaultErrorText = (isForCancellation: boolean) => ({
  title: 'Failed to load preview',
  partOne: `We encountered an issue loading the form preview. This won't affect your ability to continue, you can still`,
  partTwo: ' sign and submit ',
  partThree: `to proceed with script ${isForCancellation ? 'cancellation' : 'creation'}.`
});
