import Box from '@mui/material/Box';

import { getAuthData } from '@/data/service/authService';
import type { AutocompleteMultiSelectOption } from '@montugroup/design-system';
import { AutocompleteMultiSelect } from '@montugroup/design-system';
import { FILTER_PLACEHOLDER_TEXT } from './TableFilters.constants';
import type { TableFilersProps } from './TableFilters.types';
import { TableFilterLabels } from './TableFilters.types';
import { formatDoctorNameOptions, formatMedicationNameOptions, formatPatientCodeOptions } from './TableFilters.utils';

const TableFilters = ({
  tableRowData,
  rescriptingReason,
  onPatientCodeSelect,
  onMedicationNameSelect,
  onDoctorNameSelect,
  setShouldResetPageIndex
}: TableFilersProps) => {
  const { isDoc } = getAuthData();

  const handlePatientCodeSelected = (newPatientCodes: AutocompleteMultiSelectOption[]) => {
    onPatientCodeSelect(newPatientCodes);
    setShouldResetPageIndex(true);
  };

  const handleMedicationNameSelected = (newMedicationNames: AutocompleteMultiSelectOption[]) => {
    onMedicationNameSelect(newMedicationNames);
    setShouldResetPageIndex(true);
  };

  const handleDoctorNameSelected = (newDoctorNames: AutocompleteMultiSelectOption[]) => {
    onDoctorNameSelect(newDoctorNames);
    setShouldResetPageIndex(true);
  };

  return (
    <Box display="flex" gap={3} paddingY={2}>
      <Box flex={1}>
        <AutocompleteMultiSelect
          options={formatPatientCodeOptions(tableRowData)}
          itemSelected={handlePatientCodeSelected}
          labelText={TableFilterLabels.patientCodes}
          placeholderText={FILTER_PLACEHOLDER_TEXT}
        />
      </Box>
      <Box flex={1}>
        <AutocompleteMultiSelect
          options={formatMedicationNameOptions(tableRowData, rescriptingReason)}
          itemSelected={handleMedicationNameSelected}
          labelText={TableFilterLabels.medicationNames}
          placeholderText={FILTER_PLACEHOLDER_TEXT}
        />
      </Box>
      {!isDoc && (
        <Box flex={1}>
          <AutocompleteMultiSelect
            options={formatDoctorNameOptions(tableRowData)}
            itemSelected={handleDoctorNameSelected}
            labelText={TableFilterLabels.doctors}
            placeholderText={FILTER_PLACEHOLDER_TEXT}
          />
        </Box>
      )}
    </Box>
  );
};

export { TableFilters };
