import { AlertModal } from '@/shared-ui';
import { useAppStore } from '@/state-management';
import type { CancelMedicationItemType } from './../PrescriptionPad/';
import { DEFAULT_CANCEL_ALERT_MODAL_PROPS } from './CancelPrescribedMedicationModal.config';
import { StyledList, StyledListItem } from './CancelPrescribedMedicationModal.styles';

export type CancelPrescribedMedicationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const CancelPrescribedMedicationModal = ({ isOpen, onClose, onConfirm }: CancelPrescribedMedicationModalProps) => {
  const {
    prescriptionHistory: { itemsToCancel }
  } = useAppStore.use.prescriptionAssistant();

  if (itemsToCancel.length < 1) {
    return null;
  }

  return (
    <AlertModal isOpen={isOpen} onClose={onClose} onConfirm={onConfirm} {...DEFAULT_CANCEL_ALERT_MODAL_PROPS}>
      <StyledList>
        {itemsToCancel.map(({ productId, displayName }: CancelMedicationItemType) => (
          <StyledListItem key={productId}>{displayName}</StyledListItem>
        ))}
      </StyledList>
    </AlertModal>
  );
};

export default CancelPrescribedMedicationModal;
