import { Button } from '@montugroup/design-system';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, CssBaseline, ThemeProvider, Typography } from '@mui/material';

import type { ReactElement } from 'react';
import theme from '../../theme';

export const ForbiddenPage = (): ReactElement => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box role="alert" display="flex" justifyContent="center" alignItems="center" mt={30}>
        <Box textAlign="center">
          <ErrorIcon color="error" sx={{ fontSize: 60 }} />
          <Typography variant="h4" component="h1">
            You are forbidden to perform this operation.
          </Typography>
          <Typography my={2}>Please login again.</Typography>
          <Button onClick={() => window.location.replace('/')}>Login</Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
