import { Box, Button, FormControl, FormHelperText, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMemo } from 'react';
import type { SubmitHandler } from 'react-hook-form';

import type { ConcessionCardFile, ConcessionCardType } from '..';
import { ConcessionCardExpirationDatePicker, ConcessionCardTypeTextField, ConcessionCardUpload } from '..';
import type { UseConcessionCardFormValues } from '../../../hooks/concession/useConcessionCardForm';
import { useConcessionCardForm } from '../../../hooks/concession/useConcessionCardForm';
import { transformLuxonToYearMonthDay } from '../utils';

const FieldContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3)
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

const Form = styled(Box)({
  textAlign: 'center'
});

const CardTypeContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center'
});

type SubmitValues = {
  cardType: ConcessionCardType;
  cardNumber: string;
  expiryYear: number;
  expiryMonth: number;
  expiryDay: number;
  cardImage: ConcessionCardFile;
};

export type ConcessionCardFormProps = {
  onSubmit: (data: SubmitValues) => Promise<UseConcessionCardFormValues | void> | UseConcessionCardFormValues | void;
  cardFormProps?: Parameters<typeof useConcessionCardForm>[0];
};

export const ConcessionCardForm = ({ onSubmit, cardFormProps }: ConcessionCardFormProps) => {
  const formMethods = useConcessionCardForm(cardFormProps);
  const {
    handleSubmit,
    watch,
    defaultControllers: { controlCardNumber, controlCardType, controlCardExpiry, controlCardUpload },
    formState: { isSubmitting },
    setValue
  } = formMethods;

  const {
    field: { onChange: cardTypeChange, value: cardTypeValue },
    fieldState: { error: cardTypeError }
  } = controlCardType;

  const {
    field: { onChange: cardNumberOnChange, value: cardNumberValue },
    fieldState: { error: cardNumberError }
  } = controlCardNumber;

  const {
    field: { onChange: cardExpiryChange, value: cardExpiryValue },
    fieldState: { error: cardExpiryError }
  } = controlCardExpiry;

  const {
    field: { onChange: cardUploadChange, value: cardUploadFile },
    fieldState: { error: cardUploadError }
  } = controlCardUpload;

  const cardUploadFiles = useMemo(() => (cardUploadFile ? [cardUploadFile] : []), [cardUploadFile]);

  const transformSubmit: SubmitHandler<UseConcessionCardFormValues> = async ({
    cardExpiry,
    cardNumber,
    cardType,
    cardImage
  }) => {
    if (!cardImage || !cardExpiry || !cardType) {
      return;
    }
    const { year: expiryYear, month: expiryMonth, day: expiryDay } = transformLuxonToYearMonthDay(cardExpiry);
    const result = await onSubmit({
      cardNumber,
      cardType,
      expiryDay,
      expiryMonth,
      expiryYear,
      cardImage
    });
    // This submit handler may be a 2 stage process, e.g. upload image and then send card details. We want to put the
    // image into "uploaded" state if the first was successful, but the card details were not to avoid re-uploading.
    if (result && result.cardImage) {
      setValue('cardImage', cardImage);
    }
  };

  const cardType = watch('cardType');

  const handleImagesAccepted = async (files: File[]) => {
    cardUploadChange(files[0]); // Assume max drop size is 1
  };

  return (
    <Form component="form" onSubmit={handleSubmit(transformSubmit)}>
      <FieldContainer>
        <CardTypeContainer>
          <ConcessionCardTypeTextField
            onChange={cardTypeChange}
            value={cardTypeValue}
            error={Boolean(cardTypeError)}
            helperText={cardTypeError?.message}
            disabled={isSubmitting}
          />
        </CardTypeContainer>
        <FormControl error={Boolean(cardUploadError)}>
          <ConcessionCardUpload files={cardUploadFiles} onDropAccepted={handleImagesAccepted} loading={isSubmitting} />
          {cardUploadError && <FormHelperText>{cardUploadError.message}</FormHelperText>}
        </FormControl>
        <TextField
          label="Card number"
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
          placeholder="1234 5678 9"
          onChange={cardNumberOnChange}
          value={cardNumberValue}
          error={Boolean(cardNumberError)}
          helperText={cardNumberError?.message}
          disabled={isSubmitting}
        />
        <ConcessionCardExpirationDatePicker
          cardType={cardType}
          onChange={cardExpiryChange}
          value={cardExpiryValue}
          disabled={isSubmitting}
          slotProps={{ textField: { error: Boolean(cardExpiryError), helperText: cardExpiryError?.message } }}
        />
      </FieldContainer>
      <SubmitButton color="primary" size="large" variant="contained" type="submit" disabled={isSubmitting}>
        Submit
      </SubmitButton>
    </Form>
  );
};

export default ConcessionCardForm;
