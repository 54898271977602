import { Button } from '@montugroup/design-system';
import { Box, Typography } from '@mui/material';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { useState } from 'react';

import settings from '@/data/constants';
import { getAuthData } from '@/data/service/authService';
import MergePatientModal from '@/pages/MergePatientModal';
import type { ExtendedSnackbarProps } from '@/shared-ui';
import { Snackbar } from '@/shared-ui';

import { luxonFormatWithOrdinalSuffix } from '@/utils/luxon';
import { DateTime } from 'luxon';
import DateRangeComp from '../common/DateRangeComp';

/**
 * format 'YYYY-MM-DD HH:mm:ss'
 */
type ISODateString = string;

type DownloadExportFileRequest = {
  title: string;
  dateRange: {
    from_date: ISODateString;
    to_date: ISODateString;
  };
};

const downloadPatientExportFile = async (data: DownloadExportFileRequest) => {
  const response = await axios.post<DownloadExportFileRequest, AxiosResponse<{ message: string }>>(
    `${settings.url}/excel/export-patients`,
    {
      ...data
    }
  );

  return response?.data;
};

export const PatientsPageHeader = () => {
  const { isNurse, isSuperAdmin, isManager } = getAuthData();
  const [dateRangeCompOpen, setDateRangeCompOpen] = useState(false);
  const [mergePatientModalOpen, setMergePatientModalOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState<Pick<ExtendedSnackbarProps, 'severity' | 'message' | 'open'>>({
    open: false,
    message: ''
  });

  const DownloadFile = async (from_date: ISODateString, to_date: ISODateString) => {
    const dateRange = {
      from_date: from_date,
      to_date: to_date
    };

    const title = `PMS-Patients-${DateTime.now().toFormat('MMM')}-${luxonFormatWithOrdinalSuffix(DateTime.now().day)}-${DateTime.now().year}`;
    try {
      await downloadPatientExportFile({
        title,
        dateRange
      });
      setSnackbarProps({
        open: true,
        severity: 'success',
        message: 'Patients export will be sent to your email shortly.'
      });
    } catch {
      setSnackbarProps({
        open: true,
        severity: 'error',
        message: `Unfortunately we've encountered an error while exporting patient information`
      });
    }
  };

  const handleMergePatient = (shouldOpen: boolean) => {
    setMergePatientModalOpen(shouldOpen);
  };

  const handleShowDateRangeComp = () => {
    setDateRangeCompOpen(true);
  };

  const handleOnSnackbarClose = () => {
    setSnackbarProps({ open: false, message: '' });
  };

  const canExportPatientFile = isSuperAdmin;
  const canOnboardNewPatient = isSuperAdmin || isNurse || isManager;
  const canMergePatients = isSuperAdmin || isNurse || isManager;

  return (
    <Box component="header" padding={4}>
      <Box mb={1} display="flex" justifyContent="space-between">
        <Typography variant="h5" color="textPrimary">
          Patients
        </Typography>
        <Box component="div" ml={2} display="flex" gap={4}>
          {canMergePatients && (
            <Button variant="contained" color="secondary" onClick={() => handleMergePatient(true)}>
              Merge Patients
            </Button>
          )}
          {canOnboardNewPatient && (
            <Button
              variant="contained"
              href="/patients/onboard/new"
              color="secondary"
              data-testid="onboard-patient-btn"
            >
              Onboard New Patient
            </Button>
          )}
          {canExportPatientFile && (
            <Button variant="contained" color="secondary" onClick={handleShowDateRangeComp}>
              Export
            </Button>
          )}
        </Box>
      </Box>
      <Box component="div" display="flex" ml={2}>
        {dateRangeCompOpen && (
          <Box>
            <DateRangeComp open={dateRangeCompOpen} setOpen={setDateRangeCompOpen} DownloadFile={DownloadFile} />
          </Box>
        )}
      </Box>
      <MergePatientModal open={mergePatientModalOpen} setOpen={setMergePatientModalOpen} />
      <Snackbar
        {...snackbarProps}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={handleOnSnackbarClose}
      />
    </Box>
  );
};
